import React from 'react';
import {
  Image,
  Menu,
  Container,
  Icon,
  Button
} from 'semantic-ui-react';
import { NavLink } from 'react-router-dom';
import { withRouter } from '../../utils/useRouter';
import Responsive from "../../components/Responsive/Responsive";
import PredictIcon from '../Icons/PredictIcon';
import UsageIcon from '../Icons/UsageIcon';
import SunIcon from '../Icons/Sun'
import NotificationIcon from '../Icons/NotificationIcon';
import PayNowIcon from '../Icons/PayNowIcon';
import { withContexts } from '../../contexts';
import { iconSearch } from '../../utils/IconsMap';

import ProductPicker from "../ProductPicker/ProductPicker"

import logo from '../../static/aurora_plus_logo.svg';
import './Header.css';


export class Header extends React.Component {

  NavListItems = (props) => {
    const { customer, hasMultipleProducts, pageConfig } = this.props.customer

    if (!pageConfig || !pageConfig.menuItems)
      return null;

    let { pageTitle, isSideMenuOpen, openSideMenu, showBackButton } = this.props.app
    const iconHeight = hasMultipleProducts ? "38px" : "32px";

    return pageConfig.menuItems.navigationMenu
      .sort((a, b) => a.order - b.order)
      .map((navItem) => {

        // add any menu customizations that we need here
        // try to avoid doing this if possible
        if (this.props.hasSolar && navItem.icon === "UsageIcon") {
          navItem.icon = "SunIcon";
          navItem.text = "Solar Usage";
        }
        else if (!this.props.hasSolar && navItem.icon === "SunIcon") {
          navItem.icon = "UsageIcon";
          navItem.text = "Usage";
        }

        // then return all menu items, with a unique key and icon
        return (
          <Menu.Item key={navItem.order} as={NavLink} to={navItem.url} replace style={props.style}>
            {iconSearch(navItem.icon, { count: navItem.count, height: iconHeight })}
            {navItem.text}
          </Menu.Item>
        );

      });
  }

  render() {
    let { isAuthenticated } = this.props.auth
    let { pageTitle, isSideMenuOpen, openSideMenu, showBackButton } = this.props.app
    let { hasLoaded, customer, hasMultipleProducts } = this.props.customer

    const menuItemStyles = {
      className: hasMultipleProducts ? "compressed" : "",
    }

    return (
      <React.Fragment>

        {/* Mobile header */}
        {isAuthenticated &&
          <Responsive lessThan="computer">
            <Menu secondary borderless inverted className={`main-header  mobile ${menuItemStyles.className}`}>
              <Container>

                  <Menu.Item
                    disabled={hasLoaded && !customer}
                    header
                    as='h3'
                    style={{
                      margin: '0 auto',
                      backgroundColor: 'transparent'
                  	}}
				  >
                    {showBackButton &&
                      <Icon name="chevron left" onClick={x => this.props.router.navigate(-1)} />
                    }
                    {pageTitle
                      ? pageTitle
                      : <Image src={logo} alt="logo" style={{ maxHeight: '2.5em' }} />
                    }
                  </Menu.Item>

                  <Menu.Menu>
                    <Menu.Item
                      icon={{ name: "bars", size: "big" }}
                      active={isSideMenuOpen}
                      onClick={openSideMenu}
                      style={{
                        position: 'absolute',
                        right: '0px'
                      }}
				    />
                  </Menu.Menu>

              </Container>
            </Menu>

            {hasMultipleProducts &&
              <Menu secondary borderless inverted className={`main-header mobile ${menuItemStyles.className}`}>
                <ProductPicker {...menuItemStyles} />
              </Menu>
            }
          </Responsive>
        }

        {/* Desktop header */}
        <Responsive greaterThan="tablet">
          <Menu pointing secondary borderless inverted className={`main-header ${menuItemStyles.className}`}>
            <Container>

              {/* Aurora logo */}
              <Menu.Item header as={NavLink} to="/" replace {...menuItemStyles}>
                <Image src={logo} alt="logo" style={{ maxHeight: '3rem' }} />
              </Menu.Item>

              {/* this.props.children == sidemenu items? */}
              {(this.props.children)
                ?
                  <Menu.Item style={{ minWidth: '550px' }} position="right">
                    {this.props.children}
                  </Menu.Item>
                :
                <React.Fragment>
                  {/* header nav items */}
                  {this.NavListItems(menuItemStyles)}

                  {hasMultipleProducts &&
                    <ProductPicker {...menuItemStyles} />
                  }
                </React.Fragment>
              }

              {/* hamburger button */}
              {isAuthenticated &&
                <Menu.Menu position="right">
                  <Responsive
                    greaterThan="tablet"
                    as={Menu.Item}
                    style={{
                      alignSelf: 'center',
                      fontSize: '1.2em',
                      padding: '.875em 0 .875em 1em',
                  }}>
                    Menu
                  </Responsive>

                  <Menu.Item
                    icon={{ name: "bars", size: "big" }}
                    active={isSideMenuOpen}
                    onClick={openSideMenu}
                    {...menuItemStyles}
                  />
                </Menu.Menu>
              }

            </Container>
          </Menu>
        </Responsive>
      </React.Fragment>
    )
  }
}

export default withRouter(withContexts(Header))
