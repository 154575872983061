import React from 'react';
export default function Icon({
    color = 'currentColor',
    height = '32px',
    width = height
}) { return (
    <svg
        height={height}
        width={width}
        viewBox="0 0 15 13"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg">
        <g id="Screens-v2" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g
                id="Hamburger-Menu"
                transform="translate(-31.000000, -744.000000)"
                fill={color}
                fillRule="nonzero">
                <g id="user" transform="translate(31.000000, 744.000000)">
                    <path
                        d="M11.2468198,12.1413391 C11.1011418,11.9734867 10.9214546,11.8260792 10.7163928,11.7160973 L8.6346397,10.4771536 C8.6121419,10.4660928 8.5804049,10.4459472 8.54322298,10.4204477 C8.49812841,10.3039574 8.57593157,10.1480164 8.70619946,10.1480164 L10.458221,10.1480164 C10.458221,10.1480164 10.916442,10.2281324 11.2938005,10.0144899 C11.5363881,9.88096338 11.5902965,9.5604997 11.4555256,9.34685725 C11.0512129,8.70592989 10.1617251,7.15702212 10.0539084,5.44788251 C10,4.9938923 9.91913747,4.51319679 9.73045822,4.08591188 C9.60026862,3.79817043 9.41426656,3.48831013 9.15033299,3.21248773 C8.7076003,2.62323169 7.97509785,2.10081744 6.77201854,2.10081744 C4.0054783,2.10081744 3.95069532,4.8749738 3.95069532,4.8749738 C3.92330383,6.67952211 2.82764433,8.43020331 2.30720607,9.15740935 C2.14285714,9.42674492 2.22503161,9.5614127 2.52633797,9.69608049 C2.54957131,9.70623379 2.57310877,9.71602826 2.59687009,9.72547517 C2.64260469,9.79321415 2.73006959,9.85044142 2.85714286,9.90766868 C3.34231806,10.1213111 3.96226415,10.1747217 3.96226415,10.1747217 L5.62187238,10.1747217 C5.62030399,10.2037294 5.61150199,10.2333053 5.59418458,10.2616852 C5.51201011,10.4232865 5.40244416,10.638755 5.26548673,10.6926221 L2.77286136,11.7430308 C2.53698412,11.8589977 2.34173123,12.0149098 2.20459419,12.1935681 C0.853439069,10.8961903 0,9.08049926 0,7.0769062 C0,3.17793146 3.20754717,0 7.14285714,0 C11.0781671,0 14.2857143,3.17793146 14.2857143,7.0769062 C14.2857143,9.32015194 13.2614555,11.2963446 11.6172507,12.6049046 C11.6172507,12.5781993 11.5902965,12.5781993 11.5902965,12.5781993 C11.5101526,12.4193915 11.3918816,12.2700275 11.2468198,12.1413391 Z"
                        id="Combined-Shape"></path>
                </g>
            </g>
        </g>
    </svg>
)
}
