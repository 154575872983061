import React from "react";

import GroupedBarChart from "../../components/BarChart/GroupedBarChart";
import OffProductView from "../../components/OffProductView/OffProductView";

import {
  Segment,
  Header,
  Button,
  Dropdown,
  Grid,
  Icon,
} from "semantic-ui-react";

import Responsive from "../../components/Responsive/Responsive";
import UsageDataHelper from "./UsageDataHelper";
import DateContextHelper from "./DateContextHelper";

import { withApp } from "../../contexts/App";
import { withCustomer } from "../../contexts/Customer";
import { withTheme } from "../../contexts/Theme";
import { withRouter } from "../../utils/useRouter";
import Layout from "../../layouts/Layout";
import FullWidthLayout from "../../layouts/FullWidthLayout";

import UsageIcon from "../../components/Icons/UsageIcon";
import UsageSummaryView from "./components/UsageSummaryView";

import ExternalLink from "./../../components/ExternalLink";
import Modal from "./../../components/Modal";
import Analytics from "./../../utils/Analytics";
import Log from "../../utils/Log";
import Urls from "../../utils/Urls";
import { getModalForId } from "../../utils/Modals";
import moment from "moment-timezone";

import {
  generateDatePickerContent,
  DesktopDatePicker,
  MobileDatePicker
} from "./components/UsageDatePicker";
import UsageLegend from "./components/UsageLegend";
import SolarHeader from "./components/SolarHeader";
import InsightsAndTips from "./components/InsightsAndTips";
import SolarSystemSize from "./components/SolarSystemSize";

import { 
	TARIFFS,
	TYPES,
	nonSolarTariffTypes
} from "../../utils/Tariffs";
import { Link } from "react-router-dom";

export class Usage extends React.Component {
  initialDataIndex = -1;

  state = {
    unitOfMeasurement: "KilowattHourUsage", //'DollarValueUsage',
    timeRange: UsageDataHelper.DAY,
    chartTitle: "...",
    data: {},
    dataIndex: this.initialDataIndex,
    initialDataLoaded: false,
    loadingNewData: false,
    animateData: false,
    showOtherCharges: false,
    modal: {
      isOpen: false,
      title: "",
      description: ""
    },
    solarInfoModal: {
      isOpen: false,
      title: "",
      description: ""
    },
    customerID: "",
    serviceAgreementID: "",
    serviceAgreementAddress: "",
    pickerVisible: false,
    pickerValues: UsageDataHelper.getInitialPickerSelectedValues(
      UsageDataHelper.DAY
    ),
    tariff: null,
    solarToggle: false,
    tip: JSON.parse(window.localStorage.getItem("energyTip") || "null"),
    insight: null,
	showEnergyTab: false, // Single tab while loading, in case they are on the T22 experience
	showSummaryCard: true,
	showSummaryDollarValues: true,
	hasT22: false,
	hasT22Transition: false,
  };

  dataHelper = new UsageDataHelper();

  componentDidMount() {
    this.handleWindowResize();
    window.addEventListener("resize", this.handleWindowResize);

    if (this.props.customer.hasLoaded && this.props.customer.customerPremise) {
      const { customer } = this.props.customer;

      this.setState({
        serviceAgreementID: this.props.customer.customerPremise
          .serviceAgreementID,
        customerID: customer ? customer.customerID : ""
      });
    }
  }

  componentWillUnmount() {
    this.dataHelper && this.dataHelper.dispose();
    window.removeEventListener("resize", this.handleWindowResize);
  }

  componentDidUpdate(prevProps, prevState) {
    const { customer } = this.props.customer;

    if (
      prevProps.customer.customerPremise !== this.props.customer.customerPremise
    ) {
      this.setState({
        serviceAgreementID: this.props.customer.customerPremise
          .serviceAgreementID,
        customerID: customer ? customer.customerID : ""
      });
    }

    if (prevState.serviceAgreementID !== this.state.serviceAgreementID) {
      this.getMoreData();

      // request insights and tips from API if localStorage date is not today's date
      this.checkInsightsAndTips();
    }

    if (prevState.dataIndex !== this.state.dataIndex) {
      this.getMoreData();
    }

    if (prevState.data.StartDate !== this.state.data.StartDate) {
      this.checkWeatherData(
        this.props.customer.customerPremise.suburb,
        this.state.dataIndex
      );
    }

    if (
      prevState.data.HasSolarTariff !== this.state.data.HasSolarTariff &&
      !this.state.data.HasSolarTariff
    ) {
      this.setState({ solarToggle: false });
    }



	if (
		prevState.data !== this.state.data ||
		prevState.unitOfMeasurement !== this.state.unitOfMeasurement ||
		prevState.dataIndex !== this.state.dataIndex
	) {
		const data = this.state.data;
		const tariffs = data.TariffTypes;

		let hasT22 = false;
		if (data.NonMeteredUsageRecords) {
			data.NonMeteredUsageRecords.forEach(record => {
				if (record.Description.indexOf("Tariff 22") > 0) {
					hasT22 = true;
				}
			});
		}
		let hasNonSteppedPrimaryTariff = false;
		if (tariffs) {
			tariffs.forEach(tariff => {
				if (TARIFFS[tariff]) {
					switch(TARIFFS[tariff].Type) {
						case TYPES.timeOfUse:
						case TYPES.flat:
							hasNonSteppedPrimaryTariff = true;
							break;
						default:
							break;
					}
				}
			});
		}
		let hasT22Transition = hasT22 && hasNonSteppedPrimaryTariff;

		// AT22-115 Check if the user has T22 but no actual data. This indicates a change, even if no other tariff is present
		if (hasT22 && !hasT22Transition) {
			if (data.MeteredUsageRecords) {
				let hasSomeT22Usage = false;
				data.MeteredUsageRecords.forEach(record => {
					if (record.KilowattHourUsage && record.KilowattHourUsage.T22) {
						hasSomeT22Usage = true;
					}
				});
				if (!hasSomeT22Usage) {
					hasT22Transition = true;						
				}
			}
		}
		
		// AT22-26 calculateCharges() is incorrect for T22. Hide the daily usage card and display a warning over the graph

		let showEnergyTab = hasNonSteppedPrimaryTariff && !data.NoDataFlag && this.state.initialDataLoaded;
		let showSummaryCard = !hasT22 || (this.state.unitOfMeasurement === "KilowattHourUsage");
		let showSummaryDollarValues = !hasT22;


		if (!showEnergyTab && (this.state.unitOfMeasurement === "DollarValueUsage")) {
			this.unitOfMeasurementChanged("KilowattHourUsage");
			showSummaryCard = true;
		}

		this.setState({
			showEnergyTab: showEnergyTab,
			showSummaryCard: showSummaryCard,
			showSummaryDollarValues: showSummaryDollarValues,
			hasT22: hasT22,
			hasT22Transition: hasT22Transition,
		});

	}




  }

  handleWindowResize = () => {
    let isMobile = window.innerWidth < 768;
    if (isMobile !== this.state.isMobile) this.setState({ isMobile });
  };

  handleCloseModal = () => {
    this.setState({
      modal: {
        isOpen: false
      }
    });
  };

  handleCloseSolarInfoModal = () => {
    this.setState({
      solarInfoModal: {
        isOpen: false
      }
    });
  };

  openModal = (modalId, link = "") => {
    const modal = getModalForId(modalId, this.state.unitOfMeasurement);

    if (!modal)
      // error getting modal
      return;

    this.setState({
      modal: {
        isOpen: true,
        description: modal.description,
        title: modal.title,
        learnMore: modal.learnMoreBtn,
        link: link || modal.link
      }
    });
  };

  openSolarInfoModal = () => {
    const modal = getModalForId("usageSolarInfo", this.state.unitOfMeasurement);

    if (!modal)
      // error getting modal
      return;

    this.setState({
      solarInfoModal: {
        isOpen: true,
        description: modal.description,
        title: modal.title,
        learnMore: modal.learnMoreBtn,
        link: modal.link
      }
    });
  };

  solarSwitch = () => {
    this.setState({ solarToggle: !this.state.solarToggle });
    this.checkSolarFirstTime();
  };

  checkSolarFirstTime = () => {
    if (window.localStorage.getItem("hasLoggedInSolar") !== "true") {
      this.openSolarInfoModal();
      window.localStorage.setItem("hasLoggedInSolar", true);
    }
  };

  checkInsightsAndTips = () => {
    const dateFormat = "DD-MM-YYYY";

    // if last query is not set or wasn't refreshed today, then query the API for new tips
    if (
      !this.state.tip ||
      moment(this.state.tip.date, dateFormat).format(dateFormat) !==
        moment()
          .utc()
          .format(dateFormat)
    ) {
      this.dataHelper
        .getDataForTips(this.state.serviceAgreementID)
        .then(data => {
          var obj = {
            date: moment()
              .utc()
              .format(dateFormat),
            ...data.randomTip
          };

          this.setState({
            tip: obj
          });

          window.localStorage.setItem("energyTip", JSON.stringify(obj));
        })
        .catch(err => console.log(err));
    }

    // insight values are queried more often, so we don't need to store it in localStorage
    this.dataHelper
      .getDataForInsights(this.state.serviceAgreementID, this.state.customerID)
      .then(data => {
        this.setState({
          insight: data.comparisonTotals
        });
      })
      .catch(err => console.log(err));
  };

  checkWeatherData = (Suburb, index) => {
    this.dataHelper
      .getDataForWeather(Suburb, index)
      .then(data => {
        this.setState({ weatherData: data.weatherData });
      })
      .catch(err => console.log(err));
  };
 
  unitOfMeasurementChanged = unit => {
    this.setState(
      {
        unitOfMeasurement: unit,
        showOtherCharges: false
      },
      this.getMoreData
    );
  };

  /**
   * Return the closest values to the previous selection.
   *
   * @param {*} values
   * @returns
   * @memberof Usage
   */
  getClosestSelection(values) {
    const { timeRange } = this.state;
    const startDate = this.state.data && this.state.data.StartDate;
    const endDate = this.state.data && this.state.data.EndDate;
    const newValues = [].concat(values);

    if (timeRange === UsageDataHelper.QUARTER) {
      // Validate Selection
      const quarters = DateContextHelper.calculateQuarterOptions(
        newValues,
        timeRange,
        startDate,
        endDate
      );

      // find the closest
      newValues[0] = quarters.reduce((prev, curr) => {
        if (
          Math.abs(curr.value - newValues[0]) <
          Math.abs(Math.abs(prev.value - newValues[0]))
        ) {
          return curr;
        }
        return prev;
      }, quarters[0]).value;
    }

    if (
      timeRange === UsageDataHelper.DAY ||
      timeRange === UsageDataHelper.MONTH
    ) {
      // Validate Month Selection
      const ind = timeRange === UsageDataHelper.DAY ? 1 : 0;

      const months = DateContextHelper.calculateMonthOptions(
        newValues,
        timeRange,
        startDate,
        endDate
      );

      // find the closest
      newValues[ind] = months.reduce((prev, curr) => {
        if (
          Math.abs(curr.value - newValues[ind]) <
          Math.abs(Math.abs(prev.value - newValues[ind]))
        ) {
          return curr;
        }
        return prev;
      }, months[0]).value;
    }

    if (timeRange === UsageDataHelper.DAY) {
      // Validate Day Selection
      const days = DateContextHelper.calculateDayOptions(
        newValues,
        timeRange,
        startDate,
        endDate
      );

      // If the current day selection is not valid find the closest
      newValues[0] = days.reduce((prev, curr) => {
        if (
          Math.abs(curr.value - newValues[0]) <
          Math.abs(Math.abs(prev.value - newValues[0]))
        ) {
          return curr;
        }
        return prev;
      }, days[0]).value;
    }

    return newValues;
  }

  onOk = () => {
    if (this.state.pickerValues && this.state.pickerValues.length > 0) {
      let current = moment(this.state.data.EndDate);
      let now = moment();

      let newDate = null,
        dataIndex = null;

      switch (this.state.timeRange) {
        case UsageDataHelper.DAY:
          newDate = moment()
            .year(this.state.pickerValues[2])
            .month(this.state.pickerValues[1])
            .date(this.state.pickerValues[0]);

          dataIndex = newDate.diff(now, "days");
          break;
        case UsageDataHelper.WEEK:
          // as the weeks start at monday in this app but start at Sunday in moment()
          // we subtract one day always, and if its a monday we subtract another day
          // this keeps the week calculations in line with the static weeks for usage
          let isMondayOffset = moment().day() === 1 ? 1 : 0;
          now = moment().subtract(1 + isMondayOffset, "day");

          newDate = moment()
            .subtract(1 + isMondayOffset, "day")
            .year(this.state.pickerValues[1])
            .week(this.state.pickerValues[0])
            .hour(0)
            .minute(0)
            .second(0);

          dataIndex = newDate.diff(now, "weeks") - 1;

          break;
        case UsageDataHelper.MONTH:
          newDate = moment()
            .day(0)
            .month(this.state.pickerValues[0])
            .year(this.state.pickerValues[1]);

          dataIndex = newDate.diff(now, "months") - 1;
          break;
        case UsageDataHelper.QUARTER:
          newDate = moment()
            .quarter(this.state.pickerValues[0])
            .year(this.state.pickerValues[1]);

          dataIndex = newDate.diff(now, "quarters") - 1;
          break;
        case UsageDataHelper.YEAR:
          newDate = moment().year(this.state.pickerValues[0]);
          dataIndex = this.state.dataIndex;
          dataIndex += newDate.year() - current.year() + 1;
          break;
        default:
          break;
      }

      this.setState({ dataIndex });
    }
  };

  getChartTitle = () => {
    const { data, timeRange, pickerValues } = this.state;
    const { theme } = this.props.theme;
    const onValueChange = vals => {
      const newSelection = this.getClosestSelection(vals);
      this.setState({ pickerValues: newSelection });
    };

    const { title, picker } = generateDatePickerContent({
      data,
      timeRange,
      pickerValues,
      onValueChange,
      getClosestSelection: (...args) => this.getClosestSelection(...args) //APAYGDEV-360
    });

    return (
      <React.Fragment>
        <Responsive lessThan="computer">
          <MobileDatePicker
            title={title}
            content={picker}
            onOk={this.onOk}
            data={this.state.data}
            color={theme.primary.color}
          />
        </Responsive>
        <Responsive greaterThan="tablet">
          <DesktopDatePicker
            title={title}
            content={picker}
            visible={this.state.pickerVisible}
            toggleVisible={vis => this.setState({ pickerVisible: vis })}
            onOk={this.onOk}
            data={this.state.data}
            color={theme.primary.color}
          />
        </Responsive>
      </React.Fragment>
    );
  };

  getMoreData = (previous, classList) => {
    if (
      this.state.loadingNewData ||
      (classList && classList.contains("disabled"))
    )
      return;
    let dataIndex = this.state.dataIndex;

    //Log.log('dataIndex', dataIndex);

    if (previous === undefined) {
      //do nothing with dataIndex
    } else if (previous) {
      dataIndex = dataIndex - 1;
    } else if (dataIndex < -1) {
      dataIndex = dataIndex + 1;
    } else {
      return;
    }

    this.setState({ loadingNewData: true, animateData: false });

    const updateState = (data, dataIndex) => {
      const date = moment(data.StartDate);

      let pickerValues;
      switch (this.state.timeRange) {
        case UsageDataHelper.DAY:
          pickerValues = [date.date(), date.month(), date.year()];
          break;
        case UsageDataHelper.WEEK:
          // The starting day of week defines the year context in this app, but in moment this
          // isnt the case, as some of the weeks starting in December are actually week === 1
          // If this happens, we want the week index to increase as normal (eg. 50, 51, 52, 53) to align
          // with the date picker week indexs
          if (date.month() === 11 && date.week() === 1) {
            pickerValues = [date.weeksInYear() + 1, date.year()];
          } else {
            pickerValues = [date.isoWeek(), date.year()];
          }

          break;
        case UsageDataHelper.MONTH:
          pickerValues = [date.month(), date.year()];
          break;
        case UsageDataHelper.QUARTER:
          pickerValues = [date.quarter(), date.year()];
          break;
        case UsageDataHelper.YEAR:
          pickerValues = [date.year()];
          break;
        default:
          return;
      }

      this.setState({
        dataIndex,
        data,
        loadingNewData: false,
		initialDataLoaded: true,
        animateData: true,
        pickerValues
      });
    };

    if (this.state.timeRange === UsageDataHelper.DAY) {
      this.dataHelper
        .getDataForIndex(
          UsageDataHelper.DAY,
          dataIndex,
          this.state.serviceAgreementID,
          this.state.customerID
        )
        .then(data => {
          let filteredMeteredUsageRecords;

          //If looking at Amount in Dollars usage page
          if (
            this.state.timeRange === UsageDataHelper.DAY &&
            this.state.unitOfMeasurement === "DollarValueUsage"
          ) {
            filteredMeteredUsageRecords = data.MeteredUsageRecords.filter(
              i => i.TimeMeasureUnit === "Day"
            );
          } else {
            //else looking at Energy Usage usage page
            filteredMeteredUsageRecords = data.MeteredUsageRecords.filter(
              i => i.TimeMeasureUnit === "Hour"
            ).map(i => {
              if (i.KilowattHourUsage) {
                for (let key in i.KilowattHourUsage) {
                  i.isPeak = !key.includes("OFFPEAK");
                }
              }
              return i;
            });
          }

		  data.NonSolarTariffTypes = nonSolarTariffTypes(data.TariffTypes);
          updateState(
            { ...data, MeteredUsageRecords: filteredMeteredUsageRecords },
            dataIndex
          );
        })
        .catch(err => {
          console.log(err);
          this.setState({ loadingNewData: false });
        });
    } else {
      this.dataHelper
        .getDataForIndex(
          this.state.timeRange,
          dataIndex,
          this.state.serviceAgreementID,
          this.state.customerID
        )
        .then(data => {
		  data.NonSolarTariffTypes = nonSolarTariffTypes(data.TariffTypes);
		  updateState(data, dataIndex);
        })
        .catch(err => console.log(err));
    }
  };

  timeRangeChanged = (event, { value }) => {
    Log.log("timeChangeRange: ", value);
    let timeRangeHeading;

    if (value === this.state.timeRange) return;

    const newIndex = DateContextHelper.recalculateDataIndex(
      this.state.dataIndex,
      this.state.timeRange,
      value
    );

    this.setState(
      {
        dataIndex: newIndex,
        timeRange: value,
        timeRangeHeading,
        pickerValues: UsageDataHelper.getInitialPickerSelectedValues(value)
      },
      this.getMoreData
    );

    Analytics.event({
      category: "Usage",
      action: "Time Range Changed to " + value
    });
  };

  showOtherCharges = () => {
    this.setState({
      showOtherCharges: !this.state.showOtherCharges,
      animateData: false
    });
  };

  renderTabButton = (unit, title) => {
    const { theme } = this.props.theme;
    const isActive = this.state.unitOfMeasurement === unit;
    return (
      <Button
        disabled={!this.props.customer.customer}
        className="tab-button"
        onClick={() => this.unitOfMeasurementChanged(unit)}
        style={{
          flex: 1,
          borderRadius: 0,
          fontFamily: "Campton Book",
          borderTopRightRadius: 8,
          borderTopLeftRadius: 8,
          border: "1px solid rgba(34,36,38,.15)",
          borderBottom: "none",
          backgroundColor: isActive ? "white" : "#002963",
          color: isActive ? theme.primary.color : "white",
          fontWeight: isActive ? "bold" : "normal"
        }}
      >
        {title}
      </Button>
    );
  };

  switchServiceAgreement = (serviceAgreementID, serviceAgreementAddress) => {
    this.setState(
      {
        serviceAgreementID,
        serviceAgreementAddress
      },
      this.getMoreData
    );
  };

  isMultipleServiceAgreementsForPeriod = () => {
    return (
      this.state.data &&
      this.state.data.ActiveServiceAgreements &&
      this.state.data.ActiveServiceAgreements.length > 1
    );
  };

  isViewingDifferentServiceAgreement = () => {
    const { customerPremise } = this.props.customer;

    if (!customerPremise) return false;

    return this.state.serviceAgreementID !== customerPremise.serviceAgreementID;
  };

  renderMessage = (message, { address, id }, buttonLabel, buttonProps) => {
    return (
      <Segment
        key={message + id}
        textAlign="center"
        style={{
          marginBottom: "1rem"
        }}
        className="usage-data-message"
      >
        <Grid verticalAlign="middle" stackable>
          <Grid.Column tablet={2} computer={2} only="tablet computer">
            <Icon name="info circle" color="blue" size="big" />
          </Grid.Column>
          <Grid.Column width={8}>
            {message}
            <br />
            <b>{address}</b>
          </Grid.Column>
          <Grid.Column width={6}>
            <Button fluid color="orange" size="small" {...buttonProps}>
              {buttonLabel}
            </Button>
          </Grid.Column>
        </Grid>
      </Segment>
    );
  };

  getMessages = () => {
    const { customerPremise } = this.props.customer;
    let { ServiceAgreements: serviceAgreements = {} } = this.state.data;
    const { loadingNewData } = this.state;

    if (!customerPremise || !customerPremise.isActive) return [];

    /*
     *  This doesn't really make sense as the variable names are all the same so
     *  i'll put this comment here to explain
     *
     *  customerPremise.serviceAgreementID is the SA that's currently selected in the DROPDOWN
     *
     *  this.state.serviceAgreementID stores the SA that is currently selected for the USAGE page
     *    it mirrors the dropdown's SA until the user clicks a 'View Usage Data' button
     *
     *  this.state.data.ServiceAgreementID is the id of the SA that we have loaded data for, it is
     *  a cache of our last reponse from the API
     *
     *  we use these three bits of information to know whether we are loading data after
     *  the user has switched a premise in the dropdown
     */
    const a =
      this.state.serviceAgreementID === customerPremise.serviceAgreementID;
    const b =
      this.state.data.ServiceAgreementID !== customerPremise.serviceAgreementID;
    const isLoadingDataAfterChangingAccount = a && b;

    if (isLoadingDataAfterChangingAccount) return [];

    serviceAgreements = Object.keys(serviceAgreements).map(
      k => serviceAgreements[k]
    );

    const otherServiceAgreements = serviceAgreements.filter(
      x => x.Id !== this.state.serviceAgreementID
    );
    const messages = [];

    if (this.isViewingDifferentServiceAgreement()) {
      const msg = this.renderMessage(
        "You are viewing usage data for:",
        {
          address: this.state.serviceAgreementAddress,
          id: this.state.serviceAgreementID
        },
        "Return to current premise",
        {
          color: "red",
          disabled: loadingNewData,
          onClick: () =>
            this.switchServiceAgreement(
              customerPremise.serviceAgreementID,
              customerPremise.address
            )
        }
      );

      messages.push(msg);
    }

    otherServiceAgreements.forEach(serviceAgreement => {
      if (serviceAgreement.Id !== customerPremise.serviceAgreementID) {
        const msg = this.renderMessage(
          "You have usage data available for:",
          { address: serviceAgreement.PremiseName, id: serviceAgreement.Id },
          "View usage data",
          {
            onClick: () =>
              this.switchServiceAgreement(
                serviceAgreement.Id,
                serviceAgreement.PremiseName
              )
          }
        );

        messages.push(msg);
      }
    });

    return messages;
  };

  DropdownItem = text => {
    const { theme } = this.props.theme;

    return (
      <span
        style={{
          background: theme.secondary.background,
          display: "block",
          padding: ".8125rem 1.125rem"
        }}
      >
        {text}
      </span>
    );
  };

  UsageLayout = props => {
    let isMobile = this.state.isMobile;

    let dropdown = (
      <Dropdown
        basic
        selection
        fluid
        options={[
          {
            value: UsageDataHelper.DAY,
            text: "Day",
            content: this.DropdownItem("Day")
          },
          {
            value: UsageDataHelper.WEEK,
            text: "Week",
            content: this.DropdownItem("Week")
          },
          {
            value: UsageDataHelper.MONTH,
            text: "Month",
            content: this.DropdownItem("Month")
          },
          {
            value: UsageDataHelper.QUARTER,
            text: "Quarter",
            content: this.DropdownItem("Quarter")
          },
          {
            value: UsageDataHelper.YEAR,
            text: "Year",
            content: this.DropdownItem("Year")
          }
        ]}
        className={"account-picker"}
        onChange={this.timeRangeChanged}
        icon="angle down"
        value={this.state.timeRange}
        style={
          isMobile
            ? {
                borderTop: "none"
              }
            : {}
        }
        disabled={!this.props.customer.customer}
      />
    );

    return (
      <Layout
        pageTitle="Usage"
        layout={FullWidthLayout}
        oldCCBAccountsDisplayed={true}
        afterAccountPicker={
          isMobile ? (
            <React.Fragment>{dropdown}</React.Fragment>
          ) : (
            <Grid
              verticalAlign="middle"
              columns={2}
              centered
              style={{
                marginTop: "1em"
              }}
            >
              <Grid.Column>
                <Header
                  as="h2"
                  textAlign="right"
                  style={{
                    color: "white",
                    fontWeight: "normal"
                  }}
                >
                  View Usage By
                </Header>
              </Grid.Column>
              <Grid.Column>{dropdown}</Grid.Column>
            </Grid>
          )
        }
      >
        <Grid
          centered
          style={{
            marginBottom: "0rem"
          }}
        >
          <Grid.Column mobile={16} tablet={14} computer={12}>
            {this.getMessages()}
          </Grid.Column>
        </Grid>
        {props.children}
      </Layout>
    );
  };

  graphHeight = () => {
    if (this.state.data.HasSolarTariff) {
      return this.state.solarToggle ? 200 : 300;
    } else {
      return 200;
    }
  };

  render() {
    const { UsageLayout } = this
    const { theme } = this.props.theme;
    const {
      hasLoaded,
      customer,
      pageConfig,
      customerPremise: premise,
      premises
    } = this.props.customer;

    if (!hasLoaded) {
      return null;
    }

    const data = this.state.data;
    const errorFlags = {
      NoDataDayThresholdExceededFlag: data.NoDataDayThresholdExceededFlag,
      NoDataFlag: data.NoDataFlag
    };
    const tariffs = data.TariffTypes;
	const nonSolarTariffs = data.NonSolarTariffTypes || tariffs;
    const chartData = data.MeteredUsageRecords;
    const isOnSolarTariff = data.HasSolarTariff;
    const loadForwardDisabled = this.state.dataIndex >= -1;



    let noDataErrorMessage =
      "There seems to be a problem getting your energy usage data. We are looking into " +
      "it and will update your data as soon as it becomes available.";

    let noDataForCurrentPremiseMessage = (
      <div>
        <b>{premise && premise.address}</b>
        <br />
        was not active at this date. You have no data to view.
      </div>
    );

    let noDataForViewingPremiseMessage = (
      <div>
        <b>{this.state.serviceAgreementAddress}</b>
        <br />
        was not active at this date. You have no data to view.
      </div>
    );

    let noDataReason = "";

    if (
      data.ServiceAgreements &&
      data.ServiceAgreements[this.state.serviceAgreementID]
    ) {
      noDataReason = noDataErrorMessage;
    } else {
      noDataReason = this.isViewingDifferentServiceAgreement()
        ? noDataForViewingPremiseMessage
        : noDataForCurrentPremiseMessage;
    }

    //Case 604, added to check if a customer is meant to experience the off the product view (determined by API if all the SAs are inactive) but then to allow through a person with hub I and CCB A (fresh churn)
    if (
      (premise && premise.isActive) === false ||
      (customer && customer.productLevel === "OFFPRODUCT")
    ) {
      return (
        <UsageLayout>
          <OffProductView icon={<UsageIcon height="160px" />} />
        </UsageLayout>
      );
    }

	let graphWarningContent = (this.state.hasT22Transition && (this.state.timeRange !== UsageDataHelper.DAY)) ? (
		this.state.unitOfMeasurement === "KilowattHourUsage" ? (
			<p>
				You switched energy plans part way through this period so there will be gaps in your usage displayed on this graph. This will be back to normal in the next period.{' '}
				<Link to="/transactionhistory/statements">View your statements here</Link>.
			</p>
		) : (
			<p>
				Due to the tiered structure of the T22 energy plan, energy costs are calculated when your bill is issued so you can’t see them here. {' '}
				<Link to="/transactionhistory/statements">View your statements here</Link>.
			</p>

		)
	) : null;

    return (
      <UsageLayout>
        <Modal
          name={
            this.state.modal.title
              ? `usage/${this.state.modal.title
                  .toLowerCase()
                  .replace(" ", "-")}`
              : false
          }
          size="tiny"
          open={this.state.modal.isOpen}
          onClose={this.handleCloseModal}
        >
          <Modal.Header>{this.state.modal.title}</Modal.Header>
          <Modal.Content>
            <Modal.Description
              style={{
                textAlign: "left"
              }}
            >
              {this.state.modal.description}
            </Modal.Description>
          </Modal.Content>
          <Modal.Actions>
            <Button
              secondary
              fluid
              onClick={this.handleCloseModal}
              style={{ backgroundColor: theme.primary.backgroundColor }}
            >
              OK
            </Button>
            {this.state.modal.learnMore && (
              <React.Fragment>
                <Button
                  fluid
                  basic
                  as={ExternalLink}
                  href={
                    this.state.modal.link
                      ? this.state.modal.link
                      : Urls.USAGE_LEARN_MORE_PDF
                  }
                >
                  Learn More
                </Button>
              </React.Fragment>
            )}
          </Modal.Actions>
        </Modal>

        <Modal
          name={
            this.state.solarInfoModal.title
              ? `usage/${this.state.solarInfoModal.title
                  .toLowerCase()
                  .replace(" ", "-")}`
              : false
          }
          size="small"
          open={this.state.solarInfoModal.isOpen}
          onClose={this.handleCloseSolarInfoModal}
        >
          <Modal.Header>{this.state.solarInfoModal.title}</Modal.Header>
          <Modal.Content>
            <Modal.Description
              style={{
                textAlign: "left"
              }}
            >
              {this.state.solarInfoModal.description}
            </Modal.Description>
          </Modal.Content>
          <Modal.Actions>
            <Button
              secondary
              fluid
              onClick={this.handleCloseSolarInfoModal}
              style={{ backgroundColor: theme.primary.backgroundColor }}
            >
              OK
            </Button>
            {this.state.solarInfoModal.learnMore && (
              <Button
                fluid
                basic
                as={ExternalLink}
                href={this.state.solarInfoModal.link}
              >
                Learn More
              </Button>
            )}
          </Modal.Actions>
        </Modal>

        <Button.Group className={"tab-button-group"}>
		  {this.state.showEnergyTab ? this.renderTabButton("DollarValueUsage", "Amount in Dollars") : null}
		  {this.renderTabButton("KilowattHourUsage", "Energy Usage")}
        </Button.Group>

        <Responsive greaterThan="tablet" className="usage-layout">
          <Segment
            style={{
              borderTopLeftRadius: 0,
              borderTop: "none"
            }}
          >
            <Grid>
              {isOnSolarTariff && (
                <Grid.Column width={16}>
                  <SolarHeader
                    toggle={this.solarSwitch}
                    toggleVal={this.state.solarToggle}
                    solarModal={this.openSolarInfoModal}
                  />
                </Grid.Column>
              )}
              <Grid.Column width={10}>
                <Header className="usage-graph-header" textAlign="center">
                  <Icon
                    disabled={!customer || this.state.loadingNewData}
                    style={{
                      float: "left",
                      cursor: "pointer",
                      color: theme.primary.color
                    }}
                    name="angle double left"
                    onClick={ev => this.getMoreData(true, ev.target.classList)}
                  />{" "}
                  {this.getChartTitle()}
                  <Icon
                    style={{
                      float: "right",
                      cursor: "pointer",
                      color: theme.primary.color
                    }}
                    disabled={
                      !customer ||
                      loadForwardDisabled ||
                      this.state.loadingNewData
                    }
                    name="angle double right"
                    onClick={ev => this.getMoreData(false, ev.target.classList)}
                  />
                </Header>

                <UsageLegend
                  openModal={id => this.openModal(id)}
                  solar={this.state.solarToggle}
                  tariffs={tariffs}
                />

                <GroupedBarChart
                  productType={pageConfig.productName}
                  theme={theme}
                  noDataReason={noDataReason}
                  unitOfMeasurement={this.state.unitOfMeasurement}
                  timeRange={this.state.timeRange}
                  data={chartData || []}
                  tariffs={tariffs}
				  nonSolarTariffs={nonSolarTariffs}
                  hasDaySubstituted={
                    this.state.data.summaryDetailsDayContainsSubstitutedUsage
                  }
                  hasSolar={isOnSolarTariff}
                  solar={this.state.solarToggle}
                  weather={this.state.weatherData}
                  weatherInfo={pageConfig.functionality.weatherInfo}
                  errorFlags={errorFlags}
				  graphWarningContent={graphWarningContent}
                  animateData={this.state.animateData}
                  loadingData={this.state.loadingNewData}
                  size={{ width: 540, height: this.graphHeight() }}
                />
              </Grid.Column>
              <Grid.Column width={6}>
				{ this.state.showSummaryCard &&
				  <React.Fragment>
					<UsageSummaryView
						data={this.state.data}
						customerType={this.props.customer.customer.customerType}
						unitOfMeasurement={this.state.unitOfMeasurement}
						showOtherCharges={this.state.showOtherCharges}
						toggleShowOtherCharges={this.showOtherCharges}
						timeRange={this.state.timeRange}
						openModal={this.openModal}
						hasSolar={isOnSolarTariff}
						productType={pageConfig.productName}
						history={this.props.history}
						auroraInsightsEnabled={
							pageConfig.functionality.auroraInsights
						}
						showDollarValues={this.state.showSummaryDollarValues}
						hasT22={this.state.hasT22}
					/>
				  </React.Fragment>
				}
              </Grid.Column>
            </Grid>

            {this.state.solarToggle ? (
              <SolarSystemSize />
            ) : (
	          (!pageConfig.functionality.auroraInsights && pageConfig.functionality.insightsAndTips) && (
                <InsightsAndTips
                  insight={this.state.insight}
                  tip={this.state.tip}
                  hasSolar={isOnSolarTariff}
                  margin="1rem 0 -1rem 0"
                  bordered
                  openModal={this.openModal}
                />
              )
            )}
          </Segment>
        </Responsive>

        <Responsive lessThan="computer" className="usage-layout">
          <Segment
            style={{
              borderTop: "none",
              borderTopRightRadius: 0,
              borderTopLeftRadius: 0
            }}
          >
            {isOnSolarTariff && (
              <SolarHeader
                toggle={this.solarSwitch}
                toggleVal={this.state.solarToggle}
                solarModal={this.openSolarInfoModal}
              />
            )}
            <Header className="usage-graph-header" textAlign="center">
              <Icon
                disabled={
                  !this.props.customer.customer || this.state.loadingNewData
                }
                style={{
                  float: "left",
                  color: theme.primary.color
                }}
                name="angle double left"
                onClick={ev => this.getMoreData(true, ev.target.classList)}
              />{" "}
              {this.getChartTitle()}
              <Icon
                style={{
                  float: "right",
                  color: theme.primary.color
                }}
                disabled={
                  !this.props.customer.customer ||
                  loadForwardDisabled ||
                  this.state.loadingNewData
                }
                name="angle double right"
                onClick={ev => this.getMoreData(false, ev.target.classList)}
              />
            </Header>

            <UsageLegend
              openModal={id => this.openModal(id)}
              solar={this.state.solarToggle}
              tariffs={tariffs}
              mobile={true}
            />

            <GroupedBarChart
              productType={pageConfig.productName}
              theme={theme}
              noDataReason={noDataReason}
              unitOfMeasurement={this.state.unitOfMeasurement}
              timeRange={this.state.timeRange}
              data={chartData || []}
              hasDaySubstituted={
                this.state.data.summaryDetailsDayContainsSubstitutedUsage
              }
              tariffs={tariffs}
			  nonSolarTariffs={nonSolarTariffs}
              hasSolar={isOnSolarTariff}
              solar={this.state.solarToggle}
              weather={this.state.weatherData}
              weatherInfo={pageConfig.functionality.weatherInfo}
              mobile={true}
              errorFlags={errorFlags}
			  graphWarningContent={graphWarningContent}
              animateData={this.state.animateData}
              loadingData={this.state.loadingNewData}
              size={{ width: 400, height: this.graphHeight() }}
            />
          </Segment>

		  { this.state.showSummaryCard &&
			  <React.Fragment>
				<Segment>
					<UsageSummaryView
						data={this.state.data}
						customerType={this.props.customer.customer.customerType}
						unitOfMeasurement={this.state.unitOfMeasurement}
						showOtherCharges={this.state.showOtherCharges}
						toggleShowOtherCharges={this.showOtherCharges}
						timeRange={this.state.timeRange}
						openModal={this.openModal}
						hasSolar={isOnSolarTariff}
						productType={pageConfig.productName}
						history={this.props.history}
						auroraInsightsEnabled={pageConfig.functionality.auroraInsights}
						showDollarValues={this.state.showSummaryDollarValues}
						hasT22={this.state.hasT22}
					/>
				</Segment>
			</React.Fragment>
  		  }

          {this.state.solarToggle ? (
            <Segment>
              <SolarSystemSize />
            </Segment>
          ) : (
            (!pageConfig.functionality.auroraInsights && pageConfig.functionality.insightsAndTips) && (
              <Segment>
                <InsightsAndTips
                  insight={this.state.insight}
                  tip={this.state.tip}
                  hasSolar={isOnSolarTariff}
                  margin="-1rem 0"
                  openModal={this.openModal}
                />
              </Segment>
            )
          )}
        </Responsive>
      </UsageLayout>
    );
  }
}

export default withRouter(withApp(withCustomer(withTheme(Usage))));
