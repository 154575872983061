import React from "react";
import Layout from "../../../layouts/Layout.js";
import BareLayout from "../../../layouts/BareLayout.js";
import IframeContainer from "../../../components/IframeContainer/IframeContainer.js";
import useScrollToTop from "../../../utils/useScrollToTop.js";

export default function BusinessExternalSignup() {
	
	useScrollToTop();

	const formUrl = `${process.env.REACT_APP_AURORA_WEBSITE}/aurora-plus/business/signup?referrer=app_signup&display=stripped`;	
	
	return (
		<Layout
			layout={BareLayout}
			journey={null}
			noContainer={true}
		>
			<IframeContainer src={formUrl} title="Signup form" />
		</Layout>
	);

}
