import { createContext, useContext, useEffect, useReducer } from 'react'
import { Config } from '../config/configData'
import { useAuth } from './Auth'

const initialState = {
  hasLoaded: false,
  isAuthenticated: false,
  isInterimScreenOpen: false,
  isNoProductScreenOpen: false,
  hasMultipleProducts: false,
  customers: [],
  customer: null,
  setPremiseType: () => null,
  premises: [],
  allPremises: [],
  customerPremise: null,
  powerHours: null,
  pageConfig: Config(),
  setConfig: () => null,
  setCustomerPremise: () => null,
  refresh: () => null,
  set: () => null,
  updateUnreadNotificationCount: () => null,
  isFromHubCX: false,
  lastRefreshTime: ''
}

function customerReducer(state, payload) {
  return { ...state, ...payload }
}

export const CustomerContext = createContext(initialState)

export function CustomerProvider({ children }) {
  const [state, setState] = useReducer(customerReducer, initialState)
  const { isAuthenticated } = useAuth()

  useEffect(() => {
    if (isAuthenticated && !state.isAuthenticated) {
      setState({ isAuthenticated })
    }
    if (!isAuthenticated && state.isAuthenticated) {
      setState({ isAuthenticated })
    }
  }, [state.isAuthenticated, isAuthenticated])

  const customer = {
    ...state,
    setState,
    set: props => setState(props)
  }

  return (
    <CustomerContext.Provider value={customer}>
      {children}
    </CustomerContext.Provider>
  )
}

export const useCustomer = () => useContext(CustomerContext)

export function withCustomer(Component) {
  return function WrappedComponent(props) {
    return (
      <CustomerContext.Consumer>
        {customer => <Component {...props} customer={customer}/>}
      </CustomerContext.Consumer>
    )
  }
}
