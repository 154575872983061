import React from 'react'
import { Button, Modal } from 'semantic-ui-react'

function DirectDebitConfirmModal(props) {

  const { openModal, closeModal, confirmModal, isOpen, theme } = props;

  return (
    <Modal
      style={{ background: '#fff' }}
      onClose={() => closeModal()}
      onOpen={() => openModal()}
      size='mini'
      open={isOpen}>
        <Modal.Header>Confirm direct debit details</Modal.Header>
        <Modal.Content>
          <Modal.Description style={{
            textAlign: 'center'
          }}>
          <p>
            Your future bills will be paid in full by the due date using your saved direct debit information.
          </p>
        </Modal.Description>
      </Modal.Content>
      <Modal.Actions>
        <Button
          className="state-button"
          secondary
          fluid
          style={{ backgroundColor: theme.primary.backgroundColor }}
          onClick={() => confirmModal()}>
          Confirm
        </Button>
        <Button
          fluid
          type="button"
          color='grey'
          onClick={() => closeModal()}>
          Cancel
        </Button>
      </Modal.Actions>
    </Modal>
  )
}

function DirectDebitSavedModal(props) {

  const { openModal, closeModal, isOpen, theme } = props;

  return (
    <Modal
      style={{ background: '#fff' }}
      onClose={() => closeModal()}
      onOpen={() => openModal()}
      size='mini'
      open={isOpen}>
        <Modal.Header>Direct debit details saved</Modal.Header>
          <Modal.Content>
            <Modal.Description style={{ textAlign: 'center' }}>
              <p>
                Your direct debit details have been saved. You can update or stop direct debit at any time on the payment screen.
              </p>
            </Modal.Description>
          </Modal.Content>
          <Modal.Actions>
        <Button
          className="state-button"
          secondary
          fluid
          style={{ backgroundColor: theme.primary.backgroundColor }}
          onClick={() => closeModal()}>
          OK
        </Button>
      </Modal.Actions>
    </Modal>
  )
}

function DirectDebitStopModal(props) {

  const { openModal, closeModal, confirmModal, isOpen, theme } = props;

  return (
    <Modal
      style={{ background: '#fff' }}
      onClose={() => closeModal()}
      onOpen={() => openModal()}
      size='mini'
      open={isOpen}>
      <Modal.Header>Stopping direct debit</Modal.Header>
      <Modal.Content>
        <Modal.Description style={{ textAlign: 'center' }}>
          <p>Are you sure you want to stop your direct debit?</p>
        </Modal.Description>
      </Modal.Content>
      <Modal.Actions>
        <Button
          className="state-button"
          secondary
          fluid
          style={{ backgroundColor: theme.primary.backgroundColor }}
          onClick={() => confirmModal()}>
          Confirm
        </Button>
        <Button
          className="state-button"
          fluid
          onClick={() => closeModal()}>
          Cancel
        </Button>
      </Modal.Actions>
    </Modal>
  )
}

function UsingDirectDebitModal(props) {

  const { openModal, closeModal, confirmModal, isOpen, theme } = props;

  return (
    <Modal
      style={{ background: '#fff' }}
      onClose={() => closeModal()}
      onOpen={() => openModal()}
      size='mini'
      open={isOpen}>
      <Modal.Header>Using direct debit</Modal.Header>
      <Modal.Content>
        <Modal.Description style={{ textAlign: 'center' }}>
          <p>Direct debit will use your saved bank account details to automatically pay your bill in full by the due date. You can update or stop your direct debit at any time.</p>
        </Modal.Description>
      </Modal.Content>
      <Modal.Actions>
        <Button
          className="state-button"
          fluid
          style={{ backgroundColor: theme.primary.backgroundColor, color:'white' }}
          onClick={() => closeModal()}>
          Ok
        </Button>
      </Modal.Actions>
    </Modal>
  )
}


export {
  DirectDebitSavedModal,
  DirectDebitConfirmModal,
  DirectDebitStopModal,
  UsingDirectDebitModal
};
