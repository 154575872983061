import React from 'react';
import axios from 'axios';
import { Segment, Item, Button, Grid, Header } from 'semantic-ui-react';
import moment from 'moment-timezone';
import _ from 'lodash';

import Responsive from "../components/Responsive/Responsive";
import Layout from '../layouts/Layout';
import FullWidthLayout from '../layouts/FullWidthLayout';
import ColumnLayout from '../layouts/ColumnLayout';
import { withRouter } from '../utils/useRouter';
import { withApp } from '../contexts/App'
import { withCustomer } from '../contexts/Customer'
import { withTheme } from '../contexts/Theme';
import InfiniteList from './../components/InfiniteList';
import Modal from './../components/Modal';
import MobileAppComms from './../utils/MobileAppComms';
import { openBill } from './../utils/PDF';

export class TransactionHistory extends React.Component {

  state = {
    pageRows: 10,
    pageNumber: 1,
    tabChangeCount: 0,
    loading: true,
    moreToLoad: true,
    currentTab: '',
    modal: {
      isOpen: false,
      title: '',
      description: ''
    }
  }

  descriptionFormatters = {
    "^(BPay/Locked Bag) - (.+)$": (match) => (
      <div style={{
        textAlign: 'right'
      }}>{match[1]}<br/>
        <small style={{
          opacity: 0.5
        }}>{match[2]}</small>
      </div>
    ),
    "(.+) - (.+) (Post Office)$": (match) => (
      <div style={{
        textAlign: 'right'
      }}>{match[3]}<br/>
        <small style={{
          opacity: 0.5
        }}>{match[1]} {match[2]}</small>
      </div>
    ),
    "System Calculated Interest": "Interest Charge",
    "Re-energisation Standard": "Reconnection Charge",
    "Credit Card - Master": "Credit Card - Mastercard",
    "Electronic Funds Transfer": "Direct Deposit"
  }

  constructor(props) {
    super(props)
    this.listBillsRef = React.createRef()
    this.listTransactionsRef = React.createRef()
  }

  componentDidMount() {
    const { router } = this.props;

    this.setState({
      currentTab: (router && router.params) ? router.params.tab : "transactions",
      tabChangeCount: 0
    });
  }

  componentDidUpdate(prevProps, prevState) {
    const { router } = this.props;
    const { router: prevRouter } = prevProps;

    if (router && router.params && prevRouter.params && prevRouter.params.tab != router.params.tab) {
      this.setState({
        currentTab: router.params.tab,
        tabChangeCount: this.state.tabChangeCount + 1
      });
    }
  }

  formatDate = (dateStr) => {
    return moment(dateStr).format('D MMM YYYY')
  }

  formatCurrency = (amount) => {
    const prefix = amount < 0
      ? '-'
      : '+'
    const formattedAmount = Math
      .abs(amount)
      .toLocaleString('en-AU', {
        currency: 'AUD',
        minimumFractionDigits: 2
      })

    return `${prefix} $${formattedAmount}`
  }

  formatType = (code) => {
    switch (code) {
      case 'PS':
        return 'Payment'
      default:
        return `Unknown Code (${code})`
    }
  }

  formatDescription = (transaction) => {

    if (transaction.Type === "Original Invoice") {
      return (
          <React.Fragment>
            <span>
              {transaction.BillID}
            </span>
            <a style={{ opacity: 0.6, paddingLeft: "5px" }} onClick={() => this.billClicked(transaction.BillID)}>View PDF</a>
          </React.Fragment>
        );
    }

    return transaction.Description;
  }
  
  tabButtonChanged = tabName => {
    this.setState({
      currentTab: tabName,
      tabChangeCount: this.state.tabChangeCount + 1
    });
  }

  renderTabButton = (tabName, title) => {
    const isActive = this.state.currentTab === tabName;
    const { theme } = this.props.theme;

    return (
      <Button
        style={{
          backgroundColor: isActive ? '' : theme.primary.color,
          color: isActive ? theme.primary.color : ''
        }}
        className={`tab-button ${isActive
        ? 'tab-button-active'
        : ''}`}
        onClick={() => this.tabButtonChanged(tabName)}>{title}</Button>
    );
  }

  handleCloseModal = () => {
    this.setState({
      modal: {
        isOpen: false
      }
    });
  }

  openModal = (title, description) => {
    this.setState({
      modal: {
        isOpen: true,
        description,
        title
      }
    });
  }

  renderCurrentTab = () => {
    const { TransactionLayout } = this
    const { currentTab } = this.state;

    let list = null;
    let balance = null;
    let heading = '';

    if (currentTab === 'transactions') {
      list = this.renderTransactionList();
      balance = this.renderBalance();
      heading = 'Transactions';
    }
    else if (currentTab === 'statements') {
      list = this.renderBillSegments();
      heading = 'Statements';
    }

    return (
      <TransactionLayout>
        <Responsive greaterThan="tablet">
          <Header
            textAlign='center'
            size='large'
            style={{
            fontWeight: 500,
            marginBottom: '1.5rem'
          }}>
            {heading}
          </Header>
        </Responsive>
        {balance}
        {list}
      </TransactionLayout>
    )
  }

  TransactionLayout = (props) => {
    return (
      <Segment padded className="main-wrapper layout-default transaction-history-layout"
        style={{marginTop: 0, borderTop: 0}}>
        <ColumnLayout column={{ mobile: 16, tablet: 10, computer: 8 }}>
          { props.children }
        </ColumnLayout>
      </Segment>
    );
  }

  renderTransactionList = () => {
    const { customerPremise, customer } = this.props.customer

    if (!customerPremise)
      return null;

    const { theme } = this.props.theme;

    //Added for Case #504, updated the endpoint to also require a parentAccountNumber, which is now sent. Also added an empty catch to at least 'handle' any future exceptions thrown
    return (
      <InfiniteList
        key={customerPremise.parentAccountID + this.state.tabChangeCount}
        ref={this.listTransactionsRef}
        isSideMenuOpen={this.props.app.isSideMenuOpen}
		manualLoadMore={true}
        getData={(pageRows, pageNumber) => 
          axios
            .get(process.env.REACT_APP_API_BASE_URI + `transactions?accountId=${customerPremise.parentAccountID}&parentAccountNumber=${customerPremise.parentAccountNumber}&isOldCCB=${customerPremise.serviceAgreementStatus === "Old_CCB"}&pageRows=${pageRows}&pageNumber=${pageNumber}`)
            .then(res => {
              if (!res.data) return res.data;

              return _.filter(res.data, (obj) => {
                // APPE-220
                if (customerPremise.hasPrePayBilling && obj.Type === "Original Invoice") {
                    return false;
                }

                // we only allow transactions that have a linked PDF available for the user to download
                return obj.VisibleInStatement;
              });
            })
            .catch()
        }
        children={({
          item,
          ...props
        }) => {
          return (
            <Item key={`${item.Identifier}-${props.itemIndex}`} className="notification transaction" style={{ backgroundColor: theme.secondary.backgroundColor }}>
              <Item.Content style={{ backgroundColor: "inherit", borderRadius: '5px' }}>
                <Item.Header style={{ backgroundColor: theme.tertiary.backgroundColor, lineHeight: '33px' }}>
                  {item.Type === "Original Invoice"
                    ? <div>Bill issued</div>
                    : <div>Payment received</div>
                  }
                  {this.formatDate(item.TransactionDate)}
                </Item.Header>
                <Item.Meta className="bill-transaction">
                  <div className="transaction-description" style={{ color: theme.tertiary.backgroundColor, fontWeight: 600 }}>
                    {this.formatDescription(item, customerPremise.parentAccountID)}
                  </div>
                  <div
                    className={`currency ${item.CurrentAmount >= 0 ? 'negative' : 'positive'}`}
                    style={{ color: theme.tertiary.backgroundColor, fontWeight: 600 }}>
                    {this.formatCurrency(-1 * item.CurrentAmount)}
                  </div>
                </Item.Meta>
              </Item.Content>
            </Item>
          )
        }} />
    );
  }

  renderBalance = () => {
    const { customerPremise } = this.props.customer;
    const { theme } = this.props.theme;

    if (!customerPremise || customerPremise.hasPrePayBilling)
      return null;

    const currentBalance = Math.abs(customerPremise.estimatedBalance)
      .toLocaleString('en-AU', {
        currency: 'AUD',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      });

    return (
      <div style={{ textAlign:"center"}}>
        <p style={{ margin: 0, lineHeight: '40px' }}>
          Current account balance
        </p>
        <p
          style={{
            color: theme.tertiary.backgroundColor,
            fontSize: '52px',
            lineHeight: '70px',
            marginBottom: '15px'
        }}>
          {customerPremise.estimatedBalance >= 0 ? `$${currentBalance}` : `-$${currentBalance}`}
        </p>
      </div>
    )
  }

  billClicked = billId => {
    const { customerPremise } = this.props.customer;
    const { isApp } = this.props.app;

    openBill(billId, customerPremise.parentAccountID, isApp, customerPremise.parentAccountNumber, customerPremise.serviceAgreementStatus).catch(() => {
      this.openModal('Oops!', `We can't retrieve your statement right now. Please try again later or contact the Aurora Energy Team by clicking on Contact Us in your App menu.`);
    });
  }

  renderBillSegments = () => {
    let {customerPremise} = this.props.customer
    if (!customerPremise)
      return null;

    const { isApp } = this.props.app;
    const parentAccountID = customerPremise.parentAccountID;
  
    //Added for Case #504, updated the endpoint to also require a parentAccountNumber, which is now sent. Also Emptied the catch as it caused the page to break if it had err =>{} in it
    return (
      <InfiniteList
        key={parentAccountID + this.state.tabChangeCount}
        ref={this.listBillsRef}
        isSideMenuOpen={this.props.app.isSideMenuOpen}
		manualLoadMore={true}
        getData={(pageRows, pageNumber) =>
          axios
            .get(process.env.REACT_APP_API_BASE_URI + `bills/${parentAccountID}?parentAccountNumber=${customerPremise.parentAccountNumber}&isOldCCB=${customerPremise.serviceAgreementStatus === "Old_CCB"}&pageNumber=${pageNumber}&pageRows=${pageRows}`)
            .then(res => {
			  return res.data ? res.data.filter(doc => {
				return doc.InvoiceDocNumber && doc.StatementAvailable && !doc.InvoiceDocNumber.includes("CCB");
			  }) : [];
            })
            .catch()
		    }
        children={({
          item,
          ...props
        }) => (
            <div key={item.BillId} className="bill-segment-item">
              <div>
                <span className="bill-segment-date">{moment(item.DateComplete).format('D MMM YYYY')}</span>
                <Button
                  className="bill-segment-view-button"
                  onClick={() => this.billClicked(item.BillId)}>
                  View PDF
                </Button>
              </div>
            </div>
        )}
      />
    );
  }

  render() {
    const { hasLoaded } = this.props.customer;
    
    if (!hasLoaded || !this.state.currentTab)
      return null;
    
    //Added for Case #504, the ability to show oldCCBAccountsDisplayed, which was added as a prop and set to true for this page
    return (
      <Layout
        layout={FullWidthLayout}
        oldCCBAccountsDisplayed={true}
        pageTitle="Transaction history"
        onPullDownRefresh={props => {
        let list = this.state.currentTab === 'transactions'
          ? this.listTransactionsRef
          : this.listBillsRef;
        list && list.current && list
          .current
          .getData(1, true)
      }}>

        <Modal
          name='bill-statement-error'
          size='tiny'
          open={this.state.modal.isOpen}
          onClose={this.handleCloseModal}>
          <Modal.Header>{this.state.modal.title}</Modal.Header>
          <Modal.Content>
            <Modal.Description style={{
              textAlign: 'left'
            }}>{this.state.modal.description}</Modal.Description>
          </Modal.Content>
          <Modal.Actions>
            <Button secondary fluid onClick={this.handleCloseModal}>OK</Button>
          </Modal.Actions>
        </Modal>

        {/* Mobile Tab Buttons */}
        <Responsive lessThan="computer">

          <Button.Group className="tab-button-group">
            {this.renderTabButton('transactions', "Transaction history")}
            {this.renderTabButton('statements', "Statements")}
          </Button.Group>

          {this.renderCurrentTab()}

        </Responsive>

        {/* Desktop Tab Buttons */}
        <Responsive greaterThan="tablet">
          <Grid centered>
            <Grid.Column computer={4}>
              <Button.Group className="transaction-history-desktop-tab-group">
                {this.renderTabButton('transactions', "Transaction history")}
                {this.renderTabButton('statements', "Statements")}
              </Button.Group>
            </Grid.Column>

            <Grid.Column computer={12}>
              {this.renderCurrentTab()}
            </Grid.Column>

          </Grid>
        </Responsive>

      </Layout>
    );
  }
}

export default withRouter(withApp(withCustomer(withTheme(TransactionHistory))))
