import React from 'react';

import {Container, Segment} from 'semantic-ui-react';
import './Footer.css';

export default class Footer extends React.Component {

  render() {
    return (
      <Segment className="ui menu bottom footer">
        <Container>
          <p style={{
            margin: '0.5em 1em'
          }}>&copy; 2019 Aurora Energy Pty Ltd ABN 85 082 464 622</p>
        </Container>
      </Segment>
    )
  }
}
