import React from 'react';
import moment from 'moment-timezone';
import {
  Statistic,
  Button,
  Card,
  Header
} from 'semantic-ui-react';


export const LastUpdateCard = (props) => {
  const { customerPremise: premise, lastRefreshTimeString } = props.customer;

  if (!premise) return null;

  return (
    <Card style={{
      marginTop: 0,
      width: '100%'
    }}>
      <Card.Content
        textAlign="center"
        style={{
          padding: '1em',
          backgroundColor: 'white'
        }}>
        <Header
          as="h5"
          textAlign="center"
          style={{
            color: '#0060ae',
            fontFamily: 'Campton',
            fontWeight: 'normal'
          }}>
          Information last updated {premise && lastRefreshTimeString}
        </Header>
      </Card.Content>
    </Card>
  );
}

//export const TimeOfUseCard = (props) => {
//  const { customerPremise: premise } = props.customer;

//  if (!premise) return null;

//  getTimeOfUseString = (endDateString) => {
//    const end = moment(endDateString);
//    const isMonday = (end.day() === 1);
//    const is7amOr8am = ((end.hour() === 7 || end.hour() === 8) && end.minute() === 0);

//    // display 'Day' with string if on weekends (APAYGAPP-1191) if the end time is
//    // monday 7am or 8am (daylight savings) we can assume its on a weekend
//    if (isMonday && is7amOr8am) {
//      return `until ${end.format('dddd [at] h:mm A')}`;
//    } else {
//      return `until ${end.format('h:mm A')}`;
//    }
//  }

//  return (
//    <Card style={{
//      width: '100%',
//      height: !evenNumberCards && '211px',
//      minHeight: '130px'
//    }}>
//      <Card.Header textAlign="center">
//        Current time period
//        <InfoIcon style={{ float: 'right' }} onClick={() => this.openModal('modalPeakOffPeakIndicator')} />
//      </Card.Header>
//      <Card.Content style={{ backgroundColor: 'white' }} textAlign="center">
//        {premise && premise.currentTimeOfUsePeriodEndDate && (
//          <Statistic style={{ width: '100%' }} color='blue'>
//            <Statistic.Value
//              id="time-of-use-indicator-text"
//              style={{ textTransform: 'capitalize' }}>
//              {premise.currentTimeOfUseType && premise.currentTimeOfUseType.toLowerCase()}
//              <span style={{
//                fontSize: '24px',
//                lineHeight: '0px',
//                padding: hasMultipleBills ? '23px 0' : '25px 0',
//                display: !evenNumberCards || hasMultipleBills && 'block',
//              }}>
//                &nbsp;{this.getTimeOfUseString(premise.currentTimeOfUsePeriodEndDate)}
//              </span>
//            </Statistic.Value>
//          </Statistic>
//        )}
//      </Card.Content>
//    </Card>
//  );
//}
