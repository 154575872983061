import React from "react";
import { Header, Table, Icon, Button } from "semantic-ui-react";

import UsageOtherChargesTooltip from "./UsageOtherChargesTooltip";
import InfoIcon from "../../../components/Icons/InfoIcon";

import UsageDataHelper from "../UsageDataHelper";
import { tariffSearch, solarCheck } from "../../../utils/Tariffs";
import Urls from "../../../utils/Urls";
import Log from "../../../utils/Log";
import "./UsageSummaryView.css";

const UsageSummaryView = props => {
  //customerType added to props case 553 from the Usage page, what type the current customer is residential or business
  const {
    data,
    customerType,
    unitOfMeasurement,
    showOtherCharges,
    toggleShowOtherCharges,
    timeRange,
    openModal,
    productType,
    history,
    auroraInsightsEnabled,
	showDollarValues,
	hasT22
  } = props;

  let details = {};
  let totalUsageLabel = "";
  let timeRangeHeading = "";
  let usageModalId = "";
  let substitutedData = data.summaryDetailsDayContainsSubstitutedUsage;
  var isT93T94 = false;
  if (data.TariffTypes != null) {
    data.TariffTypes.forEach(tariff => {
      if (
        tariff == "T93PEAK" ||
        tariff == "T93OFFPEAK" ||
        tariff == "T94PEAK" ||
        tariff == "T94OFFPEAK"
      )
        isT93T94 = true;
    });
  }
  switch (timeRange) {
    case UsageDataHelper.DAY:
      timeRangeHeading = "Daily";
      break;
    case UsageDataHelper.MONTH:
      timeRangeHeading = "Monthly";
      break;
    case UsageDataHelper.QUARTER:
      timeRangeHeading = "Quarterly";
      break;
    case UsageDataHelper.WEEK:
      timeRangeHeading = "Weekly";
      break;
    case UsageDataHelper.YEAR:
      timeRangeHeading = "Yearly";
      break;
    default:
      break;
  }

  //Case 553 updated the modals, now there are separate ones for residential vs business customers.
  //A new conditional checker for customer type added to usageModalId, which is what the UsageSummaryView uses as a basis for what modal content to display
  if (timeRangeHeading === "Daily" && substitutedData) {
    if (unitOfMeasurement === "DollarValueUsage") {
      details = data.summaryDetailsDollarsValue || details;
      totalUsageLabel = "Estimated Usage";
      timeRangeHeading += " Cost";
      usageModalId =
        customerType == "residential"
          ? "usageSummaryResiDollars"
          : "usageSummarySMBDollars";
    } else {
      details = data.summaryDetailsKilowattValue || details;
      totalUsageLabel = "";
      timeRangeHeading += " Usage"; // DPS-359 Remove the (Estimated) indicator
      usageModalId =
        customerType == "residential"
          ? "usageSummaryResiKwH"
          : hasT22 ? "usageSummaryT22KwH"
		  		   : "usageSummarySMBKwH";
    }
  } else {
    if (unitOfMeasurement === "DollarValueUsage") {
      details = data.summaryDetailsDollarsValue || details;
      totalUsageLabel = "Total Usage";
      timeRangeHeading += " Cost";
      usageModalId =
        customerType == "residential"
          ? "usageSummaryResiDollars"
          : "usageSummarySMBDollars";
    } else {
      details = data.summaryDetailsKilowattValue || details;
      totalUsageLabel = "Total Cost";
      timeRangeHeading += " Usage";
      usageModalId =
        customerType == "residential"
          ? "usageSummaryResiKwH"
          : hasT22 ? "usageSummaryT22KwH"
		  		   : "usageSummarySMBKwH";
    }
  }
  const hasOtherCharges =
    data.NonMeteredUsageRecords && data.NonMeteredUsageRecords.length > 0;
  const link = productType === "business" ? Urls.BUSINESS_USAGE_LEARN_MORE : "";

 
  // DPS-211 Remove grandfathered T140 from the summary
  if (("T140" in details) && ("T110" in details)) {

	// Dollars come through with the T110 zeroed and a value in T140
	// account for both ways just in case, but only ever remove one of them
  	if ((details["T140"] === '0.0 kWh') || (details["T140"] === '$0.00')) {
		delete details["T140"];
	} else if ((details["T110"] === '0.0 kWh') || (details["T110"] === '$0.00')) {
		delete details["T110"];
	}

  }

  return (
    <React.Fragment>
      <Header as="h4" className="usage-details-header" textAlign="center">
        {substitutedData ? "" + timeRangeHeading : "Your " + timeRangeHeading}
        <InfoIcon
          className="usage-summary-info-icon"
          onClick={() => openModal(usageModalId, link)}
        />
      </Header>

      <Table
        className="usage-summary-table"
        unstackable
        basic="very"
        striped
        compact
      >
        <Table.Body>
          {Object.keys(details).map(
            (
              item // non-Solar tariffs
            ) =>
              !solarCheck(item) && (
                <Table.Row key={item}>
                  <Table.Cell>{tariffSearch(item).DisplayName}</Table.Cell>
                  <Table.Cell
                    style={{
                      textAlign: "right"
                    }}
                  >
                    {details[item]}
                  </Table.Cell>
                </Table.Row>
              )
          )}

          {unitOfMeasurement === "DollarValueUsage" && (
            <Table.Row
              className={`usage-summary-other ${
                hasOtherCharges ? "clickable" : ""
              }`}
              onClick={hasOtherCharges ? toggleShowOtherCharges : () => null}
            >
              <Table.Cell>
                Other
                {hasOtherCharges && (
                  <Icon
                    fitted
                    name={showOtherCharges ? "angle up" : "angle down"}
                  />
                )}
              </Table.Cell>
              <Table.Cell>{data.summaryDetailsOtherTotal}</Table.Cell>
            </Table.Row>
          )}

          {showOtherCharges ? (
            <Table.Row>
              <Table.Cell
                style={{
                  padding: "0px"
                }}
                colSpan={2}
              >
                <UsageOtherChargesTooltip data={data} />
              </Table.Cell>
            </Table.Row>
          ) : null}

          {unitOfMeasurement === "KilowattHourUsage" ? (
            <React.Fragment>
              <Table.Row
                style={{
                  fontWeight: "bold"
                }}
              >
                <Table.Cell>Total</Table.Cell>
                <Table.Cell
                  style={{
                    textAlign: "right"
                  }}
                >
                  {data.summaryDetailsKilowattCombinedRoundedTotal}
                </Table.Cell>
              </Table.Row>

              {Object.keys(details).map(
                item =>
                  solarCheck(item) && ( // Solar tariffs
                    <Table.Row key={item}>
                      <Table.Cell>
                        {tariffSearch(item).DisplayName} (Feed-In)
                      </Table.Cell>
                      <Table.Cell
                        style={{
                          textAlign: "right"
                        }}
                      >
                        {details[item]}
                      </Table.Cell>
                    </Table.Row>
                  )
              )}

              {!substitutedData && showDollarValues && (
                <Table.Row>
                  <Table.Cell>{totalUsageLabel}</Table.Cell>
                  <Table.Cell
                    style={{
                      textAlign: "right"
                    }}
                  >
                    {data.summaryDetailsDollarsTotal}
                  </Table.Cell>
                </Table.Row>
              )}
            </React.Fragment>
          ) : (
            <React.Fragment>
              {Object.keys(details).map(
                item =>
                  solarCheck(item) && ( // Solar tariffs
                    <Table.Row key={item}>
                      <Table.Cell>
                        {tariffSearch(item).DisplayName} (Feed-In)
                      </Table.Cell>
                      <Table.Cell
                        style={{
                          textAlign: "right"
                        }}
                      >
                        {details[item]}
                      </Table.Cell>
                    </Table.Row>
                  )
              )}

              <Table.Row
                style={{
                  fontWeight: "bold"
                }}
              >
                <Table.Cell>Total</Table.Cell>
                <Table.Cell
                  style={{
                    textAlign: "right"
                  }}
                >
                  {data.summaryDetailsDollarsTotal}
                </Table.Cell>
              </Table.Row>

			  { showDollarValues &&
				<Table.Row>
					<Table.Cell>{totalUsageLabel}</Table.Cell>
					<Table.Cell
						style={{
							textAlign: "right"
						}}
					>
						{data.summaryDetailsKilowattCombinedRoundedTotal}
					</Table.Cell>
				</Table.Row>
             }
            </React.Fragment>
          )}
        </Table.Body>
      </Table>

      {auroraInsightsEnabled && (
        <Button
          style={{ borderRadius: "1.5em" }}
          secondary
          fluid
          onClick={() => history.navigate("/redirect/bidgely")}
        >
          Your aurora+ Insights
        </Button>
      )}
    </React.Fragment>
  );
};

export default UsageSummaryView;
