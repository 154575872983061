import React from "react";
import axios from "axios";
import { NavLink } from "react-router-dom";
import { withRouter } from '../../utils/useRouter';
import Device from "../../utils/Device";
import { iconSearch } from "../../utils/IconsMap";
import NotificationIcon from "../Icons/NotificationIcon";
import AccountSettingsIcon from "../Icons/AccountSettingsIcon";
import BillsAndPaymentsIcon from "../Icons/BillsAndPaymentsIcon";
import HelpIcon from "../Icons/HelpIcon";
import LogOutIcon from "../Icons/LogOutIcon";

import "./SideMenu.css";
import { Button, Sidebar, Menu, Segment, Image, Icon } from "semantic-ui-react";

import logo from "../../static/aurora_plus_logo.svg";
import trimmedLogo from "../../static/aurora_plus_logo_trimmed.svg";
import auroraLogo from "../../static/logo.svg";
import { withApp } from "./../../contexts/App";
import { withCustomer } from "./../../contexts/Customer";
import { withTheme } from "./../../contexts/Theme";
import Modal from "./../Modal";
import Analytics from "../../utils/Analytics";

const regex = new RegExp(/(AURORA\+)/, "gi");

export class SideMenu extends React.Component {
  state = {
    visible: false,
    modalLoggedOutOpen: false,
    version_web: "",
    version_api: "",
    version_app: ""
  };

  NavListItems = props => {
    const { customer, hasMultipleProducts, pageConfig } = this.props.customer;
    const {
      pageTitle,
      isSideMenuOpen,
      openSideMenu,
      showBackButton
    } = this.props.app;

    if (!pageConfig || !pageConfig.menuItems) return null;

    return pageConfig.menuItems.sideMenu
      .sort((a, b) => a.order - b.order)
      .map(navItem => {
        const icon = iconSearch(navItem.icon, {
          ...navItem.count,
          height: "100%"
        });

        // Replace aurora+ text with logo image
        const transformedText = navItem.text
          .toUpperCase()
          .split(regex)
          .map((word, i) =>
            regex.test(word) ? (
              <Image
                key="logo"
                src={trimmedLogo}
                alt="aurora+"
                style={{
                  display: "inline-block",
                  maxHeight: "0.69em",
                  verticalAlign: "unset",
                  marginRight: "0.2em"
                }}
              />
            ) : (
              <span key={i}>{word}</span>
            )
          );

        // then return all menu items, with a unique key and icon
        return (
          <React.Fragment key={`${navItem.order}`}>
            {/* Top level is the header text and icon - sometimes clickable */}
            <Menu.Item
              key={`${navItem.order}`}
              as={navItem.url ? NavLink : "div"}
              // exact={navItem.exact}
              className="header"
              to={navItem.url}
              onClick={e => {				
				if (navItem.url === "/powerhours") {
					Analytics.event({
						category: "Power Hours",
						action: "Menu List Item",
					});
				}
                if (navItem.url !== "") {
                  // close the sidemenu
                  this.props.app.closeSideMenu(e);
                }
              }}
            >
              {icon ? (
                <i className="icon">{icon}</i>
              ) : (
                <Icon name={navItem.icon} />
              )}
              {transformedText}
              {navItem.isNewFeature && <span className="flag">new</span>}
            </Menu.Item>

            {/* Now generate all child links */}
            {navItem.links &&
              navItem.links
                .sort((a, b) => a.order - b.order)
                .map(navSubItem => {
                  if (navSubItem.text === "Take the tour again") {
                    return (
                      <Menu.Item
                        key={`${navItem.order}-${navSubItem.order}`}
                        as="a"
                        // exact={navSubItem.exact}
                        onClick={e => {
                          // close the sidemenu
                          this.props.app.closeSideMenu(e);

                          this.props.app.openAppDemo();
                        }}
                      >
                        {navSubItem.text}
                      </Menu.Item>
                    );
                  }

                  return (
                    <Menu.Item
                      key={`${navItem.order}-${navSubItem.order}`}
                      as={NavLink}
                      // exact={navSubItem.exact}
                      to={navSubItem.url}
                      onClick={e => {
                        // close the sidemenu
                        this.props.app.closeSideMenu(e);

                        if (navSubItem.onClick) navSubItem.onClick(e);
                      }}
                    >
                      {navSubItem.text}
                    </Menu.Item>
                  );
                })}
          </React.Fragment>
        );
      });
  };

  componentDidMount() {
    this.setState({
      version_web: Array.from(document.getElementsByTagName("meta"))
        .filter(e => e.name === "ui-version")
        .map(e => e.content)
        .pop()
    });

    axios.post(process.env.REACT_APP_API_BASE_URI + "status").then(resp => {
      this.setState({ version_api: resp.data.APIVersion });
    });

    /*
     * APAYGDEV-117 - sidebar won't close on iPads
     *
     * https://github.com/Semantic-Org/Semantic-UI-React/pull/1833#issuecomment-313713611
     * known issue with iOS and semantic ui, where sidebar won't call onHide correctly
     * unless cursor style is pointer
     */
    if ("ontouchstart" in document.documentElement && Device.isAppleDevice()) {
      document.body.style.cursor = "pointer";
    }
  }

  handleOpenModal = () => {
    this.setState({ modalLoggedOutOpen: true });
  };

  handleCloseModal = () => {
    this.props.app.closeSideMenu();
    this.setState({ modalLoggedOutOpen: false });
  };

  logout = () => {
    this.handleCloseModal();
    this.props.logoutUser();
    this.props.router.navigate("/");
  };

  renderDesktopMenuItem = item => (
    <Menu.Item
      key={item.content}
      // exact={item.exact}
      as={item.to ? NavLink : item.as || "div"}
      to={item.to}
      header={item.header}
      onClick={e => {
        // if the item is not a header OR the item is a route to another page
        // close the sidemenu
        if (!item.header || item.exact) this.props.app.closeSideMenu(e);

        if (item.onClick) item.onClick(e);
      }}
    >
      {item.icon &&
        (typeof item.icon === "string" ? (
          <Icon name={item.icon} />
        ) : (
          <i className="icon">
            <item.icon height="100%" />
          </i>
        ))}
      {item.content}
      {item.children && (
        <Menu.Menu>
          {item.children.map(child => this.renderDesktopMenuItem(child))}
        </Menu.Menu>
      )}
    </Menu.Item>
  );

  renderVersion = () => {
    let { deviceInfo, isApp } = this.props.app;

    if (isApp && deviceInfo) {
      if (deviceInfo && deviceInfo.environment === "PRODUCTION") {
        return (
          <span>
            WEB: {this.state.version_web} API: {this.state.version_api || "..."}{" "}
            APP: {deviceInfo.appVersion}
          </span>
        );
      } else {
        return (
          <span>
            WEB: {this.state.version_web} API: {this.state.version_api || "..."}{" "}
            APP: {deviceInfo.appVersion}
            <br />
            {deviceInfo.environment}
          </span>
        );
      }
    }
    if (process.env.NODE_ENV === "production") {
      return (
        <span>
          WEB: {this.state.version_web} API: {this.state.version_api || "..."}
        </span>
      );
    } else {
      return (
        <span>
          WEB: {this.state.version_web} API: {this.state.version_api || "..."}
          <br />
          {process.env.NODE_ENV}
        </span>
      );
    }
  };

  renderDesktopMenu = () => {
    const { isSideMenuOpen, closeSideMenu } = this.props.app;
    const { isAuthenticated, customerPremise } = this.props.customer;
    const { theme } = this.props.theme;

    return (
      <Sidebar
        style={{ backgroundImage: theme.sidebar.background }}
        as={Menu}
        animation="overlay"
        direction="right"
        inverted
        vertical
        onHide={closeSideMenu}
        visible={isSideMenuOpen}
      >
        <Menu.Item key="chevron" onClick={closeSideMenu}>
          <Icon name="chevron right" size="large" />
          <Image
            centered
            src={logo}
            style={{
              maxHeight: "3rem"
            }}
          />
        </Menu.Item>

        {this.NavListItems()}

        {isAuthenticated &&
          this.renderDesktopMenuItem({
            content: "Log Out",
            as: "a",
            icon: LogOutIcon,
            header: true,
            onClick: e => this.handleOpenModal()
          })}

        <Menu.Item
          key="aurora-logo"
          style={{
            textAlign: "center"
          }}
          header
        >
          <Image
            centered
            src={auroraLogo}
            style={{
              maxWidth: "100px",
              marginBottom: "0.5em"
            }}
          />
          <p onClick={() => window.location.reload(true)}>
            Version {this.renderVersion()}
          </p>
          <small>© 2019 Aurora Energy Pty Ltd ABN 85 082 464 622</small>
        </Menu.Item>
      </Sidebar>
    );
  };

  render() {
    const { isSideMenuOpen } = this.props.app;
    const { theme } = this.props.theme;

    return (
      <React.Fragment>
        <Modal
          name="side-menu/logged-out"
          size="mini"
          open={this.state.modalLoggedOutOpen}
          onClose={this.logout}
        >
          <Modal.Header>You have been logged out</Modal.Header>
          <Modal.Content>
            <Modal.Description>
              You have been successfully logged out.
            </Modal.Description>
          </Modal.Content>
          <Modal.Actions>
            <Button
              style={{ backgroundColor: theme.primary.backgroundColor }}
              secondary
              fluid
              onClick={this.logout}
            >
              OK
            </Button>
          </Modal.Actions>
        </Modal>
        <Sidebar.Pushable
          as={Segment}
          basic
          style={{
            margin: 0,
            overflow: isSideMenuOpen ? "hidden" : undefined
          }}
        >
          {this.renderDesktopMenu()}

          <Sidebar.Pusher dimmed={isSideMenuOpen}>
            {this.props.children}
          </Sidebar.Pusher>
        </Sidebar.Pushable>
      </React.Fragment>
    );
  }
}

export default withRouter(withCustomer(withTheme(withApp(SideMenu))));
