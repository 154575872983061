import React from 'react';
import axios from 'axios';
import {Header, Item, Segment} from 'semantic-ui-react';
import moment from 'moment-timezone';
import { filter } from 'lodash';

import NotificationIcon from '../components/Icons/NotificationIcon';
import EmailIcon from '../components/Icons/EmailIcon';
import SMSIcon from '../components/Icons/SMSIcon';
import InAppIcon from '../components/Icons/InAppIcon';
import OffProductView from '../components/OffProductView/OffProductView'

import Layout from '../layouts/Layout';
import FullWidthLayout from '../layouts/FullWidthLayout';

import {withCustomer} from '../contexts/Customer'
import { withApp } from '../contexts/App'
import { withTheme } from '../contexts/Theme';
import InfiniteList from './../components/InfiniteList';

export class Notifications extends React.Component {

  state = {
    continuous: true,
    notificationsToLoad: 50,
    initialLoading: true,
    isRefreshing: false
  }

  listRef = null

  formatDate = (dateStr) => {
    const date = moment(dateStr).format('D MMM LT');
    return date;
  }

  getChannelIcons = (channels) => {
    let icons = channels.map((channel, i) => {
      switch (channel.Name.toLowerCase()) {
        case 'email':
          return <EmailIcon key={'icon' + i} height={16}/>;
        case 'sms':
          return <SMSIcon key={'icon' + i} height={16}/>;
        default:
          return <InAppIcon key={'icon' + i} height={16}/>;
      }
    });

    return icons;
  }

  getTitle = (type) => {

    switch (type) {
      case 'ResetPassword':
        return 'Reset Password';
      case 'ForgotPassword':
        return 'Forgot Password';
      case 'AdminResentPasswordReset':
        return 'Admin Resent Password Reset';
      case 'FeedbackSent':
        return 'Feedback Sent';
      case 'AccountSettingsUpdate':
        return 'Account Settings Update';
      case 'PaymentSuccessful':
        return 'Payment Successful';
      case 'PaymentUnsuccessful':
        return 'Payment Unsuccessful';
      case 'PaymentDetailsUpdated':
        return 'Payment Details Updated';
      default:
        return type;
    }
  }

  componentDidUpdate = (prevProps) => {
    if (this.props.customer.customerPremise && this.props.customer.customerPremise !== prevProps.customer.customerPremise) {
      this.listRef && this
        .listRef
        .getData(1, true)
        .then(() => this.setState({initialLoading: false}))
        .then(this.updateNotificationCounter);
    }
  }

  updateNotificationCounter = () => {
    const customer = this.props.customer;

    if (customer) 
      customer.updateUnreadNotificationCount();
    }

  getFilterDate = (serviceAgreementStartDate) => {
    if (!serviceAgreementStartDate) return null;

    const dateString = moment(serviceAgreementStartDate)
      .format("YYYY-MM-DD[T00:00:00]");

    return dateString;
  }

  render() {
    const { customerPremise, customer } = this.props.customer;
    const { theme } = this.props.theme;

    if (!customerPremise) 
      return null

      //Case 604, added to check if a customer is meant to experience the off the product view (determined by API if all the SAs are inactive) but then to allow through a person with hub I and CCB A (fresh churn)
      if ((customerPremise && customerPremise.isActive) === false || (customer && customer.productLevel === "OFFPRODUCT")) {
      return (
        <Layout pageTitle="Notifications" layout={FullWidthLayout}>
          <OffProductView icon={(<NotificationIcon height="160px"/>)}/>
        </Layout>
      );
    }

    return (
      <Layout
        pageTitle="Notifications"
        isRefreshing={this.state.isRefreshing}
        onPullDownRefresh={() => {
        this.setState({isRefreshing: true});
        this
          .listRef
          .getData(1, true)
          .then(() => this.setState({isRefreshing: false}))
          .catch(() => this.setState({isRefreshing: false}));
      }}>
        <Header textAlign='center' size='large'>Notifications</Header>

        <InfiniteList
          key={customerPremise.parentAccountID}
          isSideMenuOpen={this.props.app.isSideMenuOpen}
          ref={(c) => this.listRef = c}
          getData={(pageRows, pageNumber) => {
            let filterStr = '';

            if (customerPremise.serviceAgreementStartDate) {
              filterStr = `&notBefore=${this.getFilterDate(customerPremise.serviceAgreementStartDate)}`
            }

            const url = `${process.env.REACT_APP_API_BASE_URI}notifications/${customerPremise.parentAccountID}?$top=${pageRows}&$skip=${(pageNumber - 1) * pageRows}&$orderby=NotificationTime desc${filterStr}`;
          return axios
            .get(url)
            .then(res => {
              return filter(res.data, (obj) => !obj.UserId || obj.UserId === customer.customerID );
            })
            .then(res => {
              this.updateNotificationCounter();
              return res;
            });
        }}
          children={({item, itemIndex}) => (
          <Item className="notification" key={'item' + itemIndex}>
            <Item.Content>
                <Item.Header style={{ backgroundColor: theme.primary.color }}>
                {this.getTitle(item.Type)}
                <div>
                  {this.getChannelIcons(item.NotificationChannels || [])}
                </div>
              </Item.Header>
              <Item.Description>{item.Notification1}</Item.Description>
              <Item.Extra>{this.formatDate(item.NotificationTime)}</Item.Extra>
            </Item.Content>
          </Item>
        )}
          noData={() => {
          if (this.state.initialLoading) 
            return null;
          return (
            <Segment basic textAlign="center">
              <p>No News yet! Notifications about your account and usage will show up here.</p>
              <Segment padded basic>
                <NotificationIcon height="160px"/>
              </Segment>
            </Segment>
          )
        }}/>

      </Layout>
    );
  }
}

export default withApp(withCustomer(withTheme(Notifications)))
