import React from 'react';

import {Segment} from 'semantic-ui-react'
import ColumnLayout from './ColumnLayout';

const columnContainer = {
  mobile: 16,
  tablet: 10,
  computer: 6
}

export default({
  children,
  dimmer,
  ...rest
}) => (
  <Segment padded {...rest} className="main-wrapper layout-default">
    {dimmer}
    <ColumnLayout column={columnContainer}>
      {children}
    </ColumnLayout>
  </Segment>

)