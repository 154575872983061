import React from 'react';
import moment from 'moment';
import { Form } from 'formsy-semantic-ui-react';
import {
  Card,
  Header,
  Button,
  Statistic
} from "semantic-ui-react";
import InfoIcon from '../../../components/Icons/InfoIcon';
import ValidatedCheckbox from '../../../components/Forms/ValidatedCheckbox';
import ExternalLink from '../../../components/ExternalLink';
import { FORM_STATE } from '../../../utils/FormState';


export default function ExtensionForm(props) {
  const {
    theme,
    openModal,
    onFormValid,
    onFormInvalid,
    onSubmitExtension,
    dateDue,
    amountOutstanding,
    canSubmit,
    formState
  } = props;


  return (
    <React.Fragment>

      <Card.Group style={{ marginTop: 0 }}>
        <Card style={{ width: '100%' }}>
          <Card.Header textAlign="center">
            Payment extension
            <InfoIcon style={{ float: 'right' }} onClick={() => openModal('modalExtensionDetails')} />
          </Card.Header>

          <Card.Content
            style={{ backgroundColor: theme && theme.secondary.backgroundColor, color: theme && theme.primary.color }}
            textAlign="center">

            <p className='paymentForeword'>
              Good news! We can extend your payment due date by 10 business days.
            </p>

            <div className="innerCard">
              <p className="text" style={{ backgroundColor: theme && theme.primary.color, margin: 0 }}>
                New payment due date
              </p>
              <Statistic color='blue'>
                <Statistic.Value>
                  {moment(dateDue).format("DD/MM/YY")}
                </Statistic.Value>
              </Statistic>
            </div>

            <div className="innerCard">
              <p className="text" style={{ backgroundColor: theme && theme.primary.color, margin: 0 }}>
                Outstanding amount
              </p>
              <Statistic color='blue'>
                <Statistic.Value>
                  ${amountOutstanding}
                </Statistic.Value>
              </Statistic>
            </div>

            <Form
              noValidate
              onValidSubmit={onSubmitExtension}
              onValid={onFormValid}
              onInvalid={onFormInvalid}
              className="paymentExtensionForm">

              <div className="checkboxWithLinks">
                <ValidatedCheckbox
                  name="termsconditionscheckbox"
                  required
                />
                <span className="link">I agree to the <ExternalLink eventLabel="open modal" onClick={() => openModal('modalTermsAndConditions')}>terms & conditions and privacy statement.</ExternalLink></span>
              </div>

              <Button
                style={{ backgroundColor: theme && theme.primary.backgroundColor }}
                secondary
                type='submit'
                disabled={!canSubmit}
                loading={formState === FORM_STATE.LOADING}
              >Apply</Button>
            </Form>

          </Card.Content>
        </Card>
      </Card.Group>

      <div className="payment-help">
        <Header size='large'>Need longer? We’re here to help</Header>
        <p style={{ marginBottom: '1.5em' }}>If you need longer to pay, please submit a request through the <b>contact us</b> page or call us on <b>1300 132 030</b>.</p>
      </div>

    </React.Fragment>
  );
}
