import React from 'react';
import { TARIFFS } from "./Tariffs";
import Urls from './Urls';
import Log from './Log';

//Case 553 updated the modal's text info for usageSummary to have different content for residential vs business customers
const MODALS = {
  usageSummaryResiKwH: {
    Template: "list",
    Title: "Energy Usage",
    DotPointSyle: "square",
    Lead: "Your Energy Usage summary for the selected period (Day, Week, Month, etc) shows:",
    Content: [
      "Total amount of energy you have used in kilowatt hours (kWh) based on the energy plan you have (Peak and Off-Peak pricing, or a Flat Rate price)",
      "Total cost, including fixed charges and any concessions or discounts you are entitled to."
    ], 
    PostListContent:"Total energy usage and costs for the selected period are calculated on Australian Eastern Standard Time (AEST)."
  },
  usageSummaryResiDollars: {
    Template: "list",
    Title: "Amount in Dollars",
    DotPointSyle: "square",
    Lead: "Your Amount in Dollars summary for the selected period (Day, Week, Month, etc) shows:",
    Content: [
      "Cost of the energy you have used based on the energy plan you have (Peak and Off-Peak pricing, or a Flat Rate price)",
      "Total of fixed charges and any concessions or discounts you are entitled to in the ‘Other’ line, which can be expanded to show individual items",
      "The total energy you have used in kilowatt hours (kWh)."
    ], 
    PostListContent:"Total energy usage and costs for the selected period are calculated on Australian Eastern Standard Time (AEST)."
  },  
  usageSummarySMBKwH: {
    Template: "list",
    Title: "Energy Usage",
    DotPointSyle: "square",
    Lead: "Your Energy Usage summary for the selected period (Day, Week, Month, etc) shows:",
    Content: [
      "Total amount of energy you have used in kilowatt hours (kWh) based on the energy plan you have (Peak, Shoulder and Off-Peak pricing)",
      "Total cost, including fixed charges for your account."
    ], 
    PostListContent:"Total energy usage and costs for the selected period are calculated on Australian Eastern Standard Time (AEST)."
  },
  usageSummarySMBDollars: {
    Template: "list",
    Title: "Amount in Dollars",
    DotPointSyle: "square",
    Lead: "Your Amount in Dollars summary for the selected period (Day, Week, Month, etc) shows:",
    Content: [
      "Cost of the energy you have used based on the energy plan you have (Peak, Shoulder and Off-Peak pricing)",
      "Total of fixed charges for your account in the ‘Other’ line, which can be expanded to show individual items",
      "The total energy you have used in kilowatt hours (kWh)."
    ], 
    PostListContent:"Total energy usage and costs for the selected period are calculated on Australian Eastern Standard Time (AEST)."
  },
  usageSummaryT22KwH: {
    Template: "list",
    Title: "Energy Usage",
    DotPointSyle: "square",
    Lead: "Your Energy Usage summary for the selected period (Day, Week, Month, etc) shows:",
    Content: [
      "Total amount of energy you have used in kilowatt hours (kWh) based on the energy plan you have",
    ], 
    PostListContent:"Total energy usage for the selected period are calculated on Australian Eastern Standard Time (AEST)."
  },
  usageSolarInfo: {
    Template: "image",
    Title: "How does solar energy work?",
    Content: {
      Desktop: '/images/HowSolarWorks_Desktop.png',
      Mobile: '/images/HowSolarWorks_Mobile.png'
    },
    Link: Urls.SOLAR_LEARN_MORE_LINK,
  },
  keyInsights: {
    Template: "paragraph",
    Title: "Key Insights",
    Content: [
      "We compare the last 7 days of energy that you have purchased from Aurora Energy with the previous 7 days, and let you know if you have used more or less energy in this period.",
    ],
    HideLink: true
  },
  keySolarInsights: {
    Template: "paragraph",
    Title: "Key Insights",
    Content: [
      "We compare the last 7 days of solar that you have sold to Aurora Energy with the previous 7 days and let you know if you have sold more or less energy in this period."
    ],
    HideLink: true
  },
  energyTips: {
    Template: "paragraph",
    Title: "Energy Tips",
    Content: [
      "Friendly and useful energy tips to help you reduce your energy consumption. Energy tips are tailored to the current season."
    ],
    HideLink: true
  },
  yourBillPrediction: {
    Template: "list",
    Title: "Predicted charges for your next bill",
    Lead: "Please note this is an estimated amount only. This estimation is based on:",
    Content: [
      "how much we estimate you have used until now",
      "the day you login to view your estimate",
      "the day your next bill is due."
    ],
	PostListContent: "This amount does not include any payments or rebates applied since your last bill."
	
  },
  yourBillPredictionT22: {
    Template: "list",
    Title: "Predicted charges for your next bill",
    Lead: "Please note this is an estimated amount only. This estimation is based on:",
    Content: [
      "how much we estimate you have used until now",
      "the day you login to view your estimate",
      "the day your next bill is due."
    ],
	PostListContent: [
		"This amount does not include any payments or rebates applied since your last bill.",
		"Do you have Tariff 22 (General Business)? Your predicted bill amount will be slightly higher than the your actual bill. This is due to the tiered structure of the T22 energy plan."
	]
  },
  currentBillingPeriod: {
    Template: "paragraph",
    Title: "Current billing period",
    Content: [
      "This shows the dates of your current bill cycle and the number of days remaining in your bill cycle. You’ll receive your bill a few days after your bill cycle ends.",
      "It also shows an estimated average spend per day for your energy usage until now."
    ]
  }
}

/**
 * Modified with case 553 to change the UsageSummaryView text shown for the respective tariff type (i.e. TOU or not)
 * Presents the list with dot points styles stated in the modal's DotPointStyle else use default disc (filled in circle) 
 * Modal.Lead will show prior to the dot point list and Modal.PostlistContent will show after the list
 * @param {*} modal The modal type to display info of
 * @param {*} unitOfMeasurement 
 */
const listTemplate = (modal, unitOfMeasurement) => (
  <React.Fragment>
    {modal.Lead && <p>{modal.Lead}</p>}
    <ul 
      style={{ listStyleType: modal.DotPointSyle != null ? modal.DotPointSyle : "disc"
    }}>
      {modal.Content ?
          modal.Content.map((value, index) => (
            <li key={index}>{value}</li>
          ))
          :
          modal[unitOfMeasurement].map((value, index) => (
            <li key={index}>{value}</li>
          ))
      }
    </ul>
    {modal.PostListContent && (
		modal.PostListContent instanceof Array ? (
			modal.PostListContent.map((value, index) => (
			  <p key={index}>{value}</p>
			))
		) : (
			<p>{modal.PostListContent}</p>
		)
	)}
    <small>{modal.Disclaimer}</small>
  </React.Fragment>
)

const paragraphTemplate = (modal, unitOfMeasurement) => (
  <React.Fragment>
    {modal.Content ?
      modal.Content.map((value, index) => (
        <p key={index}>{value}</p>
      ))
      :
      modal[unitOfMeasurement].map((value, index) => (
        <p key={index}>{value}</p>
      ))
    }
  </React.Fragment>
)

const imageTemplate = modal => (
  <React.Fragment>
    {window.innerWidth > 992 ?
      <p><img src={process.env.PUBLIC_URL + modal.Content.Desktop} style={{ "maxWidth": "100%" }} /></p>
      :
      <p><img src={process.env.PUBLIC_URL + modal.Content.Mobile} style={{ "maxWidth": "100%" }} /></p>
    }
  </React.Fragment>
)

const modalSearch = (name) => {
  return MODALS[name] || null
}

// when adding new templates, ensure you update this helper method to include it
const templateSearch = (name) => {
  var templateName = name + "Template";

  var allTemplates = {
    listTemplate,
    paragraphTemplate,
    imageTemplate
  };

  return allTemplates[templateName];
}

// Modal information is in two places for the Usage screen
// Utils/Modals.js == more general / re-useable modals. E.g. Usage Summary modal, Energy Saving Tips modal
// Utils/Tariffs.js == modals that change, dependant on the specific tariff being displayed E.g. Peak Modal, Off-Peak modal, etc
const getModalForId = (modalId, unitOfMeasurement) => {

  // see if there is a generic/shared modal
  let modalData = modalSearch(modalId);
  let modalTitle = "", modalLink = "";

  // if no general modal, it must be a specific tariff modal
  if (!modalData) {
    // Utils/Tariffs.js
    var tariffData = TARIFFS[modalId];

    if (!tariffData) {
      Log.log('Could not find/open modal with id: ', modalId);
      return null;
    }

    modalData = tariffData.Modal;
    modalTitle = tariffData.Modal.Title || tariffData.DisplayName;
    modalLink = tariffData.Modal.Link;
  }

  const template = templateSearch(modalData.Template);
  const modal = template(modalData, unitOfMeasurement);

  return {
    description: modal,
    title: modalTitle || modalData.Title,
    lead: modal.Lead,
    learnMoreBtn: !modalData.HideLink,
    link: modalLink || modalData.Link,
  }
}

export {
  MODALS,
  modalSearch,
  templateSearch,
  listTemplate,
  paragraphTemplate,
  getModalForId
}
