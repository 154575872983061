import React from "react";
import Icons from "../components/Icons";

const iconData = props => {
  return {
    UsageIcon: <Icons.UsageIcon {...props} />,
    SunIcon: <Icons.Sun {...props} />,
    SMSIcon: <Icons.SMSIcon {...props} />,
    PredictIcon: <Icons.PredictIcon {...props} />,
    plugIcon: <Icons.plugIcon {...props} />,
    PayNowIcon: <Icons.PayNowIcon {...props} />,
    NotificationIcon: <Icons.NotificationIcon {...props} />,
    LogOutIcon: <Icons.LogOutIcon {...props} />,
    InfoSolidFAIcon: <Icons.InfoSolidFA {...props} />,
    InfoIcon: <Icons.InfoIcon {...props} />,
    InAppIcon: <Icons.InAppIcon {...props} />,
    HomeIcon: <Icons.HomeIcon {...props} />,
    HelpIcon: <Icons.HelpIcon {...props} />,
    GlobeIcon: <Icons.globeIcon {...props} />,
    EmailIcon: <Icons.EmailIcon {...props} />,
    BusinessIcon: <Icons.BusinessIcon {...props} />,
    BillsAndPaymentsIcon: <Icons.BillsAndPaymentsIcon {...props} />,
    AccountSettingsIcon: <Icons.AccountSettingsIcon {...props} />,
    UsagePatternIcon: <Icons.UsagePatternIcon {...props} />,
    PowerHoursIcon: <Icons.PowerHoursIcon {...props} />
  };
};

function iconSearch(val, props) {
  return iconData(props)[val];
}

export { iconData, iconSearch };
