import React from 'react';
import {Button, Header} from 'semantic-ui-react';
import {Form} from 'formsy-semantic-ui-react';
import axios from 'axios';

import ValidatedDropdown from '../components/Forms/ValidatedDropdown';
import {FORM_STATE} from '../utils/FormState';

import Layout from '../layouts/Layout';
import { withCustomer } from '../contexts/Customer';
import { withTheme } from '../contexts/Theme';
import { withRouter } from '../utils/useRouter';
import ExternalLink from './../components/ExternalLink';
import Modal from './../components/Modal';
import ValidatedTextarea from '../components/Forms/ValidatedTextarea';
import Analytics from './../utils/Analytics';
import { Buffer } from "buffer";


export class Contact extends React.Component {

  state = {
    modalSubmitOpen: false,
    modalSubmitTitle: '',
    modalSubmitDescription: '',
    issues: [
      {
        text: 'Select',
        value: ''
      }, {
        text: 'Customer Service',
        value: 'Customer Service'
      }, {
        text: 'Notifications',
        value: 'Notifications'
      }, {
        text: 'Payments',
        value: 'payments'
      }, {
        text: 'Usage',
        value: 'Usage'
      }, {
        text: 'Technical',
        value: 'Technical'
      }, {
        text: 'General/Other',
        value: 'General/Other',
        param: 'general'
      }, {
        text: 'Payments Help',
        value: 'payments-help',
        param: 'payments-help'
      }
    ]
  }

  componentDidMount() {
    this.checkPremisePopulated()
  }

  componentDidUpdate(prevProps) {
    this.checkPremisePopulated()
  }

  checkPremisePopulated() {
    const {
      customer,
      customer: {
        customerPremise,
        allPremises
      },
      router
    } = this.props;

    if (!customerPremise) {
      var search = new URLSearchParams(router.location.search);
      var accountId = search.get("account");
      var accountFound = accountId && allPremises.some(premise => premise.parentAccountID == accountId);
      if (accountFound) {
        customer.setCustomerPremise(accountId, allPremises)
      }
    }
  }

  // return new payload with customer details added
  formatPayload = initialPayload => {
    let payload = {
      ...initialPayload
    };
    const {customer, customerPremise} = this.props.customer;
  
    if (payload.feedbackCategory === '') {
      payload.feedbackCategory = undefined;
    }

    payload.email = customer.emailAddress;
    payload.name = `${customer.firstName} ${customer.lastName}`
    payload.personId = customer.customerID;
    payload.serviceAgreementId = customerPremise.serviceAgreementID;
    payload.accountId = customerPremise.parentAccountID;
    payload.message = Buffer.from(payload.message).toString('base64');

    return payload;
  }

  submit = (payload, resetForm) => {
    const endpoint = process.env.REACT_APP_API_BASE_URI + 'feedback';

    payload = this.formatPayload(payload);

    this.setState({formState: FORM_STATE.LOADING});

    //APAYGDEV-450 - Update contact numbers
    axios
      .post(endpoint, payload)
      .then((response) => {
        this.setState({
          formState: FORM_STATE.DONE_LOADING,
          modalSubmitOpen: true,
          modalSubmitTitle: 'Thank you for your enquiry',
          modalSubmitDescription: "We will get back to your message in the next 10 business days." +
            " If your message is urgent, please call the aurora+ Helpline on:\nResidential: 1300 132 030\nBusiness: 1300 132 045"
        });
        resetForm()
        Analytics.event({category: 'Contact', action: 'Online form submitted', value: payload.feedbackCategory})

      })
      .catch((error) => {
          this.setState({
              formState: FORM_STATE.DEFAULT,
              modalSubmitOpen: true,
              modalSubmitTitle: 'Oh No! Something went wrong',
              modalSubmitDescription: 'Please try again.'
          });
      });
  }

  closeModal = () => {
    this.setState({modalSubmitOpen: false});
  }

  onFormValid = () => {
    this.setState({canSubmit: true});
  }

  onFormInvalid = () => {
    this.setState({canSubmit: false});
  }

  render() {
    const { router } = this.props;
    const { theme } = this.props.theme;
    const { pageConfig } = this.props.customer;
    const isBusinessProduct = pageConfig && pageConfig.productName === 'business';

    var defaultCategory = null;
    if (router && router.params) {
      var category = router.params.query;
      this.state.issues.forEach(issue => {
        if (issue.param && (issue.param == category)) category = issue.value;
      })
      defaultCategory = category;
    }

    return (
      <div style={{ marginTop: '15px' }}>
        <Layout accountPicker={false} requiresCustomer={false} pageTitle="Contact Us">
          <Modal
            name={`contact-us/${this.state.modalSubmitOpen && this.state.formState === FORM_STATE.DONE_LOADING
            ? 'success'
            : 'failure'}`}
            size="small"
            open={this.state.modalSubmitOpen}
            onClose={this.closeModal}>
            <Modal.Header>{this.state.modalSubmitTitle}</Modal.Header>
            <Modal.Content>
              <Modal.Description>
                <p>{this.state.modalSubmitDescription.split("\n").map((line, index) => (<span key={index}>{line}<br/></span>))}</p>
              </Modal.Description>
            </Modal.Content>
            <Modal.Actions>
              <Button
                style={{ backgroundColor: theme.primary.backgroundColor }}
                fluid
                secondary
                onClick={this.closeModal}>OK</Button>
            </Modal.Actions>
          </Modal>

          <Header textAlign="center" size="large">
            Tell Us What You Think
          </Header>

          <p>Do you need help or have some feedback? Let us know what you found useful
            while using aurora+.</p>
                
          <Form
            noValidate
            onValidSubmit={this.submit}
            onValid={this.onFormValid}
            onInvalid={this.onFormInvalid}>
            <ValidatedDropdown
              hideErrorMessage
              options={this.state.issues}
              defaultValue={defaultCategory || ''}
              label="Feedback / Issue"
              name="feedbackCategory"
              placeholder="Select"/>
            <ValidatedTextarea required hideErrorMessage name="message" label="Message"/>
            <Form.Field>
              <Button
                fluid
                secondary
                size="small"
                style={{ backgroundColor: theme.primary.backgroundColor }}
                disabled={this.state.formState === FORM_STATE.LOADING}
                loading={this.state.formState === FORM_STATE.LOADING}>Send</Button>
            </Form.Field>
          </Form>

          <h4 style={{ fontWeight: 400 }}>Useful Contacts</h4>

          <p>Email:<br/>
            <ExternalLink
              style={{ wordBreak: 'break-word' }}
              className="break-word"
              href={isBusinessProduct ? 'mailto:business@auroraenergy.com.au' : 'mailto:customer.service@auroraenergy.com.au'}>
              {isBusinessProduct ? 'business@auroraenergy.com.au' : 'customer.service@auroraenergy.com.au'}
          </ExternalLink>
          </p>

          <p>aurora+ Helplines:<br />
            <ExternalLink href='tel:1300132030'>
              Residential: 1300 132 030
            </ExternalLink><br />
            <ExternalLink href='tel:1300132045'>
              Business: 1300 132 045
              </ExternalLink>
          </p>

          <p>TasNetworks Electrical Faults and Outages:<br/>
            <ExternalLink href="tel:132004">132 004</ExternalLink>
          </p>

          <p>Aurora Website:<br/>
            <ExternalLink href="https://www.auroraenergy.com.au/">www.auroraenergy.com.au</ExternalLink>
          </p>

        </Layout>
      </div>
    );
  }
}

export default withRouter(withCustomer(withTheme(Contact)));
