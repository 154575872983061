import { Form } from "formsy-semantic-ui-react";
import { Button, Card, Modal } from "semantic-ui-react";
import ValidatedDropdown from "../Forms/ValidatedDropdown";
import { useState } from "react";
import { FORM_STATE } from "../../utils/FormState";
import PowerHoursIcon from "../Icons/PowerHoursIcon";
import InfoIcon from '../Icons/InfoIcon';
import './PowerHoursCard.css';
import { useEffect } from "react";
import moment from "moment-timezone";
import { withApp } from "../../contexts/App";
import { useNavigate } from "react-router-dom";


export default withApp(({ powerHours, premises, onSubmit, condensed, upcomingActiveOnly, app, ...cardProps }) => {

	const navigate = useNavigate();

	const [timeRemaining, setTimeRemaining] = useState("");
	const [timerTarget, setTimertarget] = useState(null);
	const [timerTimeout, setTimerTimeout] = useState(null);


	useEffect(() => {		
		if (!powerHours.isValid) return;

		if (timerTimeout) return;


		let hasFinished = powerHours.hasFinished();
		let isInProgress = powerHours.isInProgress();

		let waitTime = null;

		let now = moment.now();

	
		if (!hasFinished) {						

			if (isInProgress) {
				setTimeRemaining(powerHours.getTimeRemaining());
				waitTime = 1000;
			} else if (!powerHours.isExpired) {
				if (powerHours.acceptedTimeSlot) {
					waitTime = powerHours.acceptedTimeSlot.startDateTime.valueOf() - now + 50;
				} else {
					let waitTimes = powerHours.availableTimeSlots.map(timeSlot => {
						return timeSlot.expiry.valueOf() - now + 50
					}).filter(waitTime => waitTime > 0);
					waitTimes.sort((a, b) => a - b);
					if (waitTimes.length > 0) {
						waitTime = waitTimes[0];
					} else {
						// The event has just expired. Should refresh entire page
						navigate(0);
					}
				}

			}
		}

		
		if (waitTime) {
			setTimertarget(now + waitTime);
		}


	}, [powerHours, timerTimeout]);


	useEffect(() => {
		// Set a timer, then clear timerTimeout afterwards so the main useEffect will run again to set a new timerTarget
		let waitTime = timerTarget - moment.now();

		clearTimeout(timerTimeout);
		if (timerTarget > 0) {
			setTimerTimeout(setTimeout(() => {
				setTimerTimeout(null);
			}, waitTime));
		}

	}, [timerTarget]);


	useEffect(() => {
		if (timerTimeout) {
			clearTimeout(timerTimeout);
			setTimerTimeout(null);
		}
	}, []);


	function renderContent() {
		if (!powerHours.isValid) return null;

		let hasFinished = powerHours.hasFinished();
		let isInProgress = powerHours.isInProgress();

		if (powerHours.isInvitation) {
			if (upcomingActiveOnly) return null;
			if (powerHours.invitationHasExpired) {
				return <ExpiredContent powerHours={powerHours} />
			} else {
				return <InvitationContent powerHours={powerHours} premises={premises} onSubmit={onSubmit} />
			}
		} else if (powerHours.isAccepted) { 
			let premise = premises.find(p => p.serviceAgreementID === powerHours.accountId) || premises[0];
			if (!premise) return null;
			if (hasFinished) {
				if (upcomingActiveOnly) return null;
				return <HistoricContent powerHours={powerHours} premise={premise} />
			} else if (isInProgress) {
				return <InProgressContent powerHours={powerHours} premise={premise} timeRemaining={timeRemaining} condensed={condensed} />
			} else {
				return <ConfirmedContent powerHours={powerHours} premise={premise} condensed={condensed}/>
			}
		}

	}

	let content = renderContent();

	return (content ? 
		<Card
			key={powerHours.id + powerHours.name}
			className={"power-hours-card" + (condensed ? " condensed" : "")}
			{...cardProps}
		>
			<Card.Header textAlign="center">
				{powerHours.name}
			</Card.Header>

			{ content }		
		</Card>
	: null);
});


function InfoPanel({ powerHours, address, ...props }) {
	return <Panel className="info-panel" {...props}>
		<PanelValue className="address">{address}</PanelValue>

		{ powerHours.isMultiDay() && powerHours.hasDuration() ?
			<PanelValue>{powerHours.renderDuration()}</PanelValue>
		:
			<>
				<PanelValue>{powerHours.getFormattedStartDate()}</PanelValue>
				<PanelValue>{powerHours.renderStartTime()} - {powerHours.renderEndTime()}</PanelValue>
			</>
		}
	</Panel>
}

function HistoricContent({ powerHours, premise }) {

	const [showingPendingModal, setShowingPendingModal] = useState(false);
	const [showingActualsModal, setShowingActualsModal] = useState(false);
	const [showingSubstituteModal, setShowingSubstituteModal] = useState(false);
	const [showingNoUsageModal, setShowingNoUsageModal] = useState(false);

	let address = premise ? premise.formattedAddress : null;

	let showUsage = (powerHours.usage > 0) && !powerHours.hasSubstitute;

	function closeModal() {
		setShowingPendingModal(false);
		setShowingActualsModal(false);
		setShowingSubstituteModal(false);
		setShowingNoUsageModal(false);
	}	

	return (<>

		<Modal
			name="powerhours/pending"
			size="tiny"
			open={showingPendingModal}
			onClose={closeModal}>
			<Modal.Header>Total Save</Modal.Header>
			<Modal.Content>
				<Modal.Description style={{ color: '#4a4a4a', textAlign: "left" }}>
					As soon as we receive your usage data, we will calculate your total savings for the Power Hours period and display it here. This will not appear immediately in your aurora+ balance, so look for it as a Power Hours credit on your bill statement.
				</Modal.Description>
			</Modal.Content>
			<Modal.Actions>
				<Button
					color="orange"
					secondary
					fluid
					onClick={closeModal}
				>OK</Button>
			</Modal.Actions>
        </Modal>

		<Modal
			name="powerhours/actuals"
			size="tiny"
			open={showingActualsModal}
			onClose={closeModal}>
			<Modal.Header>Total Save</Modal.Header>
			<Modal.Content>
				<Modal.Description style={{ color: '#4a4a4a', textAlign: "left" }}>
					Your Total Savings is calculated on your usage during the Power Hours time slot. 
				</Modal.Description>
			</Modal.Content>
			<Modal.Actions>
				<Button
					color="orange"
					secondary
					fluid
					onClick={closeModal}
				>OK</Button>
			</Modal.Actions>
        </Modal>

		<Modal
			name="powerhours/substitute"
			size="tiny"
			open={showingSubstituteModal}
			onClose={closeModal}>
			<Modal.Header>Total Save</Modal.Header>
			<Modal.Content>
				<Modal.Description style={{ color: '#4a4a4a', textAlign: "left" }}>
					We couldn’t get a confirmed meter read within 30 days of this Power Hours time slot, so we have credited savings based on the maximum usage for a typical residential household.
				</Modal.Description>
			</Modal.Content>
			<Modal.Actions>
				<Button
					color="orange"
					secondary
					fluid
					onClick={closeModal}
				>OK</Button>
			</Modal.Actions>
        </Modal>

		<Modal
			name="powerhours/no-usage"
			size="tiny"
			open={showingNoUsageModal}
			onClose={closeModal}>
			<Modal.Header>Total Save</Modal.Header>
			<Modal.Content>
				<Modal.Description style={{ color: '#4a4a4a', textAlign: "left" }}>
					There was no recorded usage for this time slot. This can happen if:
					<ul>
						<li>you are a solar customer and were using all of your own generated power</li>
						<li>there was a power outage, or</li>
						<li>your meter is faulty.</li>
					</ul>
				</Modal.Description>
			</Modal.Content>
			<Modal.Actions>
				<Button
					color="orange"
					secondary
					fluid
					onClick={closeModal}
				>OK</Button>
			</Modal.Actions>
        </Modal>


		<Card.Content className="historic-content">
			<Panels>
				<InfoPanel borderBottom powerHours={powerHours} address={address} />
				<Panel left={showUsage}>
					<PanelLabel>
						{ !!(!powerHours.hasSubstitute && powerHours.cost) &&
							<InfoIcon
								height="24px"
								color='#002963'
								style={{
									position: 'absolute',
									right: '10px',
									top: '11px',
									fill: '#002963',
								}}
								onClick={() => { setShowingActualsModal(true) }}
							/>
						}	
						{ !!(!powerHours.cost && (powerHours.cost !== 0)) &&
							<InfoIcon
								height="24px"
								color='#002963'
								style={{
									position: 'absolute',
									right: '10px',
									top: '11px',
									fill: '#002963',
								}}
								onClick={() => { setShowingPendingModal(true) }}
							/>
						}	
						Savings
					</PanelLabel>

					{
						powerHours.isFlagged ? (
							<PanelValue className="pending">Pending...</PanelValue>
						) : powerHours.cost ? (
							<>
								<PanelValue huge>
									{powerHours.renderCost()}
								</PanelValue>
								{powerHours.hasSubstitute &&
									<PanelValue small>
										Estimated data
										<br />
										<a style={{ textDecoration: 'underline', lineHeight: 2 }} onClick={() => { setShowingSubstituteModal(true); }}>
											Find out why
										</a>
									</PanelValue>
								}
							</>
						) : powerHours.cost === 0 ? (
							<>
								<PanelValue small>
									You had no recorded usage for this time slot
									<br />
									<a style={{ textDecoration: 'underline', lineHeight: 2 }} onClick={() => { setShowingNoUsageModal(true); }}>
										Find out why
									</a>
								</PanelValue>
							</>
						) : (
							<PanelValue className="pending">Pending...</PanelValue>
						)
					}
				</Panel>
				{ showUsage &&
					<Panel right>
						<PanelLabel>Usage</PanelLabel>
						<PanelValue huge>{powerHours.usage.toLocaleString('en-AU', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</PanelValue>
						<PanelValue small>kWh</PanelValue>
					</Panel>
				}
				<Panel borderTop style={{height: '20px', padding: 0}}></Panel>
			</Panels>

		</Card.Content>
	</>);
}



function ConfirmedContent({ powerHours, premise, condensed }) {

	let address = premise ? premise.formattedAddress : null;


	if (condensed) {
		return (

			<Card.Content className="confirmed-content">
				<Panels>
					<Panel left>
						<PanelLabel>Time Slot</PanelLabel>
						<PanelValue large>Confirmed</PanelValue>
					</Panel>
					<Panel right>
						{ powerHours.isMultiDay() && powerHours.hasDuration() ? <>
							<PanelLabel>{powerHours.getCondensedStartDate()}</PanelLabel>
							<PanelValue large>{powerHours.renderDuration()}</PanelValue>
						</> : <>
							<PanelLabel>{powerHours.getCondensedStartDate()}</PanelLabel>
							<PanelValue large>{powerHours.renderStartTime()}<span style={{padding: '0 0.1em'}}>–</span>{powerHours.renderEndTime()}</PanelValue>
						</> }
					</Panel>
					<Panel borderTop condensed>
						<PanelValue small>{address}</PanelValue>
					</Panel>
				</Panels>

			</Card.Content>
		);

	} else {

		return (
			<Card.Content className="confirmed-content">
				<Panels>
					<Panel borderBottom>
						<PanelPowerIcon />
						<PanelLabel>Time Slot</PanelLabel>
						<PanelValue huge>Confirmed</PanelValue>
					</Panel>
					<InfoPanel powerHours={powerHours} address={address} />
				</Panels>

			</Card.Content>
		);
	}

}



function InProgressContent({ powerHours, premise, timeRemaining, condensed }) {

	let address = premise ? premise.formattedAddress : null;

	if (condensed) {
		return (

			<Card.Content className="in-progress-content">
				<Panels>
					<Panel left>
						<PanelLabel>Time remaining</PanelLabel>
						<PanelValue large>{timeRemaining}</PanelValue>
					</Panel>
					<Panel right>
					{ powerHours.isMultiDay() && powerHours.hasDuration() ? <>
							<PanelLabel>{powerHours.getCondensedStartDate()}</PanelLabel>
							<PanelValue large>{powerHours.renderDuration()}</PanelValue>
						</> : <>
							<PanelLabel>{powerHours.getCondensedStartDate()}</PanelLabel>
							<PanelValue large>{powerHours.renderStartTime()}<span style={{padding: '0 0.1em'}}>–</span>{powerHours.renderEndTime()}</PanelValue>
						</> }
					</Panel>
					<Panel borderTop condensed>
						<PanelValue small>{address}</PanelValue>
					</Panel>
				</Panels>

			</Card.Content>
		);

	} else {

		return (
			<Card.Content className="in-progress-content">
				<Panels>
					<Panel borderBottom>
						<PanelPowerIcon />
						<PanelLabel>Time Remaining</PanelLabel>
						<PanelValue huge>{timeRemaining}</PanelValue>
					</Panel>
					<InfoPanel powerHours={powerHours} address={address} />
				</Panels>
			</Card.Content>
		);
	}

}



function ExpiredContent({ powerHours }) {
	return (
		<Card.Content className="expired-content">
			<PanelValue>
				<p>This offer expired on {powerHours.getFormattedExpiry()}.</p>
				<p>Keep your eye out for the next Power Hours event and lock your time slot in early.</p>
			</PanelValue>				
		</Card.Content>
	);
}



function InvitationContent({ powerHours, premises, onSubmit }) {

	const defaultTimeslot = (powerHours.availableTimeSlots.length === 1) ? powerHours.availableTimeSlots[0] : null;

	const [canSubmit, setCanSubmit] = useState(false);
	const [formState, setFormState] = useState(FORM_STATE.DEFAULT);
	const [selectedTimeSlotId, setSelectedTimeSlotId] = useState(null);

	function onFormValid() {
		setCanSubmit(true);
	}

	function onFormInvalid() {
		setCanSubmit(false);
	}

	function onValidSubmit(payload) {
		// If only one premises, hide the dropdown and add it to the payload here
		if (premises.length === 1) payload.property = premises[0].parentAccountID;
		
		payload.powerHours = powerHours;
		payload.selectedTimeslot = powerHours.availableTimeSlots.find(ts => ts.id === payload.timeslot);
		payload.premises = premises.find(p => p.parentAccountID === payload.property);

		if (onSubmit) onSubmit(payload);
	}


	let now = new moment();


	// Control the value so that if time passes and the timeslot is removed, the
	// dropdown will clear properly
	let timeSlotValue = selectedTimeSlotId || ''
	let validTimeSlots = powerHours.availableTimeSlots.filter(timeslot => {
		if (timeslot.expiry > now) {
			return true;
		} else {
			if (timeslot.id === timeSlotValue) {
				timeSlotValue = '';
			}
			return false;
		}
	});

	return (
		<Card.Content className="invitation-content">

			<Form
				noValidate
				onValidSubmit={onValidSubmit}
				onValid={onFormValid}
				onInvalid={onFormInvalid}
			>

				{ (premises.length === 1) ?
					<div className="single-premise">{premises[0].formattedAddress}</div>
				:
					<ValidatedDropdown
						selection
						required
						options={[{
							'value': '',
							'text': 'Select property'
						}].concat(premises.map(premise => {
							return {
								value: premise.parentAccountID,
								text: premise.formattedAddress,
							};
						}))}
						name="property"
						placeholder="Select property"
					/>
				}

				<ValidatedDropdown
					selection
					required
					options={[{
						'value': '',
						'text': 'Scroll to select time slot'
					}].concat(validTimeSlots.map(timeslot => {
						return {
							value: timeslot.id,
							text: timeslot.getLabel(),
						};
					}))}
					name="timeslot"
					placeholder="Select time slot"
					defaultValue={defaultTimeslot?.id}
					value={timeSlotValue}
					onChange={(event, { value }) => {
						setSelectedTimeSlotId(value);
					}}
				/>

				<Button
					type="submit"
					fluid
					secondary
					size="large"
					style={{  fontWeight: '400' }}
					disabled={!canSubmit || !onSubmit}          
					loading={formState === FORM_STATE.LOADING}
				>Lock in time slot</Button>
			</Form>

		</Card.Content>
	);
}



export const PowerHoursSummary = ({ cost, usage, hours}) => {
	console.log(cost, usage, hours);

	const [showingTotalsModal, setShowingTotalsModal] = useState(false);
	
	function closeModal() {
		setShowingTotalsModal(false);
	}

	return <>

		<Modal
			name="powerhours/summary/totals"
			size="tiny"
			open={showingTotalsModal}
			onClose={closeModal}>
			<Modal.Header>Power Hours Summary</Modal.Header>
			<Modal.Content>
				<Modal.Description style={{ color: '#4a4a4a', textAlign: "left" }}>
					Information on your overall Power Hours usage and savings.
					<ul>
						<li>Total Savings: the amount of money you’ve saved throughout all Power Hours events.</li>
						<li>Total Power Hours: the total hours of free electricity that you’ve received during all Power Hours events.</li>
						<li>Total Usage: the total amount of kWh you’ve used during all Power Hours events.</li>
					</ul>
				</Modal.Description>
			</Modal.Content>
			<Modal.Actions>
				<Button
					color="orange"
					secondary
					fluid
					onClick={closeModal}
				>OK</Button>
			</Modal.Actions>
        </Modal>



		<Card className={"power-hours-card power-hours-summary"}>
			<Card.Content>
				<Panels>
					<Panel borderBottom>
						<PanelLabel>
							<InfoIcon
								height="24px"
								color='#002963'
								style={{
									position: 'absolute',
									right: '10px',
									top: '11px',
									fill: '#002963',
								}}
								onClick={() => { setShowingTotalsModal(true) }}
							/>
							Total Savings
						</PanelLabel>
						<PanelValue huge>
							<span className="power-hours-cost">
								<span className="dollars">$</span>
								{cost.toFixed(2)}
							</span>						
						</PanelValue>
					</Panel>
			
					<Panel left>
						<PanelLabel>
							Total Power Hours
						</PanelLabel>
						<PanelValue huge>{hours}<span className="total-hours">hrs</span></PanelValue>					
					</Panel>

					<Panel right>
						<PanelLabel>Total Usage</PanelLabel>
						{ (usage > 0) ? <>
							<PanelValue huge>{usage.toLocaleString('en-AU', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</PanelValue>
							<PanelValue small>kWh</PanelValue>
						</> : <>
							<PanelValue small>
								You had no<br />recorded usage
							</PanelValue>
						</> }
					</Panel>
				</Panels>
			</Card.Content>
		</Card>
	</>
}



function Panels({ children }) {
	return <div className="panels">{children}</div>
}
function Panel({ className, children, borderBottom, borderTop, left, right, condensed, ...props }) {
	return <div className={
		[
			'panel',
			condensed ? 'condensed' : null,
			borderBottom ? 'border-bottom' : null,
			borderTop ? 'border-top' : null,
			left ? 'left' : null,
			right ? 'right' : null,
			className ? className : null,
		].filter(c => c !== null).join(" ")
	} {...props}>
		{children}
	</div>
}

function PanelPowerIcon() {
	return <div style={{
		textAlign: 'right',
		position: 'absolute',
		right: '10px',
		top: 'calc(50% - 16px)', // Icons 32px high by default
	}}>
		<PowerHoursIcon color="white"/>
	</div>
}

function PanelLabel({ children }) {
	return <div className="panel-label">{children}</div>
}
function PanelValue({ children, huge, large, medium, small, className }) {
	return <div className={"panel-value" + (className ? " " + className : "") + (huge ? " huge" : "") + (large ? " large" : "") + (medium ? " medium" : "") + (small ? " small" : "")}>{children}</div>
}