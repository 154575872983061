import React from 'react';
export default function Icon({
    color = 'currentColor',
    height = '32px',
    width = height
}) { return (
    <svg
      height={height}
      width={width}
      viewBox="0 0 50 50"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path stroke={color} d="M8.33301 5.16675C8.33301 4.61447 8.78072 4.16675 9.33301 4.16675H40.6663C41.2186 4.16675 41.6663 4.61446 41.6663 5.16675V44.8334C41.6663 45.3857 41.2186 45.8334 40.6663 45.8334H9.33301C8.78072 45.8334 8.33301 45.3857 8.33301 44.8334V5.16675Z" strokeWidth="2" />
      <path fill={color} d="M14.583 9.41675C14.0307 9.41675 13.583 9.86446 13.583 10.4167C13.583 10.969 14.0307 11.4167 14.583 11.4167V9.41675ZM35.4163 11.4167C35.9686 11.4167 36.4163 10.969 36.4163 10.4167C36.4163 9.86446 35.9686 9.41675 35.4163 9.41675V11.4167ZM14.583 11.4167H35.4163V9.41675H14.583V11.4167Z" />
      <path fill={color} d="M14.583 15.6667C14.0307 15.6667 13.583 16.1145 13.583 16.6667C13.583 17.219 14.0307 17.6667 14.583 17.6667V15.6667ZM35.4163 17.6667C35.9686 17.6667 36.4163 17.219 36.4163 16.6667C36.4163 16.1145 35.9686 15.6667 35.4163 15.6667V17.6667ZM14.583 17.6667H35.4163V15.6667H14.583V17.6667Z" />
      <path fill={color} d="M14.583 21.9167C14.0307 21.9167 13.583 22.3645 13.583 22.9167C13.583 23.469 14.0307 23.9167 14.583 23.9167V21.9167ZM35.4163 23.9167C35.9686 23.9167 36.4163 23.469 36.4163 22.9167C36.4163 22.3645 35.9686 21.9167 35.4163 21.9167V23.9167ZM14.583 23.9167H35.4163V21.9167H14.583V23.9167Z" />
      <path fill={color} d="M14.583 28.1667C14.0307 28.1667 13.583 28.6145 13.583 29.1667C13.583 29.719 14.0307 30.1667 14.583 30.1667V28.1667ZM35.4163 30.1667C35.9686 30.1667 36.4163 29.719 36.4163 29.1667C36.4163 28.6145 35.9686 28.1667 35.4163 28.1667V30.1667ZM14.583 30.1667H35.4163V28.1667H14.583V30.1667Z" />
      <path fill={color} d="M14.583 34.4167C14.0307 34.4167 13.583 34.8645 13.583 35.4167C13.583 35.969 14.0307 36.4167 14.583 36.4167L14.583 34.4167ZM27.083 36.4167C27.6353 36.4167 28.083 35.969 28.083 35.4167C28.083 34.8645 27.6353 34.4167 27.083 34.4167L27.083 36.4167ZM14.583 36.4167L27.083 36.4167L27.083 34.4167L14.583 34.4167L14.583 36.4167Z" />
    </svg>
)
}
