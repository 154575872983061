import React from 'react';
import {Link} from 'react-router-dom';
import axios from 'axios';

import {Header, Button, Form, Dimmer, Loader} from 'semantic-ui-react'

import Layout from '../../../layouts/Layout';
import WestpacPayment from './../../../components/WestpacPayment/WestpacPayment';
import {withCustomer} from '../../../contexts/Customer'
import { withRouter } from '../../../utils/useRouter';
import Modal from './../../../components/Modal';

export class PaymentSettingsAdd extends React.Component {

  state = {
    adding: false,
    loading: false,
    editing: false,
    errors: [],
    accounts: [],
    error: false
  }

  registerPaymentDetails = singleUseToken => {
    const { customer } = this.props.customer;

    const url = process.env.REACT_APP_API_BASE_URI + 'paymentAccounts/registerPaymentAccount';
    const body = {
      SingleUseToken: singleUseToken,
      CustomerID: customer.customerID
    };

    return axios.post(url, body)
  }

  saveNewCard = () => {
    this.setState({loading: true})

    this.westpacPayment.submit((err, token) => {
      if (!err) {
        let singleUseToken = token.singleUseToken.singleUseTokenId;

        this.registerPaymentDetails(singleUseToken)
          .then(response => {
            this.props.router.navigate('/accountSettings/payments')
          })
          .catch(err => {
            this.setState({
              error: err.message || true
            })
          })
          .finally(() => this.setState({loading: false}))
      } else {
        this.setState({loading: false});
      }
    })
  }

  render() {
    return (
      <Layout pageTitle="Payment Settings">
        <Header size="large" textAlign="center">Add a Card</Header>
        <Modal
          name="payment-settings/add/error"
          size="tiny"
          open={!!this.state.error}
          onClose={x => this.setState({error: false})}>
          <Modal.Header>Error</Modal.Header>
          <Modal.Content>
            <Modal.Description>
              <p>{this.state.error}</p>
            </Modal.Description>
          </Modal.Content>
          <Modal.Actions>
            <Button onClick={x => this.setState({error: false})}>
              OK
            </Button>
          </Modal.Actions>
        </Modal>
        <Form as='div'>
          <Dimmer inverted active={this.state.loading}>
            <Loader/>
          </Dimmer>

          <WestpacPayment
            basic
            ref={cmp => this.westpacPayment = cmp}
            customer={this.props.customer}
          />
          <Form.Button fluid secondary onClick={e => this.saveNewCard()}>Save</Form.Button>
          <Form.Field>
            <Button as={Link} to="." fluid basic>Cancel</Button>
          </Form.Field>

        </Form>
      </Layout>
    )
  }
}

export default withRouter(withCustomer(PaymentSettingsAdd))
