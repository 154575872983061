import React from 'react';
export default function Icon({
    color = 'currentColor',
    height = '32px',
    width = height
}) { return (
    <svg
      height={height}
      width={width}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path stroke={color} d="M7 7L19 19C19.5523 19.5523 20.4477 19.5523 21 19V19C21.5523 18.4477 21.5523 17.5523 21 17L9 5" />
      <path stroke={color} d="M9 5C9.5522 5.55219 9.55219 6.4478 9 7C8.4478 7.55219 7.5522 7.5522 7 7C6.44781 6.44781 6.44781 5.5522 7 5C7.5522 4.4478 8.4478 4.4478 9 5Z" />
      <path stroke={color} d="M12 8C12.5522 8.55219 12.5522 9.4478 12 10C11.4478 10.5522 10.5522 10.5522 10 10" />
      <path stroke={color} d="M20 2C20 3.65685 21.3431 5 23 5C21.3431 5 20 6.34315 20 8C20 6.34315 18.6569 5 17 5C18.6569 5 20 3.65685 20 2Z" strokeLinecap="round" strokeLinejoin="round" />
      <path stroke={color} d="M7 15C7 16.6569 8.34315 18 10 18C8.34315 18 7 19.3431 7 21C7 19.3431 5.65685 18 4 18C5.65685 18 7 16.6569 7 15Z" strokeLinecap="round" strokeLinejoin="round" />
      <path fill={color} d="M14.5 7C14.5 7.27614 14.7239 7.5 15 7.5C15.2761 7.5 15.5 7.27614 15.5 7H14.5ZM15.5 19C15.5 18.7239 15.2761 18.5 15 18.5C14.7239 18.5 14.5 18.7239 14.5 19H15.5ZM3 1.5H13V0.5H3V1.5ZM13 22.5H3V23.5H13V22.5ZM1.5 21V3H0.5V21H1.5ZM14.5 3V7H15.5V3H14.5ZM14.5 19V21H15.5V19H14.5ZM3 22.5C2.17157 22.5 1.5 21.8284 1.5 21H0.5C0.5 22.3807 1.61929 23.5 3 23.5V22.5ZM13 23.5C14.3807 23.5 15.5 22.3807 15.5 21H14.5C14.5 21.8284 13.8284 22.5 13 22.5V23.5ZM13 1.5C13.8284 1.5 14.5 2.17157 14.5 3H15.5C15.5 1.61929 14.3807 0.5 13 0.5V1.5ZM3 0.5C1.61929 0.5 0.5 1.61929 0.5 3H1.5C1.5 2.17157 2.17157 1.5 3 1.5V0.5Z" />
    </svg>
)
}

