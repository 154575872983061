import React from 'react';
export default function Icon({
    color = 'currentColor',
    height = '32px',
    width = height,
    count
}) {
  return (
    <React.Fragment>
      <svg
        height={height}
        width={width}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path fill={color} d="M19.6217 16.049L20.0311 15.7619L19.6217 16.049ZM5.2215 18.8779L5.08255 19.3582L5.2215 18.8779ZM6.5 10C6.5 8.07707 6.98039 6.72671 7.85355 5.85355C8.72671 4.98039 10.0771 4.5 12 4.5V3.5C9.92293 3.5 8.27329 4.01961 7.14645 5.14645C6.01961 6.27329 5.5 7.92293 5.5 10H6.5ZM5.5 10C5.5 13.1611 4.7437 14.6569 3.96892 15.7619L4.78771 16.336C5.68078 15.0623 6.5 13.3659 6.5 10H5.5ZM5.08255 19.3582C6.32262 19.7169 8.26096 20 12 20V19C8.29907 19 6.46882 18.7182 5.36045 18.3976L5.08255 19.3582ZM18.5 10C18.5 7.92293 17.9804 6.27329 16.8536 5.14645C15.7267 4.01961 14.0771 3.5 12 3.5V4.5C13.9229 4.5 15.2733 4.98039 16.1464 5.85355C17.0196 6.72671 17.5 8.07707 17.5 10H18.5ZM17.5 10C17.5 13.3659 18.3192 15.0623 19.2123 16.336L20.0311 15.7619C19.2563 14.6569 18.5 13.1611 18.5 10H17.5ZM18.6395 18.3976C17.5312 18.7182 15.7009 19 12 19V20C15.739 20 17.6774 19.7169 18.9175 19.3582L18.6395 18.3976ZM19.2123 16.336C19.7745 17.1379 19.4369 18.1669 18.6395 18.3976L18.9175 19.3582C20.5139 18.8963 20.8994 17.0004 20.0311 15.7619L19.2123 16.336ZM3.96892 15.7619C3.10056 17.0004 3.48614 18.8963 5.08255 19.3582L5.36045 18.3976C4.56307 18.1669 4.22546 17.1379 4.78771 16.336L3.96892 15.7619Z" />
        <path fill={color} d="M10.5 21C10.5 20.7239 10.2761 20.5 10 20.5C9.72386 20.5 9.5 20.7239 9.5 21H10.5ZM14.5 21C14.5 20.7239 14.2761 20.5 14 20.5C13.7239 20.5 13.5 20.7239 13.5 21H14.5ZM12 22C11.5742 22 11.1769 21.892 10.9024 21.709C10.6426 21.5358 10.5 21.306 10.5 21H9.5C9.5 21.694 9.85741 22.2142 10.3477 22.541C10.8231 22.858 11.4258 23 12 23V22ZM12 23C12.5742 23 13.1769 22.858 13.6524 22.541C14.1426 22.2142 14.5 21.694 14.5 21H13.5C13.5 21.306 13.3574 21.5358 13.0977 21.709C12.8231 21.892 12.4258 22 12 22V23Z" />
        <path stroke={color} d="M14 4C14 2.89543 13.1046 2 12 2C10.8954 2 10 2.89543 10 4" />
      </svg>
      {count !== 0 && <span className="count">{count}</span>}
    </React.Fragment>
)
}