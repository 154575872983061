import React from 'react';
export default function Icon({
    color = 'currentColor',
    height = '32px',
    width = height
}) { return (
    <svg
      height={height}
      width={width}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path stroke={color} d="M2 10.5C2 9.67157 2.67157 9 3.5 9V9C4.32843 9 5 9.67157 5 10.5V20.5C5 21.3284 4.32843 22 3.5 22V22C2.67157 22 2 21.3284 2 20.5V10.5Z" />
      <path stroke={color} d="M8 6.5C8 5.67157 8.67157 5 9.5 5V5C10.3284 5 11 5.67157 11 6.5V20.5C11 21.3284 10.3284 22 9.5 22V22C8.67157 22 8 21.3284 8 20.5V6.5Z" />
      <path stroke={color} d="M14 14.5C14 13.6716 14.6716 13 15.5 13V13C16.3284 13 17 13.6716 17 14.5V20.5C17 21.3284 16.3284 22 15.5 22V22C14.6716 22 14 21.3284 14 20.5V14.5Z" />
      <path stroke={color} d="M20 3.5C20 2.67157 20.6716 2 21.5 2V2C22.3284 2 23 2.67157 23 3.5V20.5C23 21.3284 22.3284 22 21.5 22V22C20.6716 22 20 21.3284 20 20.5V3.5Z" />
    </svg>
)
}
