import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router'
import { Form } from 'formsy-semantic-ui-react'
import { Button } from 'semantic-ui-react'
import { FORM_STATE } from '../../../../utils/FormState'
import Layout from '../../../../layouts/Layout.js'
import useScrollToTop from '../../../../utils/useScrollToTop.js'
import SignupFormLayout from '../../../../layouts/SignupFormLayout.js'
import SignupButton from '../../components/SignupButton/SignupButton.js'
import axios from 'axios'
import OtpInput from './OtpInput'
import './Verify.css'
import SignupError from '../../components/SignupError/SignupError'
import SignupTip from '../../components/SignupTip/SignupTip'
import SignupTipButton from '../../components/SignupTip/SignupTipButton'
import { useSignup } from '../../../../contexts/Signup'
import SignupErrorModal, { CONNECTIVITY_ERROR, GENERIC_ERROR } from "../../components/SignupErrorModal/SignupErrorModal.js";
import Analytics from '../../../../utils/Analytics'

const RE_DIGIT = new RegExp(/^\d{6}$/)

export default () => {

	const navigate = useNavigate();
	useScrollToTop();

	const signup = useSignup();
	if (!signup.token
		|| (signup.verificationType !== "Phone" && signup.verificationType !== "Email")
		|| (!signup.maskedEmailAddress && !signup.maskedPhoneNumber)
	) navigate('/signup/residential');

	const [formState, setFormState] = useState(FORM_STATE.DEFAULT);
	const [canSubmit, setCanSubmit] = useState(false);
	const [errorModal, setErrorModal] = useState(null);

	const [otp, setOtp] = useState('')
	const [codeError, setCodeError] = useState(false);
	const [codeResent, setCodeResent] = useState(false);
	const [codeResentError, setCodeResentError] = useState(false); // TODO: not handled


	function submitResendCode(payload) {
		const endpoint = process.env.REACT_APP_API_BASE_URI + "signup/verify/send";

		setCodeResentError(false);
		setCodeResent(true);

		axios
			.post(endpoint, {
				accountNo: signup.accountNo,
				emailAddress: signup.emailAddress,
				dateOfBirth: signup.dateOfBirth,
				verificationType: signup.verificationType,
			}, {
				headers: {
					common: {
						'authorization': 'bearer ' + signup.token
					}
				},
				ignoreGlobalErrors: true
			})
			.then(response => {
			})
			.catch(error => {

				switch(error.response ? error.response.status : error.code) {
					default:
						setCodeResentError(true);
						break;

				}
				
			});
	}

	const submit = (payload) => {
		const endpoint = process.env.REACT_APP_API_BASE_URI + "signup/verify/validate";

		window.scrollTo(0, 0);
		setCanSubmit(false);
		setFormState(FORM_STATE.LOADING);

		setCodeError(false);

		axios
			.post(endpoint, {
				accountNo: signup.accountNo,
				emailAddress: signup.emailAddress,
				dateOfBirth: signup.dateOfBirth,
				verificationType: signup.verificationType,
				verificationCode: otp,
			}, {
				headers: {
					common: {
						'authorization': 'bearer ' + signup.token
					}
				},
				ignoreGlobalErrors: true				
			})
			.then((response) => {
				setFormState(FORM_STATE.DONE_LOADING);

				signup.set({
					token: response.data.Token,
					emailAddress: response.data.EmailAddress,
				})

				Analytics.event({ category: "Signup", action: "CustomerVerifySuccess" });

				
				setTimeout(() => {
					navigate('/signup/residential/confirm-email');
				}, 1);
				
			})
			.catch((error) => {

				setFormState(FORM_STATE.DEFAULT);
				setCanSubmit(true);

				Analytics.event({ category: "Signup", action: "CustomerVerifyFailure" });

				switch(error.response ? error.response.status : error.code) {
					case 401:
						setCodeError(true);
						setOtp('');					
						break;
					case 404:
						navigate('/signup/external/residential');
						break;
					case 500:
						setErrorModal(GENERIC_ERROR);
						break;
					default:
						setErrorModal(CONNECTIVITY_ERROR);
						break;

				}
				

			})

	}

	useEffect(() => {
		setCanSubmit(RE_DIGIT.test(otp));
	}, [otp]);

	return (
		<Layout
			requiresCustomer={false}
			accountPicker={false}
			layout={SignupFormLayout}
			journey={{ message: 'Enter your code', journeyName: "signup", currentStep: 2 }}
		>
			<Form onValidSubmit={submit}>
				<p className="verify-prompt">
					{ signup.verificationType === "Email" ?
						"We have sent a 6 digit verification code to the email address " + signup.maskedEmailAddress + ", enter the code below:"
					: signup.verificationType === "Phone" ?
						"We have sent a 6 digit verification code to the mobile number " + signup.maskedPhoneNumber + ", enter the code below:"
					:
						null
					}
				</p>

				{ codeError && <SignupError>
					You verification code did not match, please try again
				</SignupError>}

				<OtpInput
					value={otp}
					valueLength={6}
					onChange={value => setOtp(value)}
				/>

				<SignupButton
					disabled={!canSubmit}
					loading={formState === FORM_STATE.LOADING}
				>
					Continue
				</SignupButton>


				<SignupTip>
					<p>It can take up to 2 minutes to receive a code, if you haven't after that time try resending</p>
					<SignupTipButton disabled={codeResent} onClick={submitResendCode}>{codeResent ? "Code sent" : "Resend code"}</SignupTipButton>
				</SignupTip>

				<SignupErrorModal open={!!errorModal} message={errorModal} onClose={() => { setErrorModal(null); }}/>

			</Form>
		</Layout>
	)
}
