import React from 'react';
import {Item, Visibility, Transition, Loader, Button} from 'semantic-ui-react';
import Log from '../utils/Log';

class InfiniteList extends React.Component {
  static defaultProps = {
    getData: async() => (await[]),
    isMoreToLoad: (data) => (data && data.length > 0),
    noDataText: 'No results found.',
    noData: (props) => (<Item content={props.noDataText}/>),
    loader: () => (
      <Item children={< Loader active />}/>
    ),
    isSideMenuOpen: false
  }
  state = {
    items: [],
    loading: false,
    moreToLoad: true,
    currentPage: 1,
    rowCount: 10
  }

  componentDidMount() {
    this.getData(1, true)
  }

  getData(page = 1, reload = false) {
    if (!this.state.moreToLoad || this.props.isSideMenuOpen) 
      return;
    
    this.setState({loading: true})

    return this
      .props
      .getData(this.state.rowCount, page)
      .then(data => {
       
        this.setState({
          loading: false,
          moreToLoad: this.props.isMoreToLoad(data),
          items: reload
            ? data
            : this.state.items.concat(data)
        })

        return data
      })
      .catch(err => {
        this.setState({loading: false})
      })
  }

  render() {
    const renderItem = this.props.children
      ? this.props.children
      : this.props.item;

    return (
      <Visibility
        className={this.props.isSideMenuOpen
        ? 'max-viewport-height'
        : undefined}
        once={false}
        onBottomVisible={x => {
          if (this.state.moreToLoad && !this.props.manualLoadMore) {
            this.getData(this.state.currentPage + 1);
            this.setState({
              currentPage: this.state.currentPage + 1
            })
          }
        }}>
        <Transition.Group as={this.props.as || Item.Group} animation="fade down">
          {this
            .state
            .items
            .map((item, itemIndex) => {
              return renderItem({
                item,
                itemIndex,
                ...this.props
              })
            })}
          {!this.state.loading && this.state.items.length === 0 && this
            .props
            .noData(this.props)}
          {this.state.loading && this
            .props
            .loader(this.props)}

		  {!this.state.loading && this.state.moreToLoad && this.props.manualLoadMore &&
		  	<Button
				secondary
				style={{
					minWidth: '12em',
					margin: '1.5rem auto 0',
				}}
				onClick={() => {
					this.getData(this.state.currentPage + 1);
					this.setState({
						currentPage: this.state.currentPage + 1
					})
				}}
			>Load More</Button>
		  }
        </Transition.Group>
      </Visibility>
    );
  }
}

export default InfiniteList
