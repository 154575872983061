import { createContext, useContext, useReducer } from 'react'

export const themes = {
  residential: {
    name: "residential", // name must match "ServiceAgreementType" value coming from API
    background: "linear-gradient(0deg, #79C6E4 0%, #0060AE 100%)", // full page background
    sidebar: {
      background: "linear-gradient(157deg, #002963, #417cec)",
    },
    primary: {
      backgroundColor: "#f7902b", // orange (buttons)
      color: '#0060ae', // cards header text colour
    },
    secondary: {
      backgroundColor: "#E7F1F8",// light blue card background
      background: "linear-gradient(to right, #1a7bbb, #0868b2)", // dropdown background
    },
    tertiary: {
      backgroundColor: "#0060ae", // transaction history and account settings tabs
    },
    quaternary:{
      backgroundColor: "#E7F1F8",// light blue card background
      background: "linear-gradient(to right, #1a7bbb, #0868b2)", // dropdown background
    },
    important: {
      backgroundColor: "#f7902b", // payment buttons colour
      color: 'red' // negative dollar amount
    }
  },
  business: {
    name: "business", // name must match "ServiceAgreementType" value coming from API
    background: "linear-gradient(188.56deg, #415879 6.43%, #E8E8E8 103.45%)", // full page background
    sidebar: {
      background: "linear-gradient(188.56deg, #415879 55.43%, #E8E8E8 142.45%)",
    },
    primary: {
      backgroundColor: "#415879", // dark blue (buttons)
      color: '#415879', // cards header text colour / button BG
    },
    secondary: {
      backgroundColor: "#E8E8E8", // grey card background
      background: "linear-gradient(to right, #415879, #6a8dbf)", // dropdown background
    },
    tertiary: {
      backgroundColor: "#415879", // transaction history and account settings tabs
    },
    quaternary:{
      backgroundColor: "white",// white background for predication cards
      background: "linear-gradient(to right, #1a7bbb, #0868b2)", // dropdown background
    },
    important: {
      backgroundColor: "#FAA63D", // payment buttons colour (slightly darker orange)
      color: 'red' // negative dollar amount
    }
  }
}

const initialState = {
  theme: themes.residential,
  set: () => null
}

function themeReducer(state, payload) {
  return { ...state, ...payload }
}

export const ThemeContext = createContext(initialState)

export function ThemeProvider({ children }) {
  const [state, setState] = useReducer(themeReducer, initialState)

  const theme = {
    ...state,
    setState,
    set: name => name === state.name ? null : setState({ theme: themes[name] })
  }

  return (
    <ThemeContext.Provider value={theme}>
      {children}
    </ThemeContext.Provider>
  )
}

export const useTheme = () => useContext(ThemeContext)

export function withTheme(Component) {
  return function WrappedComponent(props) {
    return (
      <ThemeContext.Consumer>
        {theme => <Component {...props} theme={theme}/>}
      </ThemeContext.Consumer>
    )
  }
}