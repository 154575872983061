import React from 'react';
import moment from 'moment';
import { Statistic, Button, Card } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import { PaymentPlanStatus } from '../../../model/PaymentPlan';
import InfoIcon from '../../../components/Icons/InfoIcon';
import TickIcon from '../../../components/Icons/TickIcon';
import SwitchIcon from '../../../components/Icons/SwitchIcon';
import BillIcon from '../../../components/Icons/BillIcon';
import BillingDetails from '../../../model/BillingDetails';
import { Payment } from '../..';

const generalErrorMessage = 'Oh no! Something went wrong! We are unable to get your data. We will fix this as soon as possible.';
const noBillYet = "We are just preparing your account and data. We'll have it ready for you soon.";

const noUsageBalanceError = "Your account balance will be available after we've received usage data for this premise.";
const noUsageEstimatedDaysError = "Information will be available after we've received usage data for this premise.";
const noPaymentsDue = "Nice work! You have no payments due!";

const BaseWidget = ({ isRed, children }) => (
  <Statistic style={{ width: '100%' }} color={isRed ? 'red' : 'blue'}>
    <Statistic.Value>
      {children}
    </Statistic.Value>
  </Statistic>
)

export const BalanceWidget = props => {
  const { premise, hasLoaded } = props;

  const isNegative = premise ? premise.isBalanceNegative : '';
  const isError = (!premise && hasLoaded) || (premise && premise.currentBalanceDisplay === null)
  const isPaygToPayg = (isError && (premise && premise.balanceLastUpdatedDate === null));

  if (isPaygToPayg) {
    return <p style={{ padding: '25px' }}>{noUsageBalanceError}</p>;
  }

  if (isError) {
    return <p style={{ padding: '25px' }}>{generalErrorMessage}</p>;
  }

  if (!premise) return null;

  return (
    <BaseWidget isRed={isNegative}>
      <sup style={{ fontSize: '55%' }}>
        {isNegative ? '-' : ''}
        $
      </sup>{premise.currentBalanceDisplay}
	  {!isNegative && <span style={{ fontSize: '50%', lineHeight: 1 }}>CR</span>}
    </BaseWidget>
  );
}

export const EstimatedDaysWidget = props => {
  const { premise, hasLoaded } = props;

  const isError = (!premise && hasLoaded) || (premise && premise.estimatedDaysDisplayValue === null);
  const isPaygToPayg = (isError && (premise && premise.balanceLastUpdatedDate === null));

  if (isPaygToPayg) {
    return <p style={{ padding: '25px' }}>{noUsageEstimatedDaysError}</p>;
  }

  if (isError) {
    return <p style={{ padding: '25px' }}>{generalErrorMessage}</p>;
  }

  if (!premise || !hasLoaded) return null;

  const daysRemaining = Math.abs(premise.estimatedDaysDisplayValue);

  return (
    <BaseWidget isRed={daysRemaining === 0}>
      {daysRemaining}

      <span style={{ fontSize: '24px', textTransform: 'none', lineHeight: '0px' }}>
        &nbsp;Day
        {
          daysRemaining !== 1 ? 's' : ''
        }
      </span>
    </BaseWidget>
  )
}

export const PostpayBillCard = props => {
  const { theme, bills, activePayment, openModal, history, pageConfig, paymentExtension } = props;
  
  if ((!bills && !paymentExtension) || !activePayment || !theme) return null;

  const isBusinessCustomer = pageConfig && pageConfig.productName === "business";
  const hasExtension = paymentExtension && paymentExtension.isActive;
  const extensionOverdue = hasExtension && paymentExtension.isOverdue;

  const billCount = bills ? bills.outStanding.length : 0;
  const billOverdueCount = bills ? bills.overdue.length : 0;

  const hasMultiple = ((hasExtension ? 1 : 0) + billCount) > 1;
  const hasOverdue = billOverdueCount > 0 || extensionOverdue;
  const allOverdue = hasOverdue
    && billOverdueCount === billCount
    && (hasExtension ? extensionOverdue : true);

  let billsAndExtension = bills && bills.outStanding || [];
  if (hasExtension) {
    billsAndExtension = billsAndExtension.concat(paymentExtension);
  }

  const renderBill = (obj) => (
    <div
      key={obj.billId || obj.accountId}
      style={{
        width: '100%',
        background: obj.isOverdue && '#FFEBEB'
      }}>
      <div className="cardBlock-top">
        <p style={{ color: '#4a4a4a', fontSize: '12px' }}>
          {obj.isExt ? 'Extended due date' : 'Due date'}
        </p>
        <p className="card-text-size">
          {moment(obj.dateDue).format("D")}
          <span className="card-date"> {moment(obj.dateDue).format("MMM")}</span>
        </p>
      </div>
      <div className="cardBlock-bottom">
        <p style={{ color: '#4a4a4a', fontSize: '12px' }}>
          Bill amount
        </p>
        <p className="card-text-size">
          <sup className="card-dollar">$</sup>
          {Math.abs(obj.amountOutstanding).toLocaleString('en-AU', {
            currency: 'AUD',
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
          })}
        </p>
      </div>
    </div>
  );

  return (
    <Card style={{ width: '100%' }}>
      <Card.Header textAlign="center">
        Payment{hasMultiple && 's'} due
        <InfoIcon height="24px" style={{ float: 'right' }} onClick={() => openModal('modalCurrentBalance')} />
        {(activePayment.isAutoPaymentActive || activePayment.isDirectDebitActive) &&
          <SwitchIcon height="24px" style={{ float: 'right', marginRight: '10px' }} />
        }
      </Card.Header>
      <Card.Content
        style={{
          backgroundColor: allOverdue ? '#FFEBEB' : isBusinessCustomer ? 'white' : theme.secondary.backgroundColor,
          padding: '0 0 1em 0'
        }}
        textAlign="center">
        <Statistic
          color='blue'
          style={{
            width: '100%',
            borderBottom: '1px solid lightgrey'
          }}>
          <Statistic.Value>
            <p
              id='home-total-bill-list'
              style={{ background: hasOverdue && '#FFEBEB' }}>
              You have {hasOverdue ? 'an overdue bill' : hasMultiple ? 'bills due' : 'a bill due'}<br /> for payment
            </p>

            {billsAndExtension &&
              BillingDetails
                .sortByDate(billsAndExtension)
                .map(obj => renderBill(obj))
            }

          </Statistic.Value>
        </Statistic>

        <Button
          style={{ backgroundColor: theme.primary.backgroundColor }}
          secondary
          onClick={() => history.navigate('/payment')}>
          Pay now
        </Button>

        {(pageConfig && pageConfig.functionality.paymentHelp) &&
          <Link
            style={{ margin: '10px 0 -5px 0' }}
            className="payment-plan-link"
            to="/payment-help" >
            Need more time? We're here to help
          </Link>
        }

      </Card.Content>
    </Card>
  );
}

export const PrepayBillCard = props => {
  const { theme, bills, openModal, billClicked } = props;
  
  if (!bills || !theme) return null;

  const DueHeader = () => (
    <Card.Header textAlign="center">
      You have a bill due
      <InfoIcon
        height="24px"
        style={{ float: 'right' }}
        onClick={() => openModal('modalBillDue')} />
    </Card.Header>
  )

  const OverdueHeader = () => (
    <Card.Header textAlign="center">
      You have an overdue bill
      <InfoIcon
        height="24px"
        style={{ float: 'right' }}
        onClick={() => openModal('modalBillOverDue')} />
    </Card.Header>
  )

  return (
    bills.outStanding.map(obj =>
      <Card key={obj.billId}>
        {obj.isOverdue
          ? <OverdueHeader />
          : <DueHeader />
        }
        <Card.Content
          textAlign="center"
          style={{
            backgroundColor: obj.isOverdue ? '#FFEBEB' : theme.secondary.backgroundColor
          }}>

          <div style={{
            display: 'block',
            margin: 'auto auto 0.5em auto'
          }}>
            <BillIcon height={50} color={theme.primary.color} />
          </div>

        {//DPS-138, Bills in HUBcx that have the prefix of "CCB" in their invoiceDocNnumber don't have valid PDFs, so hide the button. 
          //However CC&B Bills won't have an invoiceDocNnumber, but we still want to show their PDF links
          !obj.invoiceDocNumber ? 
          (
            <Button
              style={{ backgroundColor: theme.primary.backgroundColor }}
              secondary
              onClick={() => billClicked(obj.billId)}>
              View Bill
            </Button>
          
          ) : (
          
           !obj.invoiceDocNumber.includes('CCB') && 
            <Button
              style={{ backgroundColor: theme.primary.backgroundColor }}
              secondary
              onClick={() => billClicked(obj.billId)}>
              View Bill
            </Button>
          )
        }

          <Link className="payment-plan-link" to="/payment-help" >
            Need more time? We're here to help
          </Link>
        </Card.Content>
      </Card>
    )
  );
}

export const BillPredictionWidget = props => {
  const { premise } = props;

  if (!premise) return null;

  const nextBillDate = moment(premise.billNextRun);

  return (
    <BaseWidget>
      <p style={{
        fontSize: '40px',
        textTransform: 'none',
        lineHeight: '.5em',
        margin: '10px 0'
      }}>
        {nextBillDate.format("D")}<span
          style={{
            fontSize: '30px',
            lineHeight: '0px',
            textTransform: 'none',
            paddingLeft: '5px'
          }}>{nextBillDate.format("MMM")}</span>
      </p>
    </BaseWidget>
  )
}

export const AvgBillWidget = props => {
  const { premise, hasLoaded, currentAvg } = props;
  
  if (!premise || !hasLoaded) return null;

  return (
    <BaseWidget>
      <p style={{
        fontSize: '40px',
        textTransform: 'none',
        lineHeight: '.5em',
        margin: '10px 0'
      }}>
        <sup style={{ fontSize: '24px' }}>$</sup>
        {currentAvg}
        <span
          style={{
            fontSize: '24px',
            lineHeight: '0px',
            textTransform: 'none'
          }}>/day</span>
      </p>
    </BaseWidget>
  )
}

export const PaymentExtensionWidget = props => {
  const { extension } = props;

  if (!extension) return null;

  const isOverdue = extension.isOverdue;

  return (
    <Statistic
      style={{
        width: '100%',
        borderBottom: '1px solid lightgrey'
      }}
      color={isOverdue ? 'red' : 'blue'}>
      <Statistic.Value>
        <div style={{ width: '100%', background: isOverdue ? '#FFEBEB' : '' }}>
          <div className="cardBlock-top">
            <p style={{ color: '#4a4a4a', fontSize: '12px' }}>
              Due date
            </p>
            <p style={{ fontSize: '40px' }}>
              {moment(extension.dateDue).format("D MMM")}
            </p>
          </div>
          <div className="cardBlock-bottom">
            <p style={{ color: '#4a4a4a', fontSize: '12px' }}>
              {isOverdue ? 'Amount overdue' : 'Amount due'}
            </p>
            <p style={{ fontSize: '40px' }}>
              <sup style={{ fontSize: '24px' }}>$</sup>{extension.amountOutstanding}
            </p>
          </div>
        </div>
      </Statistic.Value>
    </Statistic>
  );
}

export const PaymentPlanInstallments = props => {
  const { paginatedList, onLoadMore, displayLoadMore, theme, style } = props;

  return (
    <div className="plan__upcomingInstalments upcomingInstalments" style={{ ...style }}>

      <div className="upcomingInstalments__header" style={{ backgroundColor: theme.primary.color }}>
        <p>Upcoming instalments</p>
        <p style={{ fontSize: '12px' }}>Due date</p>
      </div>

      <div className="upcomingInstalments__body">
        {(paginatedList && paginatedList.length > 0)
          ? 
          paginatedList.map(obj =>
            <div key={obj.InstalmentId} className="upcomingInstalments__list-item">
              <p><b>${obj.InstallmentAmount}</b></p>
              <p>{obj.Date !== "" ? moment(obj.Date).format("D MMM") : ""}</p>
            </div>
          )
          :
          <p>No upcoming instalments.</p>
        }
      </div>

      <Button
        className="load-more"
        style={{ display: displayLoadMore ? "inline-block" : "none" }}
        onClick={() => onLoadMore()}>Load more</Button>
    </div>
  );
}

export const PaymentPlanWidget = props => {
  const { theme, history, plan, children, openModal } = props;

  if (!plan || !plan.currentInstalment) return null;
  
  let wasPaidInPastInstalment = false;
  const current = plan.currentInstalment;
  const status = plan.instalmentStatus(current);
  const instalmentDueDate = moment(current.Date);
  const showAdvancePayments = status === PaymentPlanStatus.PAID && plan.overpaidAmount > 0;

  // if overpaid last instalment, enough to pay off the current instalment, then we show a slightly different view
  if (status === PaymentPlanStatus.PAID && plan.currentIndex > 0) {
    const paidDate = moment(current.DatePaid);
    const prevDueDate = moment(plan.instalments[plan.currentIndex - 1].Date);
    
    // paidDate was before/on the previous instalment due date
    wasPaidInPastInstalment = prevDueDate.diff(paidDate, "days") >= 0;
  }

  // if current instalment is overdue, check to see if the next instalment is coming due as well
  // and add that sum to the current amount
  let overdueAmount = plan.overdueAmount;
  if (status === PaymentPlanStatus.OVERDUE) {
    const nextInstalment = plan.upcomingInstalments[0];

    // we only add the next instalment amount if it's coming due (due within 3 days of today)
    if (nextInstalment && plan.instalmentStatus(nextInstalment) === PaymentPlanStatus.DUE) {
      overdueAmount += nextInstalment.AmountOutstanding;
    }
  }

  const currentStatus = wasPaidInPastInstalment ? "paid" : status;
  const dynamicContent = {
    header: '',
    colorEmphasis: ''
  }

  switch (currentStatus) {
    case PaymentPlanStatus.OVERDUE:
      dynamicContent.header = "You are behind on your payments";
      dynamicContent.colorEmphasis = theme.important.color; // red
      break;
    case PaymentPlanStatus.DUE:
      dynamicContent.header = "You have an instalment due";
      dynamicContent.colorEmphasis = theme.primary.color; // blue
      break;
    case PaymentPlanStatus.PAID:
      dynamicContent.header = "You’re up to date";
      dynamicContent.colorEmphasis = '#6DD06B'; // green
      break;
    default:
      dynamicContent.header = "You’re up to date";
      dynamicContent.colorEmphasis = theme.primary.color; // blue
  }

  return (
    <div>
      <p className="plan__header"
        style={{ color: status === PaymentPlanStatus.OVERDUE ? dynamicContent.colorEmphasis : theme.primary.color }}>
        {dynamicContent.header}
      </p>

      <div className="plan__body instalment">

        {currentStatus === PaymentPlanStatus.OVERDUE
          ?
          <React.Fragment>
            <p className="instalment__header" style={{ backgroundColor: theme.primary.color }}>
              Overdue instalment
            </p>

            <div className="instalment__content-overdue" style={{ color: dynamicContent.colorEmphasis }}>
              <div>
                <p className="instalment__subheader-single">
                  Amount due
                </p>
                <p className="instalment__text">
                  <sup>$</sup>{overdueAmount}
                </p>
              </div>

              <p>Please pay this amount immediately</p>
            </div>
          </React.Fragment>
          :
          <React.Fragment>
            <p className="instalment__header" style={{ backgroundColor: dynamicContent.colorEmphasis }}>
              {status === PaymentPlanStatus.PAID
                ?
                <span>Instalment paid<TickIcon height={18} style={{ float: 'right' }} /></span>
                :
                <span>Next instalment{(current.AmountOutstanding < current.InstallmentAmount) &&
                  <InfoIcon height={18} style={{ float: 'right' }} onClick={() => openModal('modalPaymentPlanUnusual')} />}
                </span>
              }
            </p>

            <div className="instalment__content-due">
              <div className="instalment__subheader-double" style={{
                borderRight: `1px solid ${dynamicContent.colorEmphasis}`,
                borderBottom: status === PaymentPlanStatus.PAID ? '' : `1px solid ${dynamicContent.colorEmphasis}`,
                color: dynamicContent.colorEmphasis
              }}>
                <p style={{ margin: 0 }}>
                  {currentStatus === PaymentPlanStatus.PAID ? 'Amount paid' : 'Amount due'}
                </p>
                <p className="instalment__text">
                  <sup>$</sup>{status === PaymentPlanStatus.PAID ? current.AmountPaid : current.AmountOutstanding}
                </p>
              </div>

              <div className="instalment__subheader-double" style={{
                borderBottom: status === PaymentPlanStatus.PAID ? '' : `1px solid ${dynamicContent.colorEmphasis}`,
                color: dynamicContent.colorEmphasis
              }}>
                <p style={{ margin: 0 }}>
                  Due date
                </p>
                <p className="instalment__text">
                  {instalmentDueDate.format("D")}<span style={{ fontSize: '24px', paddingLeft: '5px' }}>{instalmentDueDate.format("MMM")}</span>
                </p>
              </div>
            </div>
          </React.Fragment>
        }

        <Button
          className="plan__paynow-btn"
          style={{
            display: currentStatus === PaymentPlanStatus.PAID ? 'none' : 'inline-block',
            backgroundColor: theme.important.backgroundColor
          }}
          secondary
          onClick={() => history.navigate('/payment')
          }>Pay now</Button>

      </div>

      {showAdvancePayments &&
        <div className="advancePayments">
          <p className="advancePayments__header" style={{ backgroundColor: theme.primary.color }}>
            Amount paid in advance
            <InfoIcon
              height={18}
              style={{ float: 'right' }}
              onClick={() => openModal('modalPaymentPlanAdvanced')}
            />
          </p>
          <p className="advancePayments__content" style={{ color: theme.primary.color }}>
            <sup>$</sup>{plan.overpaidAmount}
          </p>
        </div>
      }

      {children &&
        children
      }
    </div>
  );
}

export const ErrorMessages = { generalErrorMessage, noUsageBalanceError, noUsageEstimatedDaysError }
