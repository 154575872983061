import React from "react";
import Layout from "../../../layouts/Layout.js";
import useScrollToTop from "../../../utils/useScrollToTop.js";
import SignupFormLayout from "../../../layouts/SignupFormLayout.js";
import { useNavigate } from "react-router";
import ValidatedInput from "../../../components/Forms/ValidatedInput.js";
import ExternalLink from "../../../components/ExternalLink.js";


export default () => {

	const navigate = useNavigate();
	useScrollToTop();
	
	return (		
		<Layout
			requiresCustomer={false}
			accountPicker={false}
			layout={SignupFormLayout}
			journey={{ message: "Something is broken", error: true }}
		>

			<p>We are unable to create your login and set your password right now. </p>
			<p>We have been alerted to the problem and will be working on it.</p>
			<p>Please try again later.</p>

			<ExternalLink to="https://www.auroraenergy.com.au/">Visit the Aurora Energy Website</ExternalLink>

		</Layout>		
	);

}


