import React from 'react';
export default function Icon({
    color = 'currentColor',
    height = '32px',
    width = height
}) { return (
    <svg
      height={height}
      width={width}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path stroke={color} d="M3 2C3 1.44772 3.44772 1 4 1H20C20.5523 1 21 1.44772 21 2V22C21 22.5523 20.5523 23 20 23H4C3.44772 23 3 22.5523 3 22V2Z" />
      <path stroke={color} d="M12 19V5" strokeMiterlimit="10" strokeLinecap="round" />
      <path stroke={color} d="M8 15C8 15 8.43348 18 12 18C14.3759 18 16 16.8476 16 15C16 10.94 8.5 12.7156 8.5 8.5C8.5 7.02674 9.7897 6 12 6C14.7393 6 15.5 8 15.5 8" strokeMiterlimit="10" strokeLinecap="round" />
    </svg>
)
}
