import UsageIcon from "./UsageIcon";
import Sun from "./Sun";
import SMSIcon from "./SMSIcon";
import PredictIcon from "./PredictIcon";
import plugIcon from "./plugIcon";
import PayNowIcon from "./PayNowIcon";
import NotificationIcon from "./NotificationIcon";
import LogOutIcon from "./LogOutIcon";
import InfoSolidFA from "./InfoSolidFA";
import InfoIcon from "./InfoIcon";
import InAppIcon from "./InAppIcon";
import HomeIcon from "./HomeIcon";
import HelpIcon from "./HelpIcon";
import globeIcon from "./globeIcon";
import EmailIcon from "./EmailIcon";
import BusinessIcon from "./BusinessIcon";
import BillsAndPaymentsIcon from "./BillsAndPaymentsIcon";
import AccountSettingsIcon from "./AccountSettingsIcon";
import TickIcon from "./TickIcon";
import UsagePatternIcon from "./UsagePatternIcon";
import PowerHoursIcon from "./PowerHoursIcon";
import TruckIcon from "./TruckIcon";

export default {
  UsageIcon,
  Sun,
  SMSIcon,
  PredictIcon,
  plugIcon,
  PayNowIcon,
  NotificationIcon,
  LogOutIcon,
  InfoSolidFA,
  InfoIcon,
  InAppIcon,
  HomeIcon,
  HelpIcon,
  globeIcon,
  EmailIcon,
  BusinessIcon,
  BillsAndPaymentsIcon,
  AccountSettingsIcon,
  TickIcon,
  UsagePatternIcon,
  PowerHoursIcon,
  TruckIcon
};
