import React, { useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

import {Form} from 'formsy-semantic-ui-react';

import Layout from "../../layouts/Layout";

import { useExternalLink } from "../../components/ExternalLink";
import IconRadio from "../../components/IconRadio/IconRadio";
import { useState } from "react";
import SignupButton from "./components/SignupButton/SignupButton";

import IconRadioGroup from "../../components/IconRadio/IconRadioGroup";
import SignupBusinessIcon from '../../components/Icons/signup/SignupBusinessIcon';
import SignupResidentialIcon from '../../components/Icons/signup/SignupResidentialIcon';
import SignupNewConnectionIcon from '../../components/Icons/signup/SignupNewConnectionIcon';
import SignupMovingHouseIcon from '../../components/Icons/signup/SignupMovingHouseIcon';
import useScrollToTop from "../../utils/useScrollToTop";
import SignupFormLayout from "../../layouts/SignupFormLayout";
import { useSignup } from "../../contexts/Signup";


export default function SignupType() {

	const navigate = useNavigate();

	useScrollToTop();

	const [canSubmit, setCanSubmit] = useState(false);


	function submit(payload) {
		switch(payload.signupType) {
			case 'residential':
				navigate('/signup/residential');
				break;
			case 'business':
				navigate('/signup/external/business');
				break;
		}
	}


	return <>
		<Layout
			requiresCustomer={false}
			accountPicker={false}
			layout={SignupFormLayout}
			journey={{ message: "What type of property do you wish to connect to aurora+?" }}
			showSideContent={false}
		>
			<Form
				noValidate
				onValidSubmit={submit}
				onValid={() => {setCanSubmit(true)}}
				onInvalid={() => {setCanSubmit(false)}}
				style={{
					marginTop: '20px'
				}}
			>

				<IconRadioGroup
					name="signupType"
					formRadioGroup={true}
					required
					wide
				>
					<IconRadio name="signupType" value="residential" label="Residential" icon={SignupResidentialIcon} iconHeight={63} />
					<IconRadio name="signupType" value="business" label="Business" icon={SignupBusinessIcon} iconHeight={63} />
				</IconRadioGroup>

				<SignupButton
					disabled={!canSubmit}
				>
					Continue
				</SignupButton>

			</Form>

		</Layout>
    </>
}