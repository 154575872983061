import moment from 'moment';
import _ from 'lodash';

export const PaymentPlanStatus = {
  DUE: "due",
  OVERDUE: "overdue",
  PAID: "paid",
  INTERIM: "interim"
}


export default class PaymentPlan {

  constructor(paymentData) {
    
    this.accountId = paymentData.AccountId;
    this.instalments = paymentData.Instalments;
    this.dueDate = paymentData.DueDate;
    this.amount = paymentData.AmountToBePaid;
    this.startDate = paymentData.StartDate;
    this.status = paymentData.Status;

    this.index = -1;
  }

  // is not a date in the past
  static isTodayOrFuture(date) {
    return moment(date).startOf('day').diff(moment().startOf('day'), "days") >= 0;
  }

  // due within the next 3 days
  static isComingDue(date) {
    // note - payment plan instalments use midnight for the time portion
    // so we need to compare using midnight as well, hence the .startOf('day')
    const dateDiff = moment(date).startOf('day').diff(moment().startOf('day'), "days");
    
    return dateDiff >= 0 && dateDiff <= 3;
  }

  mapStatus(field) {
    switch (field) {
      case 'PD': // Paid by Due Date
      case 'PA': // Paid After Due Date
        return "paid";

      case 'ND': // Not Yet Due
      case 'NP': // Not Yet Paid
      case 'L': // Cancelled
      case 'N': // Reversed
      case 'D': // Dishonour
      default:
        return "unpaid";
    }
  }

  // for any instalment, return its status text (due, overdue, etc)
  instalmentStatus(obj) {

    // paid
    if (this.mapStatus(obj.Status) === "paid")
      return PaymentPlanStatus.PAID;

    // unpaid, due date is in the past
    if (!PaymentPlan.isTodayOrFuture(obj.Date))
      return PaymentPlanStatus.OVERDUE;

    // unpaid, due date is within 3 days of today
    if (PaymentPlan.isComingDue(obj.Date))
      return PaymentPlanStatus.DUE;

    // unpaid, due date is far off in the future
    return PaymentPlanStatus.INTERIM;
  }

  // sets the index to the first instalments index, that is either an unpaid past instalment or a future instalment
  updateCurrentIndex() {
    this.index = _.findIndex(this.instalments, (obj) =>
      this.mapStatus(obj.Status) === "unpaid" || PaymentPlan.isTodayOrFuture(obj.Date)
    );
  }

  // return all future instalments, other than the current instalment / coming-due instalment
  get upcomingInstalments() {
    return _.filter(this.instalments, (obj, key) =>
      key > this.currentIndex && PaymentPlan.isTodayOrFuture(obj.Date)
    );
  }

  get overpaidAmount() {
    return _.sumBy(this.upcomingInstalments, 'AmountPaid');
  }

  get overdueAmount() {
    const overdueInstallments = _.filter(this.instalments, (obj) =>
      !PaymentPlan.isTodayOrFuture(obj.Date) && this.mapStatus(obj.Status) === "unpaid"
    );

    return _.sumBy(overdueInstallments, 'AmountOutstanding');
  }

  // return the current active instalment, or null if there are no valid results
  get currentInstalment() {
    return this.currentIndex < 0
      ? null
      : this.instalments[this.currentIndex];
  }

  // get (and update) the current active intalment index
  get currentIndex() {

    // update index only if it's not been set
    if (this.index < 0) {
      this.updateCurrentIndex();
    }

    return this.index;
  }

  // has this plan activated?
  get hasStarted() {
    // return moment(this.startDate).diff(moment(), "hours") <= 0;
    return this.status === "Active";
  }

}
