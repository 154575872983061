import React from 'react';
export default function Icon({
    color = 'currentColor',
    height = '32px',
    width = height
}) { return (
    <svg
        height={height}
        width={width}
        viewBox="0 0 15 14"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg">
        <g id="Screens-v2" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g
                id="Hamburger-Menu"
                transform="translate(-32.000000, -320.000000)"
                fill={color}
                fillRule="nonzero">
                <g id="coin" transform="translate(32.000000, 320.000000)">
                    <path
                        d="M7.14285714,0 C3.20430437,0 0,3.14144063 0,7.0027248 C0,10.864009 3.20430437,14.0054496 7.14285714,14.0054496 C11.0814099,14.0054496 14.2857143,10.864009 14.2857143,7.0027248 C14.2857143,3.14144063 11.0814771,0 7.14285714,0 Z M7.14285714,13.083905 C3.72260921,13.083905 0.940052831,10.3558725 0.940052831,7.0027248 C0.940052831,3.6495771 3.72260921,0.921610378 7.14285714,0.921610378 C10.5631051,0.921610378 13.3456615,3.6495771 13.3456615,7.0027248 C13.3456615,10.3558725 10.5631051,13.083905 7.14285714,13.083905 Z"
                        id="Shape"></path>
                    <path
                        d="M7.74538559,6.70896419 L7.74538559,4.08616872 C8.01664378,4.1832433 8.25331843,4.36135635 8.41788128,4.60222801 C8.56354302,4.81531695 8.86082446,4.87431401 9.08188516,4.73401451 C9.30301434,4.59371502 9.36423747,4.30724271 9.21857573,4.09415378 C8.8741785,3.59023706 8.34350956,3.2481333 7.74538559,3.12756549 L7.74538559,2.39373181 C7.74538559,2.13860582 7.53076223,1.93178615 7.26600985,1.93178615 C7.00125748,1.93178615 6.78663411,2.13860582 6.78663411,2.39373181 L6.78663411,3.12617965 C5.72563863,3.33933457 4.92610837,4.24732176 4.92610837,5.33203616 C4.92610837,6.41675056 5.72570711,7.32486974 6.78663411,7.53802466 L6.78663411,10.1608201 C6.51537592,10.0637456 6.27870127,9.8856325 6.11413843,9.64476084 C5.96847668,9.4316719 5.67119524,9.37260885 5.45013454,9.51297434 C5.22900536,9.65327384 5.16778223,9.93974614 5.31344397,10.1528351 C5.6578412,10.6567518 6.18851015,10.9988555 6.78663411,11.1194234 L6.78663411,11.8531911 C6.78663411,12.108317 7.00125748,12.3151367 7.26600985,12.3151367 C7.53076223,12.3151367 7.74538559,12.108317 7.74538559,11.8531911 L7.74538559,11.1208092 C8.80638108,10.9076543 9.60591133,9.99966709 9.60591133,8.91495269 C9.60591133,7.83023829 8.80638108,6.92211911 7.74538559,6.70896419 Z M5.88485986,5.33203616 C5.88485986,4.76100534 6.26110133,4.27437858 6.78663411,4.08649868 L6.78663411,6.57757365 C6.26110133,6.38969375 5.88485986,5.90306699 5.88485986,5.33203616 Z M7.74538559,10.1604242 L7.74538559,7.66934921 C8.27091837,7.85722911 8.64715985,8.34385587 8.64715985,8.9148867 C8.64715985,9.48591752 8.27098685,9.97254428 7.74538559,10.1604242 Z"
                        id="Shape"
                        stroke="#FFFFFF"
                        strokeWidth="0.2"></path>
                </g>
            </g>
        </g>
    </svg>
)
}
