import React from "react";
import Layout from "../../../layouts/Layout.js";
import useScrollToTop from "../../../utils/useScrollToTop.js";
import IconSteps from "../../../components/IconSteps/IconSteps.js";
import IconStep from "../../../components/IconSteps/IconStep.js";
import SignupButton from '../components/SignupButton/SignupButton.js';
import { useNavigate } from "react-router";
import ResidentialFAQ from "../components/FAQs/ResidentialFAQ.js";
import SignupHR from "../components/SignupHR/SignupHR.js";
import Journeys from '../../../utils/Journeys';
import SignupFormLayout from "../../../layouts/SignupFormLayout.js";
import EmbeddedVideo from "../../../components/EmbeddedVideo/EmbeddedVideo.js";

export default function ResidentialSignup() {

	const navigate = useNavigate();
	useScrollToTop();

	return (
		<Layout
			requiresCustomer={false}
			accountPicker={false}
			layout={SignupFormLayout}
			journey={{ message: "Find your account", }}
			showSideContent={false}
		>
        	<p style={{
				fontSize: '16px',
				lineHeight: '28px',
				margin: '10px 0 20px'
			}}>
				Five simple steps to get you connected to aurora+:
			</p>

			<IconSteps separators fullWidth>
				{ Journeys.signup.steps.map(step => {
					return <IconStep
						key={step.title}
						icon={step.icon}
						title={step.title}
						text={step.text}
					/>
				})}				
			</IconSteps>
			
			<SignupButton
				style={{marginTop: '45px' }}
				onClick={() => {
					navigate('/signup/residential/find-your-account');
				}}
			>
				Let's get started
			</SignupButton>


			<SignupHR spaced />

			<ResidentialFAQ />

			<EmbeddedVideo
				src="https://player.vimeo.com/video/840705401?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
				width="640"
				height="360"
				title="test"
				allowFullScreen
				allow="autoplay; fullscreen; picture-in-picture"
				frameBorder="0"
				style={{
					marginTop: '30px',
					marginBottom: '80px'
				}}
			/>
			
		</Layout>
	);

}
