import React from 'react';
import {Dropdown, Message} from 'semantic-ui-react';
import Analytics from '../../utils/Analytics';

import { withCustomer } from '../../contexts/Customer'
import { withTheme } from '../../contexts/Theme';

import './AccountPicker.css'

export class AccountPicker extends React.Component {

  state = {
    open: false
  }

  /*  APAYGDEV-486
      Takes address and returnes all caps for CC&B or Title(sentence) case for HubCX
  */
  formatAddress = (address,isFromCCB) => {
    //Regex matches comma and optionaly all following spaces
    address = address.split(/,\s*/).join(", ").toUpperCase()
    if (!isFromCCB) address = address.split(" ").map(a => a.charAt(0) + a.slice(1).toLowerCase()).join(" ")
    return address
  }

  setClose = () => {
    this.setState({open: false});
    Analytics.event({category: 'Account', action: 'Swap Accounts'})
  }

  setOpen = () => {
    this.setState({open: true});
  }
  DropdownItem = (text) => {
    const { theme } = this.props.theme;
    return (
      <span
        style={{
          background: theme.secondary.background,
          display: "block",
          padding: ".8125rem 1.125rem",
        }}>
        {text}
      </span>
    )
  }

  render() {
    if (!this.props.visible) 
      return null

    let { customerPremise, hasLoaded, setCustomerPremise, premises, isFromHubCX } = this.props.customer
    //let premises = this.props.customer.filteredPremises;
    //handle the scenario - hubCX interim user without HubCX premise returned but has Old CCB premises
    if(premises.filter(c => c.serviceAgreementStatus == "Old_CCB").length != premises.length && this.props.customer.isFromHubCX){
        // do not display old CCB accounts for new HubCX users in some circumstances
      if (!this.props.oldCCBAccountsDisplayed) {
        // change dropdown to use any other account
        if (customerPremise && customerPremise.serviceAgreementStatus == "Old_CCB") {
          setCustomerPremise(premises.filter(p => p.serviceAgreementStatus !== "Old_CCB")[0].identifier);
        }

        // then remove old CCB accounts from Premise list
        premises = premises.filter(p => p.serviceAgreementStatus !== "Old_CCB");
      }
    }
    
    let value = customerPremise
      ? customerPremise.identifier
      : '';
    let initialText = customerPremise
      ? this.formatAddress(customerPremise.address, (!isFromHubCX || customerPremise.serviceAgreementStatus=="Old_CCB"))
      : '';
    

      premises = premises.map(p => ({ 
      text: this.formatAddress(p.address,(!isFromHubCX || p.serviceAgreementStatus=="Old_CCB")),
      value: p.identifier,
      content: this.DropdownItem(this.formatAddress(p.address,(!isFromHubCX || p.serviceAgreementStatus=="Old_CCB")))
    }))
    let text = (this.state.open
      ? '✓ '
      : '') + initialText

    return (hasLoaded && premises.length === 0
      ? <Message error color='red'>
          <Message.Header>
            Oh no! Something went wrong!
          </Message.Header>
          We are unable to get your data. We will fix this as soon as possible.
        </Message>
      : <div style={{
        position: 'relative'
      }}>
        <Dropdown
          basic
          fluid
          selection
          className='account-picker'
          disabled={!hasLoaded || this.props.disabled || premises.length <= 1}
          options={premises}
          onChange={(ev, data) => setCustomerPremise(data.value)}
          selectOnBlur={false}
          text={text}
          icon={premises.length > 1
          ? "angle down"
          : null}
          onOpen={this.setOpen}
          onClose={this.setClose}
          value={value}/>
      </div>);
  }
}

export default withCustomer(withTheme(AccountPicker))
