import React from 'react';
import { Header, Icon } from 'semantic-ui-react';
import InfoIcon from '../Icons/InfoIcon';
import PlugIcon from '../Icons/plugIcon'
import GlobeIcon from '../Icons/globeIcon'

import './ContentBlock.css';


export default class MainContent extends React.PureComponent {

  icon = () => {
    switch(this.props.iconName) {
      case "PlugIcon" : return (<PlugIcon />);
      case "GlobeIcon" : return (<GlobeIcon />);
    }
  }

  render() {
    let {
      type,
      title,
      iconColor,
      iconName,
      openModal } = this.props



    return (
      <section className={`content-block ${type || ''}`}>
        {title &&
          <React.Fragment>
            <Header as='h3'>{title}</Header>

            {openModal &&
              <div className="header-icon">
                <InfoIcon
                  className="content-block-icon"
                  onClick={openModal}
                />
              </div>
            }
          </React.Fragment>
        }
        <div className="content">
          {iconName &&
            // 
            <div className="content-block-icon"> {this.icon()}</div>
          }
          <p className="main">
            {this.props.children}
          </p>
        </div>
      </section>
    )
  }
}
