import './PowerHoursInvitation.css';
import PowerHoursIcon from "../Icons/PowerHoursIcon";
import { NavLink, useNavigate } from 'react-router-dom';
import Analytics from '../../utils/Analytics';
import { useRef } from 'react';


export const PowerHoursInvitationCorner = ({ powerHours }) => {

	const navigate = useNavigate();

	const cornerRef = useRef();

	return (
		<div className="power-hours-invitation-corner">
			<div className="power-hours-invitation-corner-inner"
				ref={cornerRef}
				onClick={(event) => {
					const bounds = cornerRef.current.getBoundingClientRect();
					const clickX = event.clientX - bounds.x;
					const clickY = event.clientY - bounds.top;
					const clickedCorner = ((clickX + clickY) <= bounds.width);
					if (!clickedCorner) return;

					Analytics.event({
						category: "Power Hours",
						action: "Invitation Banner Click",
					});
					navigate("/powerhours");
				}}
			>
				<div>
					<b>Power Hours</b>
            		View Offer
				</div>				
				<div>
					<PowerHoursIcon color="white" />
				</div>				
			</div>
		</div>
	);

};

export const PowerHoursInvitationBanner = ({ powerHours }) => {

	const navigate = useNavigate();

	return (
		<div className="power-hours-invitation-banner"
			onClick={() => {
				Analytics.event({
					category: "Power Hours",
					action: "Invitation Banner Click",
				});
				navigate("/powerhours");
			}}
		>
			<div className='power-hours-icon-container'>
				<PowerHoursIcon color="white" />
			</div>				
			<div>
				<b>Power Hours</b>
				View Offer
			</div>				
		</div>
	);

};