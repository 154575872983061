import React from 'react';
export default function Icon({
    color = 'currentColor',
    height = '32px',
    width = height
}) { return (
    <svg
      height={height}
      width={width}
      viewBox="0 0 24 24"
      
      xmlns="http://www.w3.org/2000/svg">
      <path fill={color} d="M20 23V23.5C20.2761 23.5 20.5 23.2761 20.5 23H20ZM20.5 13C20.5 12.7239 20.2761 12.5 20 12.5C19.7239 12.5 19.5 12.7239 19.5 13H20.5ZM0.646447 12.6464C0.451184 12.8417 0.451184 13.1583 0.646447 13.3536C0.841709 13.5488 1.15829 13.5488 1.35355 13.3536L0.646447 12.6464ZM12 2L12.3536 1.64645C12.1583 1.45118 11.8417 1.45118 11.6464 1.64645L12 2ZM22.6464 13.3536C22.8417 13.5488 23.1583 13.5488 23.3536 13.3536C23.5488 13.1583 23.5488 12.8417 23.3536 12.6464L22.6464 13.3536ZM4 23H3.5C3.5 23.2761 3.72386 23.5 4 23.5V23ZM4.5 13C4.5 12.7239 4.27614 12.5 4 12.5C3.72386 12.5 3.5 12.7239 3.5 13H4.5ZM1 22.5C0.723858 22.5 0.5 22.7239 0.5 23C0.5 23.2761 0.723858 23.5 1 23.5V22.5ZM23 23.5C23.2761 23.5 23.5 23.2761 23.5 23C23.5 22.7239 23.2761 22.5 23 22.5V23.5ZM19.5 13V23H20.5V13H19.5ZM1.35355 13.3536L12.3536 2.35355L11.6464 1.64645L0.646447 12.6464L1.35355 13.3536ZM11.6464 2.35355L22.6464 13.3536L23.3536 12.6464L12.3536 1.64645L11.6464 2.35355ZM20 22.5H4V23.5H20V22.5ZM4.5 23V13H3.5V23H4.5ZM1 23.5H23V22.5H1V23.5Z" />
      <path fill={color} d="M14 15H14.5C14.5 14.7239 14.2761 14.5 14 14.5V15ZM10 15V14.5C9.72386 14.5 9.5 14.7239 9.5 15H10ZM9.5 20.3333C9.5 20.6095 9.72386 20.8333 10 20.8333C10.2761 20.8333 10.5 20.6095 10.5 20.3333H9.5ZM14.5 23V15H13.5V23H14.5ZM14 14.5H10V15.5H14V14.5ZM9.5 15V20.3333H10.5V15H9.5Z" />
    </svg>
)
}

