import React from 'react';
import {
  Image,
  Menu,
  Container,
  Icon,
} from 'semantic-ui-react';

import { withApp } from '../../contexts/App';
import { withRouter } from '../../utils/useRouter';
import Responsive from "../../components/Responsive/Responsive";
import logo from '../../static/aurora_plus_logo.svg';
import './Header.css';
import { NavLink } from 'react-router-dom';


class LoggedOutHeader extends React.Component {

  render() {
    let { pageTitle, showBackButton } = this.props.app

    return (
      <React.Fragment>

        {/* Mobile header */}
        <Responsive lessThan="computer">
			<Menu borderless className={`main-header logged-out-header mobile`}>
				<Container>
					<Menu.Item header as='h3'>
						{showBackButton &&
							<Icon name="chevron left" onClick={x => this.props.router.navigate(-1)} />
						}
						{pageTitle
							? pageTitle
							: <NavLink to="/">
								<Image src={logo} alt="logo" style={{ maxHeight: '2.5em' }} />
							</NavLink>
						}
					</Menu.Item>
				</Container>
			</Menu>
		</Responsive>

		{/* Desktop header */}
		<Responsive greaterThan="tablet">
			<Menu borderless className={`main-header logged-out-header desktop`}>
				<Container>
					<Menu.Item header as='h3'>
						{pageTitle
							? pageTitle
							: <NavLink to="/">
								<Image src={logo} alt="logo" style={{ maxHeight: '2.5em' }} />
							</NavLink>
						}
					</Menu.Item>
				</Container>
			</Menu>
		</Responsive>

      </React.Fragment>
    )
  }
}

export default withRouter(withApp(LoggedOutHeader))
