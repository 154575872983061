import React, { useState } from 'react';
import axios from 'axios';
import moment from 'moment-timezone';
import _ from 'lodash';
import {
  Button,
  Segment,
  Statistic,
  Header,
  Card,
  Loader
} from 'semantic-ui-react'
import { Link, NavLink } from 'react-router-dom';
import { TransitionGroup, CSSTransition } from 'react-transition-group';

import Responsive from "../../components/Responsive/Responsive";
import HomeIcon from '../../components/Icons/HomeIcon';
import InfoIcon from '../../components/Icons/InfoIcon';
import BillIcon from '../../components/Icons/BillIcon';
import SwitchIcon from '../../components/Icons/SwitchIcon';

import OffProductView from '../../components/OffProductView/OffProductView'
import {
  BalanceWidget,
  EstimatedDaysWidget,
  PostpayBillCard,
  PrepayBillCard,
  BillPredictionWidget,
  AvgBillWidget,
  PaymentExtensionWidget,
  PaymentPlanWidget,
  PaymentPlanInstallments,
  OverdueCardPostpay
} from './components/HomeScreenWidgets';
import { LastUpdateCard } from './components/SharedCards';

import Layout from '../../layouts/Layout';
import FullWidthLayout from '../../layouts/FullWidthLayout';
import { withRouter } from '../../utils/useRouter';
import { withApp } from '../../contexts/App'
import { withCustomer } from '../../contexts/Customer';
import { withTheme } from '../../contexts/Theme';
import Modal from './../../components/Modal';
import ExternalLink, { withExternalLink } from './../../components/ExternalLink';
import Urls from '../../utils/Urls';
import { openBill } from '../../utils/PDF';
import Log from '../../utils/Log';

import PaymentPlan, { PaymentPlanStatus } from '../../model/PaymentPlan';
import PaymentExtension from '../../model/PaymentExtension';
import Bill from '../../model/Bill';
import ActivePayment from '../../model/ActivePayment';
import PredictBill from '../../model/PredictBill';
import BillingDetails from '../../model/BillingDetails';

import { withAITracking } from '@microsoft/applicationinsights-react-js';
import { reactPlugin, appInsights } from '../../AppInsights';
import SplashScreenModal from '../../components/SplashScreenModal/SplashScreenModal';
import { PowerHoursInvitationCorner, PowerHoursInvitationBanner }  from '../../components/PowerHoursInvitation/PowerHoursInvitation';
import PowerHoursCard from '../../components/PowerHoursCard/PowerHoursCard';
import { prioritiseHomePagePowerHoursEvents } from '../../model/PowerHours';
import Analytics from '../../utils/Analytics';
import useClassifiedPremises from '../../hooks/useClassifiedPremises';

export class Home extends React.Component {

  state = {
    greeting: '',
    paymentExtension: null,
    activePayment: null,
    paymentPlan: null,
    loadingPaymentPlan: false,
    modalCurrentBalance: false,
    modalEstimatedDays: false,
    modalBillPrediction: false,
    modalAvgUsage: false,
    modalBillDue: false,
    modalBillOverDue: false,
    modalBillError: false,
    modalPaymentExtension: false,
    modalPaymentPlan: false,
    modalPaymentPlanAdvanced: false, 
    modalPaymentPlanUnusual: false,
    modalContactUs: false,
    lastRefreshTime: null,
    billing: null,
    loadingBilling: false
  }

  componentDidMount() {

	// Clear any leftover Journey from logging in
	this.props.app.set({
	  journey: null	
	});

    const now = new Date().getHours();
    let greeting = '';

    if (now < 12) {
      greeting += 'Morning';
    } else if (now < 17) {
      greeting += 'Afternoon';
    } else {
      greeting += 'Evening';
    }

    this.setState({greeting})


    this.handleWindowResize();
    window.addEventListener("resize", this.handleWindowResize);
    
	// Tiny delay to allow customer in state to update
	setTimeout(() => {

		var refreshing = this
			.props
			.customer
			.refresh();
	
		if (refreshing) {
			this.setState({ isLoading: true });

			refreshing.then(() => this.fetchAllDataRequired())
		}
	}, 1);
}

  componentDidUpdate(prevProps, prevState) {
    const { customerPremise: prevPremise } = prevProps.customer;
    const { customerPremise: premise, customer, premises, allPremises, hasLoaded } = this.props.customer;
	const { navigate } = this.props.router;

	if (hasLoaded && customer && customer.signedUpThroughInAppSignup &&
		premises && !premises.some(premise => (premise.isActive && premise.serviceAgreementStatus !== "Stopped"))) {
			// Newly created account via AIAS that has no active premises yet.
			// Show them the pending screen if applicable, or go back to signup
			// Users that already had a+ and have signed up again won't be redirected
			
			// Hide the demo while they are still in the signup flow
			this.props.app.setState({ appDemoOpen: false });
			if (allPremises.some(premise => premise.inAppSignupInProgress)) {
				navigate("/signup/pending", { replace: true });
			} else {
				navigate("/signup/property", { replace: true });
			}			
	}


    if (prevPremise && premise && (prevPremise.serviceAgreementID != premise.serviceAgreementID)) {
      this.fetchAllDataRequired();
    }

  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleWindowResize);
  }

  showMoreInstalments = () => {
    this.setState({
      displayNumberInstalments: this.state.displayNumberInstalments + 3
    });
  }

  fetchPaymentPlan = () => {
    const { customer, customerPremise: premise, pageConfig } = this.props.customer;
    
    if (!premise
      || customer.isFromCCB
      || !pageConfig.functionality.paymentPlan
      || !premise.hasActivePaymentPlans) {

      if (this.state.paymentPlan) {
        this.setState({ paymentPlan: null });
      }
      return;
    }

    this.setState({ loadingPaymentPlan: true });

    return axios
      .get(process.env.REACT_APP_API_BASE_URI + `payment-assistance/${premise.parentAccountID}/plans`)
      .then(res => {
        const plan = new PaymentPlan(res.data);

        this.setState({
          paymentPlan: plan.hasStarted ? plan : null,
          displayNumberInstalments: 3,
          loadingPaymentPlan: false
        });

      })
      .catch(err => {
        this.setState({
          paymentPlan: null,
          displayNumberInstalments: 3,
          loadingPaymentPlan: false
        });
      })
  }

  fetchPaymentExtension = () => {
    const { customer, customerPremise: premise, pageConfig } = this.props.customer;
    
    if (!premise
      || !pageConfig.functionality.paymentExtension
      || !premise.hasActivePaymentExtension) {
      
      if (this.state.paymentExtension) {
        this.setState({ paymentExtension: null });
      }
      return;
    }

    return axios
      .get(process.env.REACT_APP_API_BASE_URI + `payment-assistance/${premise.parentAccountID}/extensions`)
      .then(res => {
        const extension = new PaymentExtension(res.data);

        this.setState({
          paymentExtension: extension.isActive ? extension : null
        });
      })
      .catch(err => {
        this.setState({ paymentExtension: null });
      })
  }

  fetchActivePayment = () => {
    const { customer, customerPremise: premise, pageConfig } = this.props.customer;

    if (!premise) return;

    return axios
      .get(process.env.REACT_APP_API_BASE_URI + `payment/activepayment/${premise.parentAccountID}`)
      .then(res => {
        const activePayment = new ActivePayment(res.data);

        this.setState({
          activePayment: activePayment
        });
      })
      .catch(err => {
        this.setState({ activePayment: null });
      })
  }

  // this is called after plans and extensions have been fetched
  fetchBillingDetails = () => {
    const { customer, customerPremise: premise } = this.props.customer;
    
    if (!premise) {
      this.setState({ billing: null });
      return;
    }

    let billingInfo = {
      showContact: false,
      list: null
    };

    // deals with too many overdue bills (replaces all with a single card)
    if (premise.numberOfUnpaidBills > 2) {
      billingInfo.showContact = true;

      this.setState({ billing: billingInfo });
      return;
    }

    // HubCX marks bills as StatementAvailable=false or DateDue=null when there's an extension
    // CCB doesn't do this, so we assume it's the last bill every time
    const unpaidBillsWithoutExt = this.state.paymentExtension
      ? premise.numberOfUnpaidBills - 1
      : premise.numberOfUnpaidBills;

    if (unpaidBillsWithoutExt < 1) {
      this.setState({ billing: null });
      return;
    }

    // the possibility of either showing 2 bill cards - a combo of due/overdue/ext due cards. No more than 2 total
    return axios
      .get(process.env.REACT_APP_API_BASE_URI + `bills/${premise.parentAccountID}?parentAccountNumber=${premise.parentAccountNumber}&isOldCCB=${premise.serviceAgreementStatus === "Old_CCB"}`)
      .then(res => {
        let data = res.data;

        // mark the bill that has been replaced by a payment extension
        if (data.length > unpaidBillsWithoutExt) {
          data = data.slice(0, unpaidBillsWithoutExt);
        }

        billingInfo.list = new BillingDetails(data, customer.isFromCCB);
        
        this.setState({ billing: billingInfo });
      });
  }

  fetchAllDataRequired = () => {
    this.setState({
      isLoading: true,
      billing: null,
      paymentPlan: null,
      paymentExtension: null,
      activePayment: null
    });

	// Tiny delay to allow customer in state to update
	setTimeout(() => {
		// wait for all API requests to complete, before we return the promise
		return Promise
		.all([
			this.fetchPaymentPlan(),
			this.fetchPaymentExtension(),
			this.fetchActivePayment(),
		])
		.then(() => {
			// Short delay to ensure state from the previous 4 calls has updated
			setTimeout(() => {
				this.fetchBillingDetails()
			}, 1)
		})
		.then(() => this.setState({ isLoading: false }));
	}, 1);
  }

  handleWindowResize = () => {
    let isMobile = window.innerWidth < 768;
    if (isMobile !== this.state.isMobile) 
      this.setState({isMobile});
    }
  
  handleCloseModal = () => {
    this.setState({
      modalCurrentBalance: false,
      modalEstimatedDays: false,
      modalPeakOffPeakIndicator: false,
      modalBillPrediction: false,
      modalAvgUsage: false,
      modalBillDue: false,
      modalBillOverDue: false,
      modalBillError: false,
      modalPaymentExtension: false,
      modalPaymentPlan: false,
      modalPaymentPlanAdvanced: false,
      modalPaymentPlanUnusual: false,
      modalContactUs: false,
	  modalInProgressPremises: false,
    });
  }

  openModal = (modalId) => {
    this.setState({
      [modalId]: true
    });
  }

  getTimeOfUseString = (endDateString) => {
    const end = moment(endDateString);
    const isMonday = (end.day() === 1);
    const is7amOr8am = ((end.hour() === 7 || end.hour() === 8) && end.minute() === 0);

    // display 'Day' with string if on weekends (APAYGAPP-1191) if the end time is
    // monday 7am or 8am (daylight savings) we can assume its on a weekend
    if (isMonday && is7amOr8am) {
      return `until ${end.format('dddd [at] h:mm A')}`;
    } else {
      return `until ${end.format('h:mm A')}`;
    }
  }

  billClicked = billId => {
    const { customerPremise } = this.props.customer;
    const { isApp } = this.props.app;

    openBill(billId, customerPremise.parentAccountID, isApp , customerPremise.parentAccountNumber, customerPremise.serviceAgreementStatus).catch(() => {
      this.openModal('modalBillError');
    });
  }


  renderPowerHours(acceptedPowerHours, premises, animationTimer) {

	// If the card's internal timer has moved the card on to a state that
	// shouldn't be shown on the homepage, don't show anything here

	let card = <PowerHoursCard
		powerHours={acceptedPowerHours}
		premises={premises}
		condensed
		upcomingActiveOnly
		onClick={() => {
			Analytics.event({
				category: "Power Hours",
				action: "Power Hours Card Click",
			});
			this.props.router.navigate('/powerhours');
		}}
	/>

	return card ? <>
		<CSSTransition
			key="powerHours"
			timeout={animationTimer}
			classNames="card-animation"
		>			
			{ card }
		</CSSTransition>
	</> : null
  }
  
    SignupInProgressModal = () => {
        const { theme } = this.props.theme;
        return <Modal
            name="home/modal-in-progress-premises"
            size="tiny"
            open={this.state.modalInProgressPremises}
            onClose={this.handleCloseModal}
        >
            <Modal.Header>We are creating your aurora+ account</Modal.Header>
            <Modal.Content>
                <Modal.Description style={{ color: '#4a4a4a', textAlign: "left" }}>
                    <p>If you haven’t heard anything a week past this date please call us on <ExternalLink href='tel:1300132003'>1300 13 2003</ExternalLink> or</p>
                </Modal.Description>
            </Modal.Content>
            <Modal.Actions>
            <Button
                style={{ backgroundColor: theme.primary.backgroundColor }}
                secondary
                fluid
                onClick={() => {
                    this.props.externalLink('https://www.auroraenergy.com.au/general-enquiries-form');
                }}
            >Contact us here</Button>
            <Button
                fluid
                onClick={this.handleCloseModal}>OK</Button>
            </Modal.Actions>
        </Modal>
    }

  HomeLayout = (props) => {
    let accountPropName = (this.state.isMobile)
      ? 'afterAccountPicker'
      : 'beforeAccountPicker';
    
    let accountProps = {};
    if (props.isActive !== false) {
      accountProps[accountPropName] = (
		<div style={{
			position: 'relative',
		}}>
			{
				props.invitedPowerHours &&
					<Responsive lessThan="tablet">
						<PowerHoursInvitationCorner powerHours={props.invitedPowerHours} />
					</Responsive>
			}
			<Header
				textAlign="center"
				size={this.state.isMobile ? "small" : "large"}
				style={{
					fontFamily: 'Campton Book',
					color: 'white',
					fontWeight: 'normal',
					padding: '10px'
				}}
			>
				Good {props.greetingTime}, <b>{props.firstname.trim().split(" ")[0] || ""}</b>
			</Header>
		  </div>
      );
    }

    return (
      <Layout
        {...accountProps}
        layout={FullWidthLayout}
        hideBackButton
        pageTitle={null}
        onPullDownRefresh={() => {
          this.setState({ isLoading: true });

          this
            .props
            .customer
            .refresh()
            .then(() => this.fetchAllDataRequired())

        }}>
			{
				props.invitedPowerHours &&
					<Responsive greaterThan="mobile">
						<PowerHoursInvitationBanner powerHours={props.invitedPowerHours} />
					</Responsive>
			}

			{props.children}
		</Layout>
    );
  }

  
  PrePayCards = () => {
    const { SignupInProgressModal } = this;
    const {
      customer,
      customerPremise: premise,
	  premises,
	  powerHours,
      hasLoaded,
      lastRefreshTimeString,
      pageConfig } = this.props.customer;
    const {
      greeting,
      activePayment,
      paymentPlan,
      billing,
      paymentExtension } = this.state;
    const { theme } = this.props.theme;

    // prevents a flash of incorrect cards (need to give React time to call methods)
    if (!hasLoaded) return null;

    const animationTimer = 750;
    let cardDisplayCount = 0;

    const firstname = customer ? customer.firstName : '';
    const hasTimeOfUse = premise && premise.currentTimeOfUsePeriodEndDate;

    const billList = (billing && billing.list && billing.list.outStanding.length > 0) && billing.list;
    const noBills = (!billing || !billing.showContact)
      && (!billList || billList.totalAmountDue === 0)
      && !paymentExtension
      && !paymentPlan;

    const overdueBills = (billList && billList.overdue.length > 0) && billList.overdue;
    const dueBills = (billList && billList.due.length > 0) && billList.due;

    const hasOverduePaymentExtension = paymentExtension && paymentExtension.isOverdue;
    let multipleOverdueBills =
      (billing && billing.showContact) ||
      (hasOverduePaymentExtension && overdueBills) ||
      (overdueBills && overdueBills.length > 1);

    // payment plan overrides overdue bills
    if (paymentPlan) {
      multipleOverdueBills = false;
    }

    const comingDueInstalmentIndex = (paymentPlan
      && paymentPlan.upcomingInstalments.length > 0
      && paymentPlan.instalmentStatus(paymentPlan.upcomingInstalments[0]) === PaymentPlanStatus.DUE) ? 1 : 0;
    const paginatedPlanInstallments = paymentPlan
      && _.slice(paymentPlan.upcomingInstalments, comingDueInstalmentIndex, this.state.displayNumberInstalments + comingDueInstalmentIndex);
    const displayLoadMore = paymentPlan
      && paginatedPlanInstallments.length > 0
      && this.state.displayNumberInstalments < paymentPlan.upcomingInstalments.length;

    const isDirectDebitActive = activePayment && activePayment.isDirectDebitActive;
    const isAutoPaymentActive = activePayment && activePayment.isAutoPaymentActive;
    
    // display "view bill" or "overdue bill" cards
    let displayBill = (dueBills || overdueBills) && !multipleOverdueBills && !paymentPlan;
    
    // display "balance" and "days prepaid" cards
    const displayBalance = premise && !paymentPlan;

	const isResidential = pageConfig.productName == "residential";

	let invitedPowerHours = null;
	let acceptedPowerHours = null;
	if (powerHours && isResidential && !customer.isFromCCB) {
		[invitedPowerHours, acceptedPowerHours] = prioritiseHomePagePowerHoursEvents(powerHours, premise);
	}
	const displayPowerHoursCard = !!acceptedPowerHours;
	const displayPowerHoursInvitation = !!invitedPowerHours;

	const [, inProgressPremises, ] = useClassifiedPremises(this.props.customer.allPremises);

    // calculate number of cards to be displayed, so widths can be figured out (dynamically)
	cardDisplayCount += inProgressPremises.length;
    if (paymentPlan) cardDisplayCount += 2;
    if (!multipleOverdueBills && paymentExtension) cardDisplayCount++;
    if (multipleOverdueBills) cardDisplayCount++;
    if (displayBill && overdueBills) cardDisplayCount++;
    if (displayBill && dueBills) cardDisplayCount++;
    if (displayBalance) cardDisplayCount += 2;

    const evenNumberCards = cardDisplayCount % 2 === 0;
    const stillLoadingData = this.state.isLoading || this.state.loadingPaymentPlan;

    const { HomeLayout } = this

    if (stillLoadingData) {
      return (
        <HomeLayout firstname={firstname} greetingTime={greeting}>
          <Loader
            inverted
            active={stillLoadingData}
            size="medium"
            className="home-loader"
          />
        </HomeLayout>
      );
    }

    return (
      <HomeLayout firstname={firstname} greetingTime={greeting} invitedPowerHours={displayPowerHoursInvitation ? invitedPowerHours : null}>
        <Modal
          name="home/days-prepaid"
          size="tiny"
          open={this.state.modalEstimatedDays}
          onClose={this.handleCloseModal}>
          <Modal.Header>Days Prepaid</Modal.Header>
          <Modal.Content>
            <Modal.Description style={{ color: '#4a4a4a', textAlign: "left" }}>
              This is the number of days we estimate you have paid for in advance based on how
              much energy you have used in the past two weeks.
            </Modal.Description>
          </Modal.Content>
          <Modal.Actions>
            <Button
              style={{ backgroundColor: theme.primary.backgroundColor }}
              secondary
              fluid
              onClick={this.handleCloseModal}>OK</Button>
          </Modal.Actions>
        </Modal>
        
        <Modal
          name="home/balance"
          size="tiny"
          open={this.state.modalCurrentBalance}
          onClose={this.handleCloseModal}>
          <Modal.Header>Balance</Modal.Header>
          <Modal.Content>
            <Modal.Description style={{ color: '#4a4a4a', textAlign: "left" }}>
              This is made up of:
              <ul>
                <li>your account balance{premise && premise.balanceLastUpdatedDateDisplay ?
                  ` as of midnight on ${premise.balanceLastUpdatedDateDisplay}` : ` at midnight yesterday`},</li>
                <li>any billed amounts you may owe us,</li>
                <li>any in-app payments you have made, and</li>
                <li>how much we estimate you have used until now.</li>
              </ul>
            </Modal.Description>
            {isAutoPaymentActive &&
              <div style={{ justifyContent: 'center', display: 'flex' }}>
                <SwitchIcon style={{ marginRight: '10px', marginBottom: isDirectDebitActive ? '10px' : null }} color='#0060ae' />
                <b style={{ color: '#0060ae' }}>Auto payment is active</b>
              </div>
            }
            {isDirectDebitActive &&
              <div style={{ justifyContent: 'center', display: 'flex' }}>
                <SwitchIcon style={{ marginRight: '10px' }} color='#0060ae' />
                <b style={{ color: '#0060ae' }}>Direct Debit is active</b>
              </div>
            }
          </Modal.Content>
          <Modal.Actions>
            <Button
              style={{ backgroundColor: theme.primary.backgroundColor }}
              secondary
              fluid
              onClick={this.handleCloseModal}>OK</Button>
          </Modal.Actions>
        </Modal>

        <Modal
          name="home/peak-offpeak-indicator"
          size="tiny"
          open={this.state.modalPeakOffPeakIndicator}
          onClose={this.handleCloseModal}>
          <Modal.Header>Peak/Off-Peak</Modal.Header>
          <Modal.Content>
            <Modal.Description
              style={{
                color: '#4a4a4a',
                textAlign: "left"
              }}>Use less in Peak times and more in Off-Peak times to save money.</Modal.Description>
          </Modal.Content>
          <Modal.Actions>
            <Button
              style={{ backgroundColor: theme.primary.backgroundColor }}
              secondary
              fluid
              onClick={this.handleCloseModal}>OK</Button>
            <Button
              fluid as={ExternalLink}
              href={Urls.USAGE_LEARN_MORE_PDF}>Learn More</Button>
          </Modal.Actions>
        </Modal>

        <Modal
          name="home/bill-overdue"
          size="tiny"
          open={this.state.modalBillOverDue}
          onClose={this.handleCloseModal}>
          <Modal.Header>Bill overdue</Modal.Header>
          <Modal.Content>
            <Modal.Description
              style={{
                color: '#4a4a4a',
                textAlign: "left"
              }}>
              Uh oh! Your bill is now overdue for payment. Please pay the amount due, as stated on your bill, 
              to avoid any further late payment or interest charges.
            </Modal.Description>
          </Modal.Content>
          <Modal.Actions>
            <Button
              style={{ backgroundColor: theme.primary.backgroundColor }}
              secondary
              fluid
              onClick={this.handleCloseModal}>OK</Button>
          </Modal.Actions>
        </Modal>

        <Modal
          name="home/bill-due"
          size="tiny"
          open={this.state.modalBillDue}
          onClose={this.handleCloseModal}>
          <Modal.Header>Bill due</Modal.Header>
          <Modal.Content>
            <Modal.Description
              style={{
                color: '#4a4a4a',
                textAlign: "left"
              }}>
              Some of your usage charges have now become due for payment.
              Please pay the amount due, as stated on your bill, to avoid any late payment or interest charges.
            </Modal.Description>
          </Modal.Content>
          <Modal.Actions>
            <Button
              style={{ backgroundColor: theme.primary.backgroundColor }}
              secondary
              fluid
              onClick={this.handleCloseModal}>OK</Button>
          </Modal.Actions>
        </Modal>

        <Modal
          name="home/bill-error"
          size="tiny"
          open={this.state.modalBillError}
          onClose={this.handleCloseModal}>
          <Modal.Header>Oops!</Modal.Header>
          <Modal.Content>
            <Modal.Description style={{ color: '#4a4a4a', textAlign: "left" }}>
              We can't retrieve your statement right now. Please try again later or contact the Aurora Energy Team by clicking on Contact Us in your App menu.
            </Modal.Description>
          </Modal.Content>
          <Modal.Actions>
            <Button
              style={{ backgroundColor: theme.primary.backgroundColor }}
              secondary
              fluid
              onClick={this.handleCloseModal}>OK</Button>
          </Modal.Actions>
        </Modal>

        <Modal
          name="home/payment-extension"
          size="tiny"
          open={this.state.modalPaymentExtension}
          onClose={this.handleCloseModal}>
          <Modal.Header>{hasOverduePaymentExtension ? 'Payment extension expired' : 'Payment extension'}</Modal.Header>
          <Modal.Content>
            <Modal.Description style={{ color: '#4a4a4a', textAlign: "left" }}>
              {(hasOverduePaymentExtension)
                ? <p>Your payment extension has expired. Please pay the amount in full to avoid any further late payment and interest charges. If you are unable to pay please go the the <b style={{ color: '#0060AE' }}>payment help</b> screen or call us on <b style={{ color: '#0060AE' }}>1300 132 030</b></p>
                : <p>This shows your new due date for the oustanding bill amount. Note that late payment and interest charges will be incured if you fail to pay this amount in full by the extended date.</p>
              }
            </Modal.Description>
          </Modal.Content>
          <Modal.Actions>
            <Button
              style={{ backgroundColor: theme.primary.backgroundColor }}
              secondary
              fluid
              onClick={this.handleCloseModal}>OK</Button>
          </Modal.Actions>
        </Modal>

        <Modal
          name="home/payment-contact-us"
          size="tiny"
          open={this.state.modalContactUs}
          onClose={this.handleCloseModal}>
          <Modal.Header>Payment options to suit you</Modal.Header>
          <Modal.Content>
            <Modal.Description style={{ color: '#4a4a4a', textAlign: "left" }}>
              When you have more than one overdue amount owing, it's best if we discuss suitable
              payment help options with you before proceeding. Either call us or submit an online
              form with a brief note and an agent will be in contact to discuss a payment option to suit your circumstances.
            </Modal.Description>
          </Modal.Content>
          <Modal.Actions>
            <Button
              style={{ backgroundColor: theme.primary.backgroundColor }}
              secondary
              fluid
              onClick={() => {
                this.props.router.navigate('/contact/payments-help')
              }}>Contact us</Button>
            <Button
              fluid
              onClick={this.handleCloseModal}>OK</Button>
          </Modal.Actions>
        </Modal>

        <Modal
          name="home/payment-plan"
          size="tiny"
          open={this.state.modalPaymentPlan}
          onClose={this.handleCloseModal}>
          <Modal.Header>Your payment plan</Modal.Header>
          <Modal.Content>
            <Modal.Description style={{ color: '#4a4a4a', textAlign: "left" }}>
              <p>Your Home screen now reflects the payment plan terms which were agreed upon. Failure to pay these instalments by the dates outlined will result in a termination of the payment plan. If you have any questions regarding your payment plan please submit a request through the Contact Us page or call us on <b style={{ color: '#0060AE' }}>1300 132 030.</b></p>
            </Modal.Description>
          </Modal.Content>
          <Modal.Actions>
            <Button
              style={{ backgroundColor: theme.primary.backgroundColor }}
              secondary
              fluid
              onClick={() => {
                this.props.router.navigate('/contact/payments-help')
              }}>Contact us</Button>
            <Button
              fluid
              onClick={this.handleCloseModal}>OK</Button>
          </Modal.Actions>
        </Modal>

        <Modal
          name="home/payment-plan-advanced"
          size="tiny"
          open={this.state.modalPaymentPlanAdvanced}
          onClose={this.handleCloseModal}>
          <Modal.Header>You've paid in advance</Modal.Header>
          <Modal.Content>
            <Modal.Description style={{ color: '#4a4a4a', textAlign: "left" }}>
              <p>Any amounts paid above your agreed instalment amount will be automatically taken off your next instalment amount. For a closer look at what you’ve paid, see your transaction history.</p>
            </Modal.Description>
          </Modal.Content>
          <Modal.Actions>
            <Button
              style={{ backgroundColor: theme.primary.backgroundColor }}
              secondary
              fluid
              onClick={() => {
                this.props.router.navigate('/transactionhistory/transactions')
              }}>Transaction History</Button>
            <Button
              fluid
              onClick={this.handleCloseModal}>OK</Button>
          </Modal.Actions>
        </Modal>

        <Modal
          name="home/payment-plan-unusual"
          size="tiny"
          open={this.state.modalPaymentPlanUnusual}
          onClose={this.handleCloseModal}>
          <Modal.Header>You're ahead on this instalment</Modal.Header>
          <Modal.Content>
            <Modal.Description style={{ color: '#4a4a4a', textAlign: "left" }}>
              <p>Wondering why your instalment amount is less than usual? Amounts paid in advance are automatically taken off your next instalment. For a closer look at what you’ve paid, see your transaction history.</p>
            </Modal.Description>
          </Modal.Content>
          <Modal.Actions>
            <Button
              style={{ backgroundColor: theme.primary.backgroundColor }}
              secondary
              fluid
              onClick={() => {
                this.props.router.navigate('/transactionhistory/transactions')
              }}>Transaction History</Button>
            <Button
              fluid
              onClick={this.handleCloseModal}>OK</Button>
          </Modal.Actions>
        </Modal>

		<SplashScreenModal />

		{ displayPowerHoursCard && this.renderPowerHours(acceptedPowerHours, premises, animationTimer) }

        <SignupInProgressModal />


        <Card.Group as={TransitionGroup} stackable itemsPerRow={2}>

		  {inProgressPremises.map((premises) => {
			return <CSSTransition
		      key={"in-progress-" + premises.identifier}
			  timeout={animationTimer}
			  classNames="card-animation"
			>
			  <React.Fragment>
				{this.InProgressPremises(premises, customer, evenNumberCards)}
			  </React.Fragment>
			</CSSTransition>					
		  })}

          {paymentPlan &&
            <CSSTransition
              key="plan"
              timeout={animationTimer}
              classNames="card-animation"
            >
              <React.Fragment>
                <Card className="payment-plan">
                  <Card.Header textAlign="center">
                    Your payment plan
                    <InfoIcon
                      height="24px"
                      style={{ float: 'right' }}
                      onClick={() => this.openModal('modalPaymentPlan')}
                    />
                  </Card.Header>
                  <Card.Content
                    textAlign="center"
                    style={{
                      backgroundColor: theme.secondary.backgroundColor,
                      padding: '1em 2em'
                    }}>

                    <PaymentPlanWidget
                      plan={paymentPlan}
                      theme={theme}
                      history={this.props.router}
                      openModal={this.openModal}
                    >
                      <Responsive lessThan="computer">
                        <PaymentPlanInstallments
                          theme={theme}
                          style={{ marginTop: '15px' }}
                          onLoadMore={this.showMoreInstalments}
                          displayLoadMore={displayLoadMore}
                          paginatedList={paginatedPlanInstallments}
                        />
                      </Responsive>
                    </PaymentPlanWidget>

                  </Card.Content>
                </Card>

                <Card as={Responsive} greaterThan="tablet" className="payment-plan">
                  <Card.Header textAlign="center">
                    Your payment plan
                      <InfoIcon
                      height="24px"
                      style={{ float: 'right' }}
                      onClick={() => this.openModal('modalPaymentPlan')}
                    />
                  </Card.Header>
                  <Card.Content
                    textAlign="center"
                    style={{ backgroundColor: theme.secondary.backgroundColor }}>

                    <PaymentPlanInstallments
                      theme={theme}
                      onLoadMore={this.showMoreInstalments}
                      displayLoadMore={displayLoadMore}
                      paginatedList={paginatedPlanInstallments}
                    />

                  </Card.Content>
                </Card>

              </React.Fragment>
            </CSSTransition>
          } 

          {(!multipleOverdueBills && paymentExtension) &&
            <CSSTransition
              key="ext"
              timeout={animationTimer}
              classNames="card-animation"
            >
              <Card>
                <Card.Header textAlign="center">

                {hasOverduePaymentExtension ? 'Payment extension expired' : 'Payment extension'}
                  <InfoIcon
                    height="24px"
                    style={{ float: 'right' }}
                    onClick={() => this.openModal('modalPaymentExtension')}
                  />
                </Card.Header>
                <Card.Content
                  textAlign="center"
                  style={{
                    backgroundColor: hasOverduePaymentExtension ? '#FFEBEB' : theme.secondary.backgroundColor,
                    padding: '0 0 2em 0'
                  }}>

                  <PaymentExtensionWidget extension={paymentExtension} />

                  <Button
                    style={{ backgroundColor: theme.primary.backgroundColor }}
                    secondary
                    onClick={() =>
                      this
                        .props
                        .router
                        .navigate('/payment')
                    }>Pay now</Button>

                  <Link className="payment-plan-link" to="/payment-help" >Need even more time?</Link>
                </Card.Content>
              </Card>
            </CSSTransition>
          }

          {multipleOverdueBills &&
            <CSSTransition
              key="contact"
              timeout={animationTimer}
              classNames="card-animation"
            >
              <Card>
                <Card.Header textAlign="center">
                  Please contact us
                <InfoIcon height="24px" style={{ float: 'right' }} onClick={() => this.openModal('modalContactUs')} />
                </Card.Header>
                <Card.Content
                  textAlign="center"
                  style={{
                    backgroundColor: '#FFEBEB',
                    padding: '1em 2em 1.5em 2em'
                  }}>
                  <p style={{ color: theme.primary.color, paddingBottom: '1em' }}>
                    You have more than one overdue amount to pay. To discuss suitable payment options,{' '}
                    please submit a Payment Help request on the Contact us page or call us on <b>1300 132 030</b>.
                  </p>

                  <Button
                    style={{ backgroundColor: theme.primary.backgroundColor }}
                    secondary
                    onClick={() => {
                      this
                        .props
                        .router
                        .navigate('/contact/payments-help')
                    }}>Contact us</Button>
                </Card.Content>
              </Card>
            </CSSTransition>
          }

          {displayBill &&
            <CSSTransition
              key="bills"
              timeout={animationTimer}
              classNames="card-animation">

              <PrepayBillCard
                theme={theme}
                openModal={this.openModal}
                billClicked={this.billClicked}
                bills={billList}
              />

            </CSSTransition>
          }

          {(displayBalance && !this.state.loadingPaymentPlan) &&
            <CSSTransition
              key="balance"
              timeout={animationTimer}
              classNames="card-animation"
            >
              <React.Fragment>
              <Card>
                <Card.Header style={{ paddingLeft: (isAutoPaymentActive || isDirectDebitActive) ? '34px' : null }} textAlign="center">
                  Balance
                    <InfoIcon height="24px" style={{ float: 'right' }} onClick={() => this.openModal('modalCurrentBalance')} />
                  {(isAutoPaymentActive || isDirectDebitActive) &&
                    <SwitchIcon height="24px" style={{ float: 'right', marginRight: '10px' }} />
                  }
                  </Card.Header>
                  <Card.Content style={{ backgroundColor: theme.secondary.backgroundColor }} textAlign="center">

                    <BalanceWidget {...{ premise, hasLoaded }} />

                    <Button
                      style={{ backgroundColor: theme.primary.backgroundColor }}
                      secondary
                      onClick={() => {
                        this
                          .props
                          .router
                          .navigate('/payment')
                      }}>Top Up Now</Button>
                  </Card.Content>
                </Card>

                <Card>
                  <Card.Header textAlign="center">
                    Days Prepaid
                  <InfoIcon style={{ float: 'right' }} onClick={() => this.openModal('modalEstimatedDays')} />
                  </Card.Header>
                  <Card.Content style={{ backgroundColor: theme.secondary.backgroundColor }} textAlign="center">

                    <EstimatedDaysWidget {...{ premise, hasLoaded }} />

                    <Button
                      style={{ backgroundColor: theme.primary.backgroundColor }}
                      secondary
                      onClick={() => {
                        this
                          .props
                          .router
                          .navigate('/usage')
                      }}>View Usage</Button>
                  </Card.Content>
                </Card>
              </React.Fragment>
            </CSSTransition>
          }
        
          {hasTimeOfUse &&
            <CSSTransition
              key="timeofuse"
              timeout={animationTimer}
              classNames="card-animation"
            >
              <Card style={{
                width: evenNumberCards && '100%',
                height: ((!displayBalance || this.state.loadingPaymentPlan) && !evenNumberCards) && '211px',
                minHeight: '130px'
              }}>
                <Card.Header textAlign="center">
                  Current time period
                  <InfoIcon style={{ float: 'right' }} onClick={() => this.openModal('modalPeakOffPeakIndicator')} />
                </Card.Header>
                <Card.Content style={{ backgroundColor: theme.secondary.backgroundColor }} textAlign="center">
                  {premise && premise.currentTimeOfUsePeriodEndDate && (
                    <Statistic style={{ width: '100%' }} color='blue'>
                      <Statistic.Value
                        id="time-of-use-indicator-text"
                        style={{ textTransform: 'capitalize' }}>
                        {premise.currentTimeOfUseType && premise.currentTimeOfUseType.toLowerCase()}
                        <span style={{
                          fontSize: '24px',
                          lineHeight: '0px',
                          padding: '25px 0',
                          display: !evenNumberCards && 'block',
                        }}>
                          &nbsp;{this.getTimeOfUseString(premise.currentTimeOfUsePeriodEndDate)}
                        </span>
                      </Statistic.Value>
                    </Statistic>
                  )}
                </Card.Content>
              </Card>
            </CSSTransition>
          }

          <LastUpdateCard customer={this.props.customer} />

        </Card.Group>

      </HomeLayout>
    );
  }

  PostPayCards = (props) => {
    const { HomeLayout, SignupInProgressModal } = this
    const {
      customer,
      customerPremise: premise,
	  premises,
	  powerHours,
      hasLoaded,
      lastRefreshTimeString,
      pageConfig } = this.props.customer;
    const {
      greeting,
      activePayment,
      billing,
      paymentExtension,
      paymentPlan } = this.state;
    const { theme } = this.props.theme;

    // prevents a flash of incorrect cards (need to give React time to call methods)
    if (!hasLoaded) return null;

    const isDirectDebitActive = activePayment && activePayment.isDirectDebitActive;
    const isAutoPaymentActive = activePayment && activePayment.isAutoPaymentActive;

    const configData = pageConfig ? pageConfig.functionality : "";
    const firstname = customer ? customer.firstName : '';
    const hasTimeOfUse = premise && premise.currentTimeOfUsePeriodEndDate;

    const billList = (billing && billing.list && billing.list.outStanding.length > 0) && billing.list;
    const noBills = (!billing || !billing.showContact)
      && (!billList || billList.totalAmountDue === 0)
      && !paymentExtension
      && !paymentPlan;
    
    const overdueBills = (billList && billList.overdue.length > 0) && billList.overdue;
    const dueBills = (billList && billList.due.length > 0) && billList.due;

    const animationTimer = 750;
    let cardDisplayCount = 0;

    const hasOverduePaymentExtension = paymentExtension && paymentExtension.isOverdue;
    let multipleOverdueBills =
      (billing && billing.showContact) ||
      (hasOverduePaymentExtension && overdueBills) ||
      (overdueBills && overdueBills.length > 1);

    // payment plan overrides overdue bills
    if (paymentPlan) {
      multipleOverdueBills = false;
    }

    const comingDueInstalmentIndex = (paymentPlan
      && paymentPlan.upcomingInstalments.length > 0
      && paymentPlan.instalmentStatus(paymentPlan.upcomingInstalments[0]) === PaymentPlanStatus.DUE) ? 1 : 0; 
    const paginatedPlanInstallments = paymentPlan
      && _.slice(paymentPlan.upcomingInstalments, comingDueInstalmentIndex, this.state.displayNumberInstalments + comingDueInstalmentIndex);
    const displayLoadMore = paymentPlan
      && paginatedPlanInstallments.length > 0
      && this.state.displayNumberInstalments < paymentPlan.upcomingInstalments.length;

    // display "view bill" or "overdue bill" cards
    const displayBill = (dueBills || overdueBills || paymentExtension)
      && !multipleOverdueBills
      && !paymentPlan;

    const hasMultipleBills = displayBill && billList && billList.outStanding.length >= 2;

    const checkNextBillRun = premise && premise.billNextRun;
    const NoNextBillRun = checkNextBillRun == null ? true : false;

	const isResidential = pageConfig.productName == "residential";

	let invitedPowerHours = null;
	let acceptedPowerHours = null;
	if (powerHours && isResidential && !customer.isFromCCB) {
		[invitedPowerHours, acceptedPowerHours] = prioritiseHomePagePowerHoursEvents(powerHours, premise);
	}
	const displayPowerHoursCard = !!acceptedPowerHours;
	const displayPowerHoursInvitation = !!invitedPowerHours;


	// calculate number of cards to be displayed, so widths can be figured out (dynamically)
	const [, inProgressPremises, ] = useClassifiedPremises(this.props.customer.allPremises);

    // calculate number of cards to be displayed, so widths can be figured out (dynamically)
	cardDisplayCount += inProgressPremises.length;
    if (paymentPlan) cardDisplayCount += 2;
    if (multipleOverdueBills) cardDisplayCount++;
    //if (hasMultipleBills) cardDisplayCount++;
    if (displayBill && (overdueBills || dueBills)) cardDisplayCount++;
    if (!NoNextBillRun && !paymentPlan) cardDisplayCount++;

    const evenNumberCards = cardDisplayCount % 2 === 0;
    const stillLoadingData = this.state.isLoading || this.state.loadingPaymentPlan;

    const NoBillDue = () => (
      <React.Fragment>
        <Responsive as='p' lessThan="computer" id="home-no-bill-list" className="alternate">
          Nice work! You have no payments due.
        </Responsive>
        <Responsive as={Card} greaterThan="tablet">
          <Card.Header textAlign="center">
            Payment due
            <InfoIcon height="24px" style={{ float: 'right' }} onClick={() => this.openModal('modalCurrentBalance')} />
            {(isAutoPaymentActive || isDirectDebitActive) &&
              <SwitchIcon height="24px" style={{ float: 'right', marginRight: '10px' }} />
            }
          </Card.Header>
          <Card.Content style={{ backgroundColor: theme.quaternary.backgroundColor, padding: 0 }} textAlign="center">
            <Statistic style={{ width: '100%' }} color='blue'>
              <Statistic.Value>
                <p id='home-no-bill-list'>
                  Nice work! You have no payments due.
                </p>
              </Statistic.Value>
            </Statistic>
          </Card.Content>
        </Responsive>
      </React.Fragment>
    )

    const NoUsageData = () => (
      <Card>
        <Card.Header textAlign="center">
          Payment due
          <InfoIcon height="24px" style={{ float: 'right' }} onClick={() => this.openModal('modalCurrentBalance')} />
          {(isAutoPaymentActive || isDirectDebitActive) &&
            <SwitchIcon height="24px" style={{ float: 'right', marginRight: '10px' }} />
          }
        </Card.Header>
        <Card.Content style={{ backgroundColor: theme.quaternary.backgroundColor, padding: 0 }} textAlign="center">
          <Statistic style={{ width: '100%' }} color='blue'>
            <Statistic.Value>
              <p
                id='home-no-bill-list'
                style={{
                  background: theme.secondary.backgroundColor,
                  padding: '0.57em 1.6em'
                }}>
                We are just preparing your account and data. We'll have it ready for you soon.
              </p>
            </Statistic.Value>
          </Statistic>
        </Card.Content>
      </Card>
    )

    if (stillLoadingData) {
      return (
        <HomeLayout firstname={firstname} greetingTime={greeting}>
          <Loader
            inverted
            active={stillLoadingData}
            size="medium"
            className="home-loader"
          />
        </HomeLayout>
      );
    }

    return (
      <HomeLayout firstname={firstname} greetingTime={greeting} invitedPowerHours={displayPowerHoursInvitation ? invitedPowerHours : null}>
        <Modal
          name="home/balance"
          size="tiny"
          open={this.state.modalCurrentBalance}
          onClose={this.handleCloseModal}>
          <Modal.Header>Balance</Modal.Header>
          <Modal.Content>
            <Modal.Description
              style={{
                color: '#4a4a4a',
                textAlign: "left"
              }}>
              There is a late payment fee of $5 for accounts not paid in full by the
              fifth day past the due date. In addition, interest will
              be applied on all overdue accounts and is calculated from the due date.
            </Modal.Description>
            {isAutoPaymentActive &&
              <div style={{ justifyContent: 'center', display: 'flex' }}>
                <SwitchIcon style={{ marginRight: '10px', marginBottom: isDirectDebitActive ? '10px' : null }} color='#0060ae' />
                <b style={{ color: '#0060ae' }}>Auto payment is active</b>
              </div>
            }
            {isDirectDebitActive &&
              <div style={{ justifyContent: 'center', display: 'flex' }}>
                <SwitchIcon style={{ marginRight: '10px' }} color='#0060ae' />
                <b style={{ color: '#0060ae' }}>Direct Debit is active</b>
              </div>
            }
          </Modal.Content>
          <Modal.Actions>
            <Button
              style={{ backgroundColor: theme.primary.backgroundColor }}
              secondary
              fluid
              onClick={this.handleCloseModal}>OK</Button>
          </Modal.Actions>
        </Modal>

        <Modal
          name="home/bill-prediction"
          size="tiny"
          open={this.state.modalBillPrediction}
          onClose={this.handleCloseModal}>
          <Modal.Header>Your next bill</Modal.Header>
          <Modal.Content>
            <Modal.Description
              style={{
                color: '#4a4a4a',
                textAlign: "left"
              }}>
              This is the date you’ll receive your next bill. Your bill will be due around 14 days from this date.
            </Modal.Description>
          </Modal.Content>
          <Modal.Actions>
            <Button
              style={{ backgroundColor: theme.primary.backgroundColor }}
              secondary
              fluid
              onClick={this.handleCloseModal}>OK</Button>
          </Modal.Actions>
        </Modal>

        <Modal
          name="home/bill-prediction"
          size="tiny"
          open={this.state.modalAvgUsage}
          onClose={this.handleCloseModal}>
          <Modal.Header>Avg. Usage</Modal.Header>
          <Modal.Content>
            <Modal.Description
              style={{
                color: '#4a4a4a',
                textAlign: "left"
              }}>
              This shows your estimated Average Spend per day for your energy usage as of today.
            </Modal.Description>
          </Modal.Content>
          <Modal.Actions>
            <Button
              style={{ backgroundColor: theme.primary.backgroundColor }}
              secondary
              fluid
              onClick={this.handleCloseModal}>OK</Button>
          </Modal.Actions>
        </Modal>

        <Modal
          name="home/payment-contact-us"
          size="tiny"
          open={this.state.modalContactUs}
          onClose={this.handleCloseModal}>
          <Modal.Header>Payment options to suit you</Modal.Header>
          <Modal.Content>
            <Modal.Description style={{ color: '#4a4a4a', textAlign: "left" }}>
              When you have more than one overdue amount owing, it's best if we discuss suitable
              payment help options with you before proceeding. Either call us or submit an online
              form with a brief note and an agent will be in contact to discuss a payment option to suit your circumstances.
            </Modal.Description>
          </Modal.Content>
          <Modal.Actions>
            <Button
              style={{ backgroundColor: theme.primary.backgroundColor }}
              secondary
              fluid
              onClick={() => {
                this.props.router.navigate('/contact/payments-help')
              }}>Contact us</Button>
            <Button
              fluid
              onClick={this.handleCloseModal}>OK</Button>
          </Modal.Actions>
        </Modal>

        <Modal
          name="home/peak-offpeak-indicator"
          size="tiny"
          open={this.state.modalPeakOffPeakIndicator}
          onClose={this.handleCloseModal}>
          {/* Added for DPS-26. Need to determine if SMB or residential customer for displaying different model info*/}
          {pageConfig.productName == "residential" ?
          <Modal.Header>Peak/Off-Peak</Modal.Header> :
          <Modal.Header>Peak/Shoulder/Off-Peak</Modal.Header>
          }
          <Modal.Content>
            <Modal.Description
              style={{
                color: '#4a4a4a',
                textAlign: "left"
              }}>Use less in Peak times and more in Off-Peak times to save money.</Modal.Description>
          </Modal.Content>
          <Modal.Actions>
            <Button
              style={{ backgroundColor: theme.primary.backgroundColor }}
              secondary
              fluid
              onClick={this.handleCloseModal}>OK</Button>
            <Button
              fluid as={ExternalLink}
              href={pageConfig.productName == "residential" ? Urls.USAGE_LEARN_MORE_PDF : Urls.BUSINESS_USAGE_LEARN_MORE}>Learn More</Button>
          </Modal.Actions>
        </Modal>

        <Modal
          name="home/payment-plan"
          size="tiny"
          open={this.state.modalPaymentPlan}
          onClose={this.handleCloseModal}>
          <Modal.Header>Your payment plan</Modal.Header>
          <Modal.Content>
            <Modal.Description style={{ color: '#4a4a4a', textAlign: "left" }}>
              <p>Your Home screen now reflects the payment plan terms which were agreed upon. Failure to pay these instalments by the dates outlined will result in a termination of the payment plan. If you have any questions regarding your payment plan please submit a request through the Contact Us page or call us on <b style={{ color: '#0060AE' }}>1300 132 030.</b></p>
            </Modal.Description>
          </Modal.Content>
          <Modal.Actions>
            <Button
              style={{ backgroundColor: theme.primary.backgroundColor }}
              secondary
              fluid
              onClick={() => {
                this.props.router.navigate('/contact/payments-help')
              }}>Contact us</Button>
            <Button
              fluid
              onClick={this.handleCloseModal}>OK</Button>
          </Modal.Actions>
        </Modal>

        <Modal
          name="home/payment-plan-advanced"
          size="tiny"
          open={this.state.modalPaymentPlanAdvanced}
          onClose={this.handleCloseModal}>
          <Modal.Header>You've paid in advance</Modal.Header>
          <Modal.Content>
            <Modal.Description style={{ color: '#4a4a4a', textAlign: "left" }}>
              <p>Any amounts paid above your agreed instalment amount will be automatically taken off your next instalment amount. For a closer look at what you’ve paid, see your transaction history.</p>
            </Modal.Description>
          </Modal.Content>
          <Modal.Actions>
            <Button
              style={{ backgroundColor: theme.primary.backgroundColor }}
              secondary
              fluid
              onClick={() => {
                this.props.router.navigate('/transactionhistory/transactions')
              }}>Transaction History</Button>
            <Button
              fluid
              onClick={this.handleCloseModal}>OK</Button>
          </Modal.Actions>
        </Modal>

        <Modal
          name="home/payment-plan-unusual"
          size="tiny"
          open={this.state.modalPaymentPlanUnusual}
          onClose={this.handleCloseModal}>
          <Modal.Header>You're ahead on this instalment</Modal.Header>
          <Modal.Content>
            <Modal.Description style={{ color: '#4a4a4a', textAlign: "left" }}>
              <p>Wondering why your instalment amount is less than usual? Amounts paid in advance are automatically taken off your next instalment. For a closer look at what you’ve paid, see your transaction history.</p>
            </Modal.Description>
          </Modal.Content>
          <Modal.Actions>
            <Button
              style={{ backgroundColor: theme.primary.backgroundColor }}
              secondary
              fluid
              onClick={() => {
                this.props.router.navigate('/transactionhistory/transactions')
              }}>Transaction History</Button>
            <Button
              fluid
              onClick={this.handleCloseModal}>OK</Button>
          </Modal.Actions>
        </Modal>

		<SplashScreenModal />

		{ displayPowerHoursCard && this.renderPowerHours(acceptedPowerHours, premises, animationTimer) }
          
        <SignupInProgressModal />


        <Card.Group as={TransitionGroup} stackable itemsPerRow={2}>
		  {inProgressPremises.map((premises) => {
			return <CSSTransition
		      key="plan"
			  timeout={animationTimer}
			  classNames="card-animation"
			>
			  <React.Fragment>
				{this.InProgressPremises(premises, customer, evenNumberCards)}
			  </React.Fragment>
			</CSSTransition>					
		  })}

          {paymentPlan &&
            <CSSTransition
              key="plan"
              timeout={animationTimer}
              classNames="card-animation"
            >
              <React.Fragment>
                <Card className="payment-plan">
                  <Card.Header textAlign="center">
                    Your payment plan
                      <InfoIcon
                      height="24px"
                      style={{ float: 'right' }}
                      onClick={() => this.openModal('modalPaymentPlan')}
                    />
                  </Card.Header>
                  <Card.Content
                    textAlign="center"
                    style={{
                      backgroundColor: theme.secondary.backgroundColor,
                      padding: '1em 2em',
                      width: '100%'
                    }}>

                    <PaymentPlanWidget
                      plan={paymentPlan}
                      theme={theme}
                      history={this.props.router}
                      openModal={this.openModal}
                    >
                      <Responsive lessThan="computer">
                        <PaymentPlanInstallments
                          theme={theme}
                          style={{ marginTop: '15px' }}
                          onLoadMore={this.showMoreInstalments}
                          displayLoadMore={displayLoadMore}
                          paginatedList={paginatedPlanInstallments}
                        />
                      </Responsive>
                    </PaymentPlanWidget>

                  </Card.Content>
                </Card>

                <Card as={Responsive} greaterThan="tablet" className="payment-plan">
                  <Card.Header textAlign="center">
                    Your payment plan
                      <InfoIcon
                      height="24px"
                      style={{ float: 'right' }}
                      onClick={() => this.openModal('modalPaymentPlan')}
                    />
                  </Card.Header>
                  <Card.Content
                    textAlign="center"
                    style={{ backgroundColor: theme.secondary.backgroundColor }}>

                    <PaymentPlanInstallments
                      theme={theme}
                      onLoadMore={this.showMoreInstalments}
                      displayLoadMore={displayLoadMore}
                      paginatedList={paginatedPlanInstallments}
                    />

                  </Card.Content>
                </Card>
              </React.Fragment>
            </CSSTransition>
          }

          {multipleOverdueBills &&
            <CSSTransition
              key="contact"
              timeout={animationTimer}
              classNames="card-animation"
            >
              <div className='child_card'>
                <Card style={{ width: '100%' }}>
                  <Card.Header textAlign="center">
                    Please contact us
                    <InfoIcon height="24px" style={{ float: 'right' }} onClick={() => this.openModal('modalContactUs')} />
                  </Card.Header>
                  <Card.Content
                    textAlign="center"
                    style={{
                      backgroundColor: '#FFEBEB',
                      padding: '1em 2em 1.5em 2em'
                    }}>
                    <p style={{ color: theme.primary.color, paddingBottom: '1em' }}>
                      You have more than one overdue amount to pay. To discuss suitable payment options,{' '}
                      please submit a Payment Help request on the Contact us page or call us on <b>1300 132 030</b>.
                    </p>

                    <Button
                      style={{ backgroundColor: theme.primary.backgroundColor }}
                      secondary
                      onClick={() => {
                        this
                          .props
                          .router
                          .navigate('/contact/payments-help')
                      }}>Contact us</Button>
                  </Card.Content>
                </Card>
              </div>
            </CSSTransition>
          }

          {displayBill &&
            <CSSTransition
              key="bills"
              timeout={animationTimer}
              classNames="card-animation"
            >
              <div className='child_card'>

                <PostpayBillCard
                  {...this.state}
                  history={this.props.router}
                  pageConfig={pageConfig}
                  theme={theme}
                  bills={billList}
                  openModal={this.openModal}
                />

              </div>
            </CSSTransition>
          }


          {noBills &&
            <CSSTransition
              key="nobill"
              timeout={animationTimer}
              classNames="card-animation"
            >
              <NoBillDue />
            </CSSTransition>
          }

          
          {(NoNextBillRun) &&
            <div className='child_card'>

              <Card style={{
                width: '100%',
                height: !evenNumberCards && '211px'
              }}>

                <Card.Header textAlign="center">
                  You'll get your next bill on
                </Card.Header>
                <Card.Content style={{ backgroundColor: theme.secondary.backgroundColor }} textAlign="center">

                  <Statistic style={{ width: '100%' }} color='blue'>
                    <Statistic.Value>
                      <p id='home-no-bill-list'>
                        Your bill is being prepared. We'll have it ready for you soon.</p>
                    </Statistic.Value>
                  </Statistic>

                </Card.Content>

              </Card>
            </div>
          }

          {!NoNextBillRun &&
            <CSSTransition
              key="nextbill"
              timeout={animationTimer}
              classNames="card-animation"
            >
              <div className='child_card'>

                <Card style={{
                  width: '100%',
                  height: !evenNumberCards && '211px'
                }}>
                  <Card.Header textAlign="center">
                    You'll get your next bill on
                    <InfoIcon style={{ float: 'right' }} onClick={() => this.openModal('modalBillPrediction')} />
                  </Card.Header>
                  <Card.Content style={{ backgroundColor: theme.secondary.backgroundColor }} textAlign="center">

                    <BillPredictionWidget {...{ premise, hasLoaded }} />

                    {configData.predictABill && (
                      <Button
                        style={{ backgroundColor: theme.primary.backgroundColor }}
                        secondary
                        onClick={() => {
                          this
                            .props
                            .router
                            .navigate('/billprediction')
                        }}>See bill prediction</Button>
                    )}
                  </Card.Content>
                </Card>

                {(hasTimeOfUse && evenNumberCards) &&
                  <Card style={{
                    width: '100%',
                    height: !evenNumberCards && '211px',
                    minHeight: '130px'
                  }}>
                    <Card.Header textAlign="center">
                      Current time period
                  <InfoIcon style={{ float: 'right' }} onClick={() => this.openModal('modalPeakOffPeakIndicator')} />
                    </Card.Header>
                    <Card.Content style={{ backgroundColor: theme.quaternary.backgroundColor }} textAlign="center">
                      {premise && premise.currentTimeOfUsePeriodEndDate && (
                        <Statistic style={{ width: '100%' }} color='blue'>
                          <Statistic.Value
                            id="time-of-use-indicator-text"
                            style={{ textTransform: 'capitalize' }}>
                            {premise.currentTimeOfUseType && premise.currentTimeOfUseType.toLowerCase()}
                            <span style={{
                              fontSize: '24px',
                              lineHeight: '0px',
                              padding: hasMultipleBills ? '23px 0' : '25px 0',
                              display: !evenNumberCards || hasMultipleBills && 'block',
                            }}>
                              &nbsp;{this.getTimeOfUseString(premise.currentTimeOfUsePeriodEndDate)}
                            </span>
                          </Statistic.Value>
                        </Statistic>
                      )}
                    </Card.Content>
                  </Card>
                }

                {(hasMultipleBills || !hasTimeOfUse) &&
                  <LastUpdateCard customer={this.props.customer} />
                }

              </div>
            </CSSTransition>
          }
          
          {(hasTimeOfUse && !evenNumberCards) &&
            <CSSTransition
              key="timeofuse"
              timeout={animationTimer}
              classNames="card-animation"
            >
              <Card style={{
                width: evenNumberCards && '100%',
                height: !evenNumberCards && '211px',
                minHeight: '130px'
              }}>
                <Card.Header textAlign="center">
                  Current time period
                  <InfoIcon style={{ float: 'right' }} onClick={() => this.openModal('modalPeakOffPeakIndicator')} />
                </Card.Header>
                <Card.Content style={{  backgroundColor: theme.quaternary.backgroundColor }} textAlign="center">
                  {premise && premise.currentTimeOfUsePeriodEndDate && (
                    <Statistic style={{ width: '100%' }} color='blue'>
                      <Statistic.Value
                        id="time-of-use-indicator-text"
                        style={{ textTransform: 'capitalize' }}>
                        {premise.currentTimeOfUseType && premise.currentTimeOfUseType.toLowerCase()}
                        <span style={{
                          fontSize: '24px',
                          lineHeight: '0px',
                          padding: '25px 0',
                          display: !evenNumberCards && 'block',
                        }}>
                          &nbsp;{this.getTimeOfUseString(premise.currentTimeOfUsePeriodEndDate)}
                        </span>
                      </Statistic.Value>
                    </Statistic>
                  )}
                </Card.Content>
              </Card>
            </CSSTransition>
          }

          {(!hasMultipleBills && hasTimeOfUse) &&
            <CSSTransition
              key="lastupdated"
              timeout={animationTimer}
              classNames="card-animation"
            >
              <LastUpdateCard customer={this.props.customer} />
            </CSSTransition>
          }
        </Card.Group>

      </HomeLayout>
    );
  }

  InProgressPremises = (premises, customer, evenNumberCards) => {
	const { theme } = this.props.theme;

	return <Card className="payment-plan">
		<Card.Header textAlign="center">
			We are creating your aurora+ account
			<InfoIcon
				height="24px"
				style={{ float: 'right' }}
				onClick={() => this.openModal('modalInProgressPremises')}
			/>
		</Card.Header>
		<Card.Content
			textAlign="center"
			style={{
				backgroundColor: theme.secondary.backgroundColor,
				padding: '1em 0.5em',
				width: evenNumberCards && '100%',
				display: 'flex',
			}}
		>


			<div style={{
				display: 'flex',
				width: '100%',
			}}>
				<div style={{
					borderRight: '1px solid lightGrey',
					flex: '1 1 0',
					padding: '10px 10px',
				}}>
					<p style={{ color: '#4a4a4a', fontSize: '12px' }}>
						Address
					</p>
					<p style={{
						color: '#0060AE',
						fontSize: '12px',
						textAlign: 'left',
						display: 'inline-block',
					}} className="card-text-size">
						<span className="card-address">
							{
								premises.addressLines.map((line, index) => {
									return <React.Fragment key={index}>
										{(index !== 0) && <br />}
										{ line }
									</React.Fragment>
								})
							}
						</span>							
					</p>
				</div>
				<div style={{
					flex: '1 1 0',
					padding: '10px 10px',
				}}>
					{ (!premises.hasAdvancedMeter && (premises.inAppSignupLifeSupport || customer.lifeSupport)) ? <>
						<p style={{ color: '#4a4a4a', fontSize: '12px' }}>
							Life Support
						</p>
						<p style={{
							color: '#4a4a4a',
							fontSize: '12px',
							//textAlign: 'left',
							display: 'inline-block',
						}} className="card-text-size">
							<span
								style={{
									display: 'inline-block',
									maxWidth: '8em',
								}}
								className="card-life-support"
							>Someone will be in contact</span>
						</p>
					</> : <>
						<p style={{ color: '#4a4a4a', fontSize: '12px' }}>
							Approx. date
						</p>
						<p style={{ color: '#0060AE' }} className="card-text-size">
							{ premises.signupEstimate.date.format("D MMMM") }
						</p>
					</> }
				</div>
			</div>

		</Card.Content>
	</Card>

  }
  
  
  render() {
    const { HomeLayout, PrePayCards, PostPayCards } = this
    const { customerPremise: premise, pageConfig, customer: customer } = this.props.customer;
    const billingType = pageConfig ? pageConfig.functionality.billingType : "";

    //Case 604, added to check if a customer is meant to experience the off the product view (determined by API if all the SAs are inactive) but then to allow through a person with hub I and CCB A (fresh churn)
    if ((premise && premise.isActive) === false || (customer && customer.productLevel == "OFFPRODUCT")) {
        return (
        <HomeLayout isActive={false}>
          <OffProductView icon={(<HomeIcon height="160px"/>)}/>
        </HomeLayout>
      );
    }

    var hasPrePayBilling = (premise != null && premise.hasPrePayBilling);

    return (
      hasPrePayBilling
        ? <PrePayCards />
        : <PostPayCards />
    );
  }
}
 
const AITracking = withAITracking(reactPlugin, Home);
export default withExternalLink(withRouter(withApp(withCustomer(withTheme(AITracking)))));
