import React from 'react';
export default function Icon({
    color = 'currentColor',
    height = '32px',
    width = height
}) { return (
    <svg
      height={height}
      width={width}
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg">
      <path fill={color} d="M20 23V23.5C20.2761 23.5 20.5 23.2761 20.5 23H20ZM20.5 14C20.5 13.7239 20.2761 13.5 20 13.5C19.7239 13.5 19.5 13.7239 19.5 14H20.5ZM4 23H3.5C3.5 23.2761 3.72386 23.5 4 23.5V23ZM4.5 14C4.5 13.7239 4.27614 13.5 4 13.5C3.72386 13.5 3.5 13.7239 3.5 14H4.5ZM1 22.5C0.723858 22.5 0.5 22.7239 0.5 23C0.5 23.2761 0.723858 23.5 1 23.5V22.5ZM23 23.5C23.2761 23.5 23.5 23.2761 23.5 23C23.5 22.7239 23.2761 22.5 23 22.5V23.5ZM19.5 14V23H20.5V14H19.5ZM20 22.5H4V23.5H20V22.5ZM4.5 23V14H3.5V23H4.5ZM1 23.5H23V22.5H1V23.5Z" />
      <path fill={color} d="M11 15H11.5C11.5 14.7239 11.2761 14.5 11 14.5V15ZM7 15V14.5C6.72386 14.5 6.5 14.7239 6.5 15H7ZM6.5 20.3333C6.5 20.6095 6.72386 20.8333 7 20.8333C7.27614 20.8333 7.5 20.6095 7.5 20.3333H6.5ZM14 15V14.5C13.7239 14.5 13.5 14.7239 13.5 15H14ZM14 18H13.5C13.5 18.2761 13.7239 18.5 14 18.5V18ZM17 18V18.5C17.2761 18.5 17.5 18.2761 17.5 18H17ZM17 15H17.5C17.5 14.7239 17.2761 14.5 17 14.5V15ZM11.5 23V15H10.5V23H11.5ZM11 14.5H7V15.5H11V14.5ZM6.5 15V20.3333H7.5V15H6.5ZM13.5 15V18H14.5V15H13.5ZM14 18.5H17V17.5H14V18.5ZM17.5 18V15H16.5V18H17.5ZM17 14.5H14V15.5H17V14.5Z" />
      <path fill={color} d="M6.5 10C6.5 9.72386 6.27614 9.5 6 9.5C5.72386 9.5 5.5 9.72386 5.5 10H6.5ZM2 10L1.53184 9.82444C1.51078 9.88058 1.5 9.94004 1.5 10H2ZM10.5 10C10.5 9.72386 10.2761 9.5 10 9.5C9.72386 9.5 9.5 9.72386 9.5 10H10.5ZM14.5 10C14.5 9.72386 14.2761 9.5 14 9.5C13.7239 9.5 13.5 9.72386 13.5 10H14.5ZM18.5 10C18.5 9.72386 18.2761 9.5 18 9.5C17.7239 9.5 17.5 9.72386 17.5 10H18.5ZM22 10H22.5C22.5 9.94004 22.4892 9.88058 22.4682 9.82444L22 10ZM5 2V1.5C4.79158 1.5 4.60502 1.62929 4.53184 1.82444L5 2ZM19 2L19.4682 1.82444C19.395 1.62929 19.2084 1.5 19 1.5V2ZM5.5 10C5.5 10.8284 4.82843 11.5 4 11.5V12.5C5.38071 12.5 6.5 11.3807 6.5 10H5.5ZM4 11.5C3.17157 11.5 2.5 10.8284 2.5 10H1.5C1.5 11.3807 2.61929 12.5 4 12.5V11.5ZM9.5 10C9.5 10.8284 8.82843 11.5 8 11.5V12.5C9.38071 12.5 10.5 11.3807 10.5 10H9.5ZM8 11.5C7.17157 11.5 6.5 10.8284 6.5 10H5.5C5.5 11.3807 6.61929 12.5 8 12.5V11.5ZM13.5 10C13.5 10.8284 12.8284 11.5 12 11.5V12.5C13.3807 12.5 14.5 11.3807 14.5 10H13.5ZM12 11.5C11.1716 11.5 10.5 10.8284 10.5 10H9.5C9.5 11.3807 10.6193 12.5 12 12.5V11.5ZM17.5 10C17.5 10.8284 16.8284 11.5 16 11.5V12.5C17.3807 12.5 18.5 11.3807 18.5 10H17.5ZM16 11.5C15.1716 11.5 14.5 10.8284 14.5 10H13.5C13.5 11.3807 14.6193 12.5 16 12.5V11.5ZM21.5 10C21.5 10.8284 20.8284 11.5 20 11.5V12.5C21.3807 12.5 22.5 11.3807 22.5 10H21.5ZM20 11.5C19.1716 11.5 18.5 10.8284 18.5 10H17.5C17.5 11.3807 18.6193 12.5 20 12.5V11.5ZM2.46816 10.1756L5.46816 2.17556L4.53184 1.82444L1.53184 9.82444L2.46816 10.1756ZM18.5318 2.17556L21.5318 10.1756L22.4682 9.82444L19.4682 1.82444L18.5318 2.17556ZM19 1.5H5V2.5H19V1.5Z" />
    </svg>
)
}

