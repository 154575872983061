import React from 'react'
import { Grid } from 'semantic-ui-react';
import ContentBlock from '../../../components/ContentBlock/ContentBlock'
import DOMPurify from 'dompurify';


function InsightsAndTips(props) {
  let { insight, tip, hasSolar, bordered, margin, openModal } = props;
  // console.log(insight, tip);
  if (!insight) return null;

  let insightMsg = "",
    IsGood = false;

  const toDollarString = val => ('$' + Math.abs(val).toLocaleString('en-AU', { currency: 'AUD', minimumFractionDigits: 2 }));
  const displayMsg = {
    positiveSolar: `You have earned an extra ${toDollarString(Number(parseFloat(insight.solar).toFixed(2)))} solar credit in this week compared to the previous week.`,
    negativeSolar: `You have earned ${toDollarString(Number(parseFloat(insight.solar).toFixed(2)))} less solar credit in this week compared to the previous week.`,
    positiveElec: `You have used ${toDollarString(Number(parseFloat(insight.elec).toFixed(2)))} less electricity in this week compared to the previous week.`,
    negativeElec: `You have used ${toDollarString(Number(parseFloat(insight.elec).toFixed(2)))} more electricity in this week compared to the previous week.`
  };

  if (hasSolar) {
    IsGood = insight.solar <= 0;
    insightMsg = IsGood ? displayMsg.positiveSolar : displayMsg.negativeSolar;
  }
  else {
    IsGood = insight.elec <= 0;
    insightMsg = IsGood ? displayMsg.positiveElec : displayMsg.negativeElec;
  }
  
  return (
    <Grid
      className={bordered ? 'bordered top thin' : ''}
      style={{ margin: margin || '' }}
      stackable
    >
      <Grid.Column width={8}>
        {insightMsg &&
          <ContentBlock
            title="Key Insights"
            iconName="PlugIcon"
            iconColor="blue"
            openModal={() => openModal(hasSolar ? "keySolarInsights" : "keyInsights")}
          >
            {insightMsg}
          </ContentBlock>
        }
      </Grid.Column>

      {tip && tip.Description &&
        <Grid.Column width={8}>
          <ContentBlock
            title="Energy Tips Insights"
            iconName="GlobeIcon"
            iconColor="orange"
            openModal={() => openModal("energyTips")}
          >
            {DOMPurify.sanitize(tip.Description)}
          </ContentBlock>
        </Grid.Column>
      }

    </Grid>
  )
}

export default InsightsAndTips
