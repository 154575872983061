import moment from 'moment-timezone';
import { BILLING_TYPE } from '../utils/Types';
import { addBusinessDays } from '../utils/Moment';

const stateCodes = ["ACT", "NSW", "NT", "QLD", "SA", "TAS", "VIC", "WA"];

export default class Premise {

  constructor(premiseData) {
    this.IsFromCCB = premiseData.IsFromCCB;
    this.address = premiseData.Address;
	this.formattedAddress = this.address ? this.address.split(" ").map(a => (stateCodes.indexOf(a) >= 0) ? a : a.charAt(0) + a.slice(1).toLowerCase()).join(" ") : "";
    this.postCode = premiseData.PostCode;
    this.parentAccountID = premiseData.ParentAccountID;
    this.parentAccountNumber = premiseData.ParentAccountNumber;
    this.parentAccountNumberCheckDigit = premiseData.ParentAccountNumberCheckDigit;
    this.serviceAgreementID = premiseData.ServiceAgreementID;
    this.serviceAgreementStatus = premiseData.ServiceAgreementStatus;
    this.serviceAgreementType = premiseData.ServiceAgreementType ? premiseData.ServiceAgreementType.toLowerCase() : "";
    this.actualBalance = premiseData.ActualBalance;
    this.estimatedBalance = premiseData.EstimatedBalance;
    this.averageDailyUsage = premiseData.AverageDailyUsage;
    this.usageDaysRemaining = premiseData.UsageDaysRemaining;
    this.hasSolar = premiseData.HasSolar;
    this.isActive = premiseData.IsActive;
    this.hasConcessions = premiseData.HasConcessionsApplied;
    this.currentTimeOfUsePeriodEndDate = premiseData.CurrentTimeOfUsePeriodEndDate;
    this.serviceAgreementStartDate = premiseData.ServiceAgreementStartDate;
    this.suburb = premiseData.Suburb;
	this.hasPAYGPlus = premiseData.HasPAYGPlus;
    this.hasActivePaymentExtension = premiseData.HasActivePaymentExtension;
    this.hasActivePaymentPlans = premiseData.HasActivePaymentPlans;
    this.numberOfUnpaidBills = premiseData.NumberOfUnpaidBills;
    this.currentAppExperience = premiseData.CurrentAppExperience;
	this.inAppSignupInProgress = premiseData.Status === "Sent";
	this.inAppSignupTime = premiseData.SubmissionTime;
	this.inAppSignupLifeSupport = premiseData.LifeSupport === "yes";
  this.hasInterimStatus = premiseData.HasInterimStatus;
  this.hasOnProductStatus = premiseData.HasOnProductStatus;
  this.hasOffProductStatus = premiseData.HasOffProductStatus;
  this.billTo = premiseData.BillTo;
  this.billNextTo = premiseData.BillNextTo;
  this.billNextRun = premiseData.BillNextRun;
  this.InvoiceID = premiseData.BillId;


    switch (premiseData.CurrentTimeOfUseType) {
      case 'OFFPEAK':
        this.currentTimeOfUseType = 'Off-Peak';
        break;
      case 'PEAK':
        this.currentTimeOfUseType = 'Peak';
        break;
      default:
        this.currentTimeOfUseType = premiseData.CurrentTimeOfUseType;
        break;
    }

	let hasAdvancedMeter = undefined;
	if (premiseData.Meters) {
		hasAdvancedMeter = false;
		premiseData.Meters.forEach((meter) => {
			switch(meter.MeterType) {
				case "COMM4":
				case "COMMS4C":
				case "COMMS4D":
					hasAdvancedMeter = true;
					break;
			}
		})
	}
	this.hasAdvancedMeter = hasAdvancedMeter;

    //Checks if last update is null
    const nullList = [null, "0001-01-01T00:00:00"];
    this.balanceLastUpdatedDate = (nullList.includes(premiseData.BalanceLastUpdatedDate))
      ? null
      : new Date(premiseData.BalanceLastUpdatedDate)

    this.identifier = this.serviceAgreementID

  }

  get hasPrePayBilling() {
    return this.serviceAgreementType === 'residential' &&
      this.currentAppExperience === BILLING_TYPE.PAYG;
  }

  get billingType() {
    return this.hasPrePayBilling
      ? BILLING_TYPE.PAYG
      : BILLING_TYPE.POSTPAID;
  }

  get estimatedDaysDisplayValue() {
    return this.usageDaysRemaining;
  }

  get currentBalanceDisplay() {
    let currentBalanceDisplay = this.estimatedBalance;

    if (currentBalanceDisplay !== null) {
      currentBalanceDisplay = Math
        .abs(this.estimatedBalance)
        .toLocaleString('en-AU', {
          currency: 'AUD',
          minimumFractionDigits: 2,
          maximumFractionDigits: 2
        });
    }

    return currentBalanceDisplay;
  }

  get isBalanceNegative() {
    return this.estimatedBalance < 0;
  }

  get balanceLastUpdatedDateDisplay() {
    if (this.balanceLastUpdatedDate === null) 
      return '';
    
    return moment(this.balanceLastUpdatedDate)
      .subtract(this.IsFromCCB ? 1 : 0, "days")
      .format("D/M/YYYY")
  }

  get addressLines() {
  let address = lowerCaseAddress(this.address);
	let suburb = lowerCaseAddress(this.suburb);
  let suburbIndex = address.lastIndexOf(suburb)
  let street = address.slice(0, suburbIndex).trim();
	let postCode = this.postCode;
	let state = (address.split(postCode).concat(""))[1].trim();
	let country = "Australia";

	if (state && (state.toLowerCase() == "tas")) state = "Tasmania";

	return [
		street + ",",
		(suburb + " " + state).trim(),
		(country + " " + postCode).trim(),
	];	
  }

  get signupEstimate() {
	const signupDate = this.inAppSignupTime;
	
  const now = new moment();
	const signupMoment = signupDate ? new moment(signupDate) : new moment();
	const completionMoment = addBusinessDays(signupMoment, this.hasAdvancedMeter ? 7 : (15 + 7))
  	
	const progress = Math.max(0, Math.min(1, now.diff(signupMoment, 'hours') / completionMoment.diff(signupMoment, 'hours')));

	return { progress, date: completionMoment };
  }


  //sort by postcode
  postCodeCompare(b) {

    if (this.postCode === b.postCode) return 0;
    if (this.postCode < b.postCode) return -1;

    return 1;
  }

  //sort premises by active/inactive
  serviceAgreementStatusCompare(b) {
    let activeStatuses = ['Active', 'Pending Stop', 'Pending Start'];

    if (this.serviceAgreementStatus === b.serviceAgreementStatus) {
      return 0;
    }

    const thisIsActive = activeStatuses.indexOf(this.serviceAgreementStatus) > -1;
    const bIsActive = activeStatuses.indexOf(b.serviceAgreementStatus) > -1;
    
    return (thisIsActive && !bIsActive
      ? -1
      : 1);
  }
}

// Taken from AccountPicker
function lowerCaseAddress(address) {
  if (!address) return address;
	//Regex matches comma and optionaly all following spaces
	address = address.split(/,\s*/).join(", ").toUpperCase()
	address = address.split(" ").map(a => a.charAt(0) + a.slice(1).toLowerCase()).join(" ")
	return address;
}
