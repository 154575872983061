import React from 'react';
import { NavLink } from 'react-router-dom';
import { Menu } from 'semantic-ui-react';
import { withRouter } from '../../utils/useRouter';
import { withCustomer } from '../../contexts/Customer';
import { withTheme } from '../../contexts/Theme';
import { iconSearch } from '../../utils/IconsMap';
import HomeIcon from '../Icons/HomeIcon';
import BusinessIcon from '../Icons/BusinessIcon';
import { PRODUCT_TYPE } from '../../utils/Types';

import './Navigation.css'


export class Navigation extends React.Component {

  NavListItems = () => {
    const { customer, pageConfig } = this.props.customer
    const { theme } = this.props.theme

    if (!pageConfig || !pageConfig.menuItems)
      return null;

    return pageConfig.menuItems.navigationMenu
      .sort((a, b) => a.order - b.order)
      .map((navItem) => {
        
        // add any menu customizations that we need here
        // try to avoid doing this if possible
        if (navItem.icon === "NotificationIcon") {
          navItem.count = customer && customer.unreadNotificationCount;
        }
        else if (this.props.hasSolar && navItem.icon === "UsageIcon") {
          navItem.icon = "SunIcon";
        }

        // then return all menu items, with a unique key and icon
        return (
          <Menu.Item key={navItem.order} style={{ color: theme.primary.color }} as={NavLink} to={navItem.url}>
            {iconSearch(navItem.icon, { count: navItem.count })}
          </Menu.Item>
        );

      });
  }

  render() {
    const { theme } = this.props.theme
    const { pageConfig } = this.props.customer
    const isBusiness = pageConfig && pageConfig.productName === PRODUCT_TYPE.BUSINESS;
    
    return (
      <Menu
        fluid
        widths={4}
        fixed="bottom"
        className="mobile-navigation"
      >
        <Menu.Item style={{ color: theme.primary.color }} as={NavLink} to="/">
          {isBusiness 
            ? <BusinessIcon />
            : <HomeIcon />
          }
        </Menu.Item>

        {this.NavListItems()}

      </Menu>
    );
  }
}

export default withRouter(withCustomer(withTheme(Navigation)));
