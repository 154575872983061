import React from 'react';
import { Card, Statistic, Button } from 'semantic-ui-react';
import moment from 'moment-timezone';
import _ from 'lodash';

import Modal from './../Modal';
import InfoIcon from '../Icons/InfoIcon';
import './Prediction.css';

const DollarValue = ({ value, plain }) => {
  value = value || 0;
  var s = Math.abs(Number(value)).toFixed(2);
  return <>{plain ? "$" : <sup style={{ fontSize: '55%' }}>$</sup>}{s}{(value < 0 && !plain) && <span style={{ fontSize: '50%', lineHeight: 1 }}>CR</span>}</>
}

export const PredictedAmount = (props) => {
  const themeColour = props.theme ? props.theme.primary.color : '';
  const themeBGColour = props.theme ? props.theme.quaternary.backgroundColor : '';
  const noData = "We are just preparing your account and data. We'll have it ready for you soon."; 
  const noBillYet = "Bill prediction information will be available after your next bill is issued. Please check back after then."; //APAYGDEV-677
  const noBillingGroup = "Bill prediction information is not currently available. Please check back soon."; //APAYGDEV-697

  const prediction = !props.noData ? props.predictedAmount : 0;

  var useT22Modal = props.tariffs && ((props.tariffs.indexOf("T22") >= 0) || (props.tariffs.length === 0));


  //Display stub card if the customer hasn't got a bill yet or their bill has no biling group APAYGDEV677 & 697
  if (props.InvoiceID==0 || props.billNextRun == null){
   return (
    <Card className="hasNoBill-Card">
    <Card.Header
        textAlign="center"
        style={{ color: themeColour }}
      >
        Predicted charges for your next bill

   </Card.Header>

    <Card.Content textAlign="center" style={{ backgroundColor: themeBGColour, padding: '1.6rem 1.4rem 1.6rem'}}>
      <p style={{ fontSize: '1.5rem', color: themeColour }}>
            {props.InvoiceID==0 ? noBillYet : noBillingGroup}</p>
    </Card.Content>
  </Card>
   );
  }


  
  return (
    <Card>
      <Card.Header
        textAlign="center"
        style={{ color: themeColour }}
      >
        Predicted charges for your next bill

        <InfoIcon
          height="24px"
          style={{ float: 'right' }}
          onClick={() => props.openModal(useT22Modal ? 'yourBillPredictionT22' : 'yourBillPrediction')} />
      </Card.Header>
      <Card.Content textAlign="center" style={{backgroundColor: themeBGColour}}>
        {props.noData
          ? noData
          :
            <Statistic style={{ width: '100%' }}>
              <Statistic.Value style={{ color: themeColour}}>
                 <DollarValue value={prediction}/>
              </Statistic.Value>
            </Statistic>
        }
      </Card.Content>
    </Card>
  );
}

export const CurrentBillingPeriod = (props) => {
  if (props.noData) return null;

  const themeColour = props.theme ? props.theme.primary.color : '';
  const themeBGColour = props.theme ? props.theme.quaternary.backgroundColor : '';
  const progress = (props.totalDays == 0)
    ? 0
    : 1 - props.daysRemaining / props.totalDays;

  // limit where we place the "Today" marker
  const billProgressPercent = _.clamp(progress, 0.0, 1.0);
  const widthPadding = 40;
  const height = props.height || 100,
    width = props.width || 345,
    linePositionY = 40,
    curentDateWidth = 45 + 50; // half the width + adjustment for inner svg elements
  const widthTotal = width + (widthPadding * 2);

  const nextBillDue = props.billNextTo
    ? props.billNextTo
    : moment(props.startDate).add(props.totalDays, 'days');


  //Don't show the card if the customer doesn't have a bill yet or the bill doesn't have a valid Billing group assigned APAYGDEV-667 & 697
  if (props.InvoiceID==0 || props.billNextRun == null){
    return false
    
   }
  // don't show the SVG in the 5-day waiting period, instead display a message
  if (props.daysRemaining < 1) {
    return (
      <Card>
        <Card.Header textAlign="center" style={{ color: themeColour }}>
          Current billing period
        <InfoIcon
            height="24px"
            style={{ float: 'right' }}
            onClick={() => props.openModal('currentBillingPeriod')} />
        </Card.Header>
        <Card.Content textAlign="center" style={{ backgroundColor: themeBGColour, padding: '1.6rem 1.4rem 1.6rem'}}>
          <p style={{ fontSize: '1.5rem', color: themeColour }}>
            Your bill is currently being prepared. This can take approximately 5-7 days.
          </p>
        </Card.Content>
      </Card>
    );
  }


  return (
    <Card>
      <Card.Header textAlign="center" style={{ color: themeColour }}>
        Current billing period

        <InfoIcon
          height="24px"
          style={{ float: 'right' }}
          onClick={() => props.openModal('currentBillingPeriod')} />
      </Card.Header>
      <Card.Content
        textAlign="center"
        className="no-padding"
        style={{ backgroundColor: themeBGColour }}
      >
        <div
          className="top-section"
          style={{ borderBottom: '1px solid #A1B0C4', padding: '2em .5em' }}
        >

          <svg
            width={widthTotal}
            height={height}
            viewBox={`0 0 ${widthTotal} ${height}`}
            fill="none"
            xmlns="http://www.w3.org/2000/svg">

            <path
              id="postDate-line"
              d={`M${widthPadding} ${height - linePositionY} L${widthPadding + width} ${height - linePositionY}`}
              stroke="#86CBDF"
              strokeWidth="10"
              strokeMiterlimit="11.4737"
              strokeLinecap="round"
              strokeDasharray="2 15"></path>
            <path
              id="preDate-line"
              d={`M${widthPadding} ${height - linePositionY} L${widthPadding + (width * billProgressPercent)} ${height - linePositionY}`}
              stroke={themeColour}
              strokeWidth="10"
              strokeMiterlimit="11.4737"
              strokeLinecap="round"></path>

            <g
              id="currentDate-group"
              transform={`translate(${widthPadding + (width * billProgressPercent) - curentDateWidth} ${-linePositionY - 17})`}
            >
              <path stroke={themeColour} id="currentDate-line" d="M93 97 L93 112" strokeWidth="2" strokeLinecap="round"></path>
              <path fill={themeColour} d="M58 76.1054C58 67.2107 65.2107 60 74.1054 60H111.895C120.789 60 128 67.2107 128 76.1054C128 85.0002 120.789 92.2109 111.895 92.2109H74.1054C65.2106 92.2109 58 85.0002 58 76.1054Z"></path>
              <text x="73" y="81" fill="white" style={{ fontSize: '13.3px', lineHeight: '15px' }}>
                Today
              </text>
              <circle stroke={themeColour} id="currentDate-spot" cx="93" cy="117" r="7" fill="white" strokeWidth="2"></circle>
            </g>

            <text x={widthPadding - 5} y={height - 2} className="styledText" color={themeColour}>
              {moment(props.startDate).format('DD MMM')}
            </text>

            <text x={widthPadding + (width - 50)} y={height - 2} className="styledText" color={themeColour}>
              {moment(nextBillDue).format('DD MMM')}
            </text>
          </svg>
          
        </div>

        <div className="left-section" style={{ width: '50%', float: 'left', padding: '1.5em .5em' }}>
          <Statistic style={{ width: '100%' }} style={{ color: themeColour }}>
            <Statistic.Value style={{ color: themeColour }}>
              {Math.max(0, props.daysRemaining)}
            </Statistic.Value>
          </Statistic>
          <p>Days remaining</p>
        </div>

        <div className="right-section">
          <Statistic style={{ width: '100%' }} style={{ color: themeColour, whiteSpace: 'no-wrap' }}>
            <Statistic.Value style={{ color: themeColour }}>
              <DollarValue value={props.dailyCost}/>
            </Statistic.Value>
          </Statistic>
          <p>Avg $/day</p>
        </div>
      </Card.Content>
    </Card>
  );
}

export const ChargesSoFar = (props) => {
  const theme = props.theme ? props.theme.primary : {};
  const themeBGColour = props.theme ? props.theme.quaternary.backgroundColor : '';
  // don't show this in the 5-day waiting period
  if (!props.noData && props.daysRemaining < 1 || props.billNextRun == null )
    return null;

  const dateStr = props.lastUpated ? moment(props.lastUpated).format("DD/M/YYYY") : "";

  const startStr = props.billTo ? moment(props.billTo).format("DD/M/YYYY") : "";
  if (props.InvoiceID==0){
    return false
  }
  return (
    <React.Fragment>
      <Modal
        name="prediction/currentCharges"
        size="tiny"
        open={props.open}
        onClose={props.handleCloseModal}>
        <Modal.Header>Current charges as of today</Modal.Header>
        <Modal.Content>
          <Modal.Description style={{ color: '#4a4a4a', textAlign: "left" }}>
          <p>This balance is based on how much we estimate you have used {props.billTo ? " (from your last Bill on " + startStr + ") ": ""}until now.</p>
          <p>This balance does not include any payments or rebates applied since your last bill and is an estimated amount only.</p>
          </Modal.Description>
        </Modal.Content>
        <Modal.Actions>
          <Button
            style={{ backgroundColor: theme.backgroundColor }}
            secondary
            fluid
            onClick={props.handleCloseModal}>OK</Button>
        </Modal.Actions>
      </Modal>

      <Card>
        <Card.Header textAlign="center" style={{ color: theme.color }}>
          Current charges as of today

          <InfoIcon
            height="24px"
            style={{
              float: 'right'
            }}
            onClick={() => props.openModal()}
          />
        </Card.Header>
        <Card.Content textAlign="center" style={{ backgroundColor: themeBGColour }}>
          <Statistic style={{ width: '100%' }} >
            <Statistic.Value style={{ color: theme.color }}>
              <DollarValue value={props.chargesSoFar}/>
            </Statistic.Value>
          </Statistic>
          {
            (props.predictedSolar !== 0) &&
              <p>
                Includes solar credit of <DollarValue value={props.solarSoFar} plain={true} />
              </p>
          }
        </Card.Content>
        </Card>
      </React.Fragment>
  );
}
