import React from 'react'
import {Modal as SemanticModal} from 'semantic-ui-react';
import Analytics from '../utils/Analytics';

const ModalComponent = ({
  name,
  ...props
}) => {

  if (!name) {
    return <SemanticModal {...props}/>
  }

  let mappedProps = {
    ...props
  }

  let handleOpen = (ev, data) => {
    Analytics.modalview(name)
    ev && props.onOpen && props.onOpen(ev, data)
  }

  if (typeof props.open === undefined) {
    mappedProps.onOpen = handleOpen
  } else {
    if (props.open === true) {
      handleOpen(null, props)
    }
  }

  return <SemanticModal {...mappedProps}/>
}

ModalComponent.Actions = SemanticModal.Actions
ModalComponent.Header = SemanticModal.Header
ModalComponent.Content = SemanticModal.Content
ModalComponent.Description = SemanticModal.Description

export default ModalComponent