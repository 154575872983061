import React from 'react';
import { Button, Menu, Image } from 'semantic-ui-react';

import HomeIcon from '../Icons/HomeIcon';
import BusinessIcon from '../Icons/BusinessIcon';
import { PRODUCT_TYPE } from '../../utils/Types';

import { withCustomer } from '../../contexts/Customer'
import './ProductPicker.css'


class ProductPicker extends React.Component {

  render() {
    const { customerPremise, setPremiseType } = this.props.customer;

    if (!customerPremise)
      return null;

    return (
      <div className="product-picker">

        <Menu.Item
          as="a"
          link={false}
          value={PRODUCT_TYPE.RESIDENTIAL}
          onClick={(e, d) => setPremiseType(d.value)}
          className={customerPremise.serviceAgreementType === PRODUCT_TYPE.RESIDENTIAL ? "active" : ""}
          style={{ ...this.props.style }}
        >
          <HomeIcon height="38px" />
          Personal
        </Menu.Item>

        <Menu.Item
          as="a"
          value={PRODUCT_TYPE.BUSINESS}
          link={false}
          onClick={(e, d) => setPremiseType(d.value)}
          className={customerPremise.serviceAgreementType === PRODUCT_TYPE.BUSINESS ? "active" : ""}
          style={{ ...this.props.style }}
        >
          <BusinessIcon height="38px" />
          Business
        </Menu.Item>

      </ div>
    );
  }
}

export default withCustomer(ProductPicker)
