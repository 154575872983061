import React from 'react';
import {Segment, Header} from 'semantic-ui-react'

import DefaultLayout from './DefaultLayout';
import NoLayout from './NoLayout';

import ColumnLayout from './ColumnLayout';

import Responsive from "../components/Responsive/Responsive";
import './Layouts.css'
import {withApp} from './../contexts/App';
import {withCustomer} from './../contexts/Customer';
import {withAuth} from './../contexts/Auth';
import { AccountPicker } from '../components';
import PullToRefresh from './PullToRefresh';
import ExternalLink from '../components/ExternalLink';
import SignupFormLayout from './SignupFormLayout';
import SignupFullWidthLayout from './SignupFullWidthLayout';


export class Layout extends React.Component {

  static defaultProps = {
    onPullDownRefresh: undefined
  }
  componentDidMount() {
    let {
		layout,
		pageTitle,
		hideBackButton,
		noContainer,
		noHeader,
		noFooter,
		whitePage,
		journey,
		showSideContent,
	} = this.props

	// All Signup pages should be white unless otherwise specified
	switch(layout) {
		case SignupFormLayout:
		case SignupFullWidthLayout:
			if (whitePage !== false) whitePage = true;
			break;
	}

    this
      .props
      .app
      .set({
        pageTitle,
        showBackButton: !hideBackButton,
		noContainer,
		noHeader,
		noFooter,
		whitePage,
		journey,
		showSideContent,
	})
  }

  render() {
    let {
      children,
	  className,
      match,
      layout: LayoutComponent = DefaultLayout,
      beforeAccountPicker,
      accountPicker = true,
      afterAccountPicker,
      oldCCBAccountsDisplayed: oldCCBAccountsDisplayed = false,
      header,
	  noHeader,
	  noFooter,
      initContent,
      ...props
    } = this.props

    if (LayoutComponent === false) {
      LayoutComponent = NoLayout
    }

	let inErrorState = (this.props.requiresCustomer !== false) && this.props.customer.hasLoaded && !this.props.customer.customer;

	if (inErrorState) accountPicker = false;


    const layout = inErrorState ? (
		<div style={{
			textAlign: 'center',
			height: '400px',
			maxHeight: '60vh',
			maxWidth: '30em',
			margin: 'auto',
			display: 'flex',
			flexDirection: 'column',
			justifyContent: 'center',
		}}>
			<Header style={{
				color: 'white'
			}}>
				Looks like something went wrong.
			</Header>
			<p style={{
				color: 'white'
			}}>				
				Try refreshing the page or going to a different page.
				If that doesn’t work and the issue persists,
				please <ExternalLink
					to={process.env.REACT_APP_AURORA_WEBSITE + "/contact-us"}
					style={{color: 'white', textDecoration: 'underline'}}
				>Contact Us</ExternalLink>.
			</p>
		</div>
	) : (
      <LayoutComponent
        basic={props.basic}
        style={{
	        position: 'relative'
		}}
	  >
        {children}
      </LayoutComponent>
    )

    return (
      <React.Fragment>

        {header && !noHeader && (
          <Segment basic className="account-picker-wrapper">
            <ColumnLayout>
              {header}
            </ColumnLayout>
          </Segment>
        )}
        {(accountPicker !== false) && (this.props.auth && this.props.auth.isAuthenticated) && (
          <Segment basic className="account-picker-wrapper">
            <ColumnLayout>
              {beforeAccountPicker}
              {< AccountPicker oldCCBAccountsDisplayed={oldCCBAccountsDisplayed} visible = {true} />}
              {afterAccountPicker}
            </ColumnLayout>
          </Segment>
        )}
        <Responsive		
		  lessThan="computer"
		  className={className}
		  style={{
		  	flex: '1 1 -0em',
		  	display: 'flex',
		  	flexDirection: 'column',
		  }}
        >
          <PullToRefresh
            onRefresh={this.props.onPullDownRefresh}
            isRefreshing={this.props.isRefreshing}
            children={layout}
            active={!!this.props.onPullDownRefresh}/>
        </Responsive>
        <Responsive
		  greaterThan="tablet"
		  className={className}
		  style={{
			flex: '1 1 -0em',
			display: 'flex',
			flexDirection: 'column',
		  }}
		>
          {layout}
        </Responsive>
      </React.Fragment>
    )
  }
}

export default withApp(withCustomer(withAuth(Layout)))
