import React from 'react';
export default function Icon({
    color = 'currentColor',
    height = '32px',
    width = height
}) { return (
    <svg
        height={height}
        width={width}
        viewBox="0 0 20 34"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg">
       <path d="M10.7692 0L0 20.0909H9.23077V34L20 13.9091H10.7692V0Z" fill={color}/>
    </svg>
)
}
