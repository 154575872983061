import React from 'react';
import moment from 'moment-timezone';
import {
  Header,
  Button,
  Radio
} from "semantic-ui-react";
import { Form } from 'formsy-semantic-ui-react';
import ValidatedDropdown from '../../../components/Forms/ValidatedDropdown';
import ValidatedInput from '../../../components/Forms/ValidatedInput';
import { FORM_STATE } from '../../../utils/FormState';

const heatingSources = [
  {
    text: 'Select from list',
    value: ''
  }, {
    text: 'Heat pump or hard-wired heating',
    value: 'Heat pump or hard-wired heating'
  }, {
    text: 'Portable or plug-in heaters',
    value: 'Portable or plug-in heaters'
  }, {
    text: 'Gas heating or wood fire',
    value: 'Gas heating or wood fire'
  }, {
    text: 'Unsure',
    value: 'Unsure'
  }
];


export default function PlanForm(props) {
  const {
    theme,
    openModal,
    onFormValid,
    onFormInvalid,
    onHeatingSourceChange,
    onFrequencyChange,
    onInstalmentAmountChange,
    onStartDateChange,
    frequency,
    canSubmit,
    amountPerInstalment,
    startDate,
    formState
  } = props;


  return (
    <React.Fragment>

      <div className="payment-help">
        <Header size='large'>Helping you to plan ahead.</Header>
        <p>A payment plan can help you stay on top of your usage and clear your outstanding charges. Choose how often you'd like to pay and how much you're able to pay at each instalment.</p>

        <Form
          noValidate
          onValidSubmit={() => openModal('modalPlanSubmit')}
          onValid={onFormValid}
          onInvalid={onFormInvalid}
          className="paymentPlanForm">

          <div className="field plan-instalment-frequency required">
            <label>How often?{' '}</label>

            <div style={{
              display: 'flex',
              justifyContent: 'space-between'
            }}>
              <Radio
                as={Button}
                label='Weekly'
                name='frequency'
                value='weekly'
                className='radioButton'
                checked={frequency === 'weekly'}
                onChange={onFrequencyChange}
              />
              <Radio
                as={Button}
                label='Fortnightly'
                name='frequency'
                value='fortnightly'
                className='radioButton'
                checked={frequency === 'fortnightly'}
                onChange={onFrequencyChange}
              />
              <Radio
                as={Button}
                label='Monthly'
                name='frequency'
                value='monthly'
                className='radioButton'
                checked={frequency === 'monthly'}
                onChange={onFrequencyChange}
              />
            </div>
          </div>

          <ValidatedInput
            required
            label="How much per instalment?"
            validations={{
              'isNumeric': true,
              'min': 1,
              'max': 999,
              'matchRegexp': /^[0-9]+$/
            }}
            validationErrors={{
              matchRegexp: `Please enter whole numbers only`,
              min: `Please enter an amount no less than $1`,
              max: `Please enter an amount no more than $999`
            }}
            name="amountPerInstalment"
            className="plan-instalment-amount"
            type='number'
            icon="dollar"
            step="0.01"
            min={1}
            max={999}
            fluid
            iconPosition="left"
            onChange={onInstalmentAmountChange}
            onKeyPress={e => ['+', '-', 'E', 'e'].indexOf(e.key) > -1 && e.preventDefault()}
            value={amountPerInstalment} />

          <ValidatedDropdown
            required
            hideErrorMessage
            className="plan-instalment-heating"
            options={heatingSources}
            defaultValue=''
            label="Your main heating source"
            name="heatingSource"
            placeholder="Select from list"
            onChange={onHeatingSourceChange} />

          <ValidatedInput
            required
            label="Start date for instalments"
            validations={{
              'matchRegexp': /^\d{1,2}\/\d{2}\/\d{4}$/,
              isValidDate: (values, value) => moment(value, "DD-MM-YYYY").isValid(),
              isNotWithin3DaysOfToday: (values, value) => {
                return moment(value, "DD-MM-YYYY").diff(moment().startOf('day'), 'days') >= 3;
              },
              isWithinACalendarMonth: (values, value) => {
                // uses endOf day function to make the dates inclusive
                return moment(value, "DD-MM-YYYY").diff(moment().endOf('day'), 'months') < 1;
              }
            }}
            validationErrors={{
              matchRegexp: `Please enter a date in the format dd/mm/yyyy - e.g. 03/11/2020`,
              isValidDate: `Please enter a valid date.`,
              isNotWithin3DaysOfToday: `Start date must be at least 3 days from today.`,
              isWithinACalendarMonth: `Start date must be less than a month away.`
            }}
            name="startdate"
            className="plan-instalment-startdate"
            type='text'
            placeholder='dd/mm/yyyy'
            fluid
            onChange={onStartDateChange}
            value={startDate}
          />

          <Button
            style={{
              backgroundColor: theme ? theme.primary.backgroundColor : null,
              marginTop: '10px'
            }}
            secondary
            type='submit'
            disabled={!canSubmit || !frequency}
            loading={formState === FORM_STATE.LOADING}
          >Apply</Button>
        </Form>
      </div>
    </React.Fragment>
  );
}
