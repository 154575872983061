import React from "react";
import Layout from "../../../layouts/Layout.js";
import useScrollToTop from "../../../utils/useScrollToTop.js";
import SignupFormLayout from "../../../layouts/SignupFormLayout.js";
import SignupButton from '../components/SignupButton/SignupButton.js';
import { useNavigate } from "react-router";
import { Form } from 'formsy-semantic-ui-react';
import { FORM_STATE } from "../../../utils/FormState.js";
import { useState } from "react";
import axios from "axios";
import SignupErrorModal, { CONNECTIVITY_ERROR, GENERIC_ERROR } from "../components/SignupErrorModal/SignupErrorModal.js";
import { useSignup } from "../../../contexts/Signup.js";
import IconRadioGroup from "../../../components/IconRadio/IconRadioGroup.js";
import IconRadio from "../../../components/IconRadio/IconRadio.js";
import SignupCheckbox from "../components/SignupCheckbox/SignupCheckbox.js";


export default () => {

	const navigate = useNavigate();
	useScrollToTop();

	const signup = useSignup();
	if ((!signup.emailAddress && !signup.maskedEmailAddress) || !signup.token) navigate('/signup/residential');

	const [formState, setFormState] = useState(FORM_STATE.DEFAULT);
	const [canSubmit, setCanSubmit] = useState(false);
	const [errorModal, setErrorModal] = useState(null);

	
	function submit(payload) {

		window.scrollTo(0, 0);
		setCanSubmit(false);
		setFormState(FORM_STATE.LOADING);

		signup.set({ offers: payload.offers });

		setTimeout(() => {
			if (payload.continueEmail === "yes") {
				navigate('/signup/residential/choose-password');
			} else {
				navigate('/signup/residential/update-email');
			}
		}, 1);
	
		return;

	}

	return (		
		<Layout
			requiresCustomer={false}
			accountPicker={false}
			layout={SignupFormLayout}
			journey={{ message: "Confirm your email address", journeyName: "signup", currentStep: 3 }}
		>
			<Form
				noValidate
				onValidSubmit={submit}
				onValid={() => { setCanSubmit(true); }}
				onInvalid={() => { setCanSubmit(false); }}
			>
				<p>
					Are you happy to continue using this email address: <span style={{color:"#0060AE"}}>{ signup.emailAddress || signup.maskedEmailAddress }</span>
				</p>
				<p>
					We will use this email address for your login, <span style={{whiteSpace: 'nowrap'}}>e-bills</span> and to contact you.
				</p>

				<IconRadioGroup
					name="continueEmail"
					formRadioGroup={true}
					required
					style={{marginTop: '30px', marginBottom: '30px'}}
				>
					<IconRadio name="continueEmail" value="yes" label="Yes" />
					<IconRadio name="continueEmail" value="no" label="No" />
				</IconRadioGroup>

				{/*
				<SignupCheckbox
					checked={false}
					name="offers"
	              	label="I’d like exclusive access to Aurora Energy promotions, competitions, news and product offers"
					size="small"
				/>
				*/}

				<SignupButton disabled={!canSubmit} loading={formState === FORM_STATE.LOADING}>
					Continue
				</SignupButton>

			</Form>

			<SignupErrorModal open={!!errorModal} message={errorModal} onClose={() => { setErrorModal(null); }}/>

		</Layout>		
	);

}


